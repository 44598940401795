var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { mm_Supports } from "hub-lib/models/orientdb/mm_Supports.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import stringSimilarity from "string-similarity";
export var eSort;
(function (eSort) {
    eSort["asc"] = "ASC";
    eSort["desc"] = "DESC";
})(eSort || (eSort = {}));
export function findBestMatch(name, list, options) {
    if (options === void 0) { options = {}; }
    // find matches
    var _a = stringSimilarity.findBestMatch(name, list), ratings = _a.ratings, bestMatch = _a.bestMatch;
    var matches = ratings;
    // minimum
    if (options.minRating) {
        matches = matches.filter(function (m) { return m.rating >= options.minRating; });
    }
    // sort
    if (options.sort) {
        var asc = function (a, b) { return a.rating - b.rating; };
        var desc = function (a, b) { return b.rating - a.rating; };
        switch (options.sort) {
            case eSort.asc:
                matches = matches.sort(asc);
                break;
            case eSort.desc:
                matches = matches.sort(desc);
                break;
        }
    }
    // distBest
    if (options.distBest) {
        matches = matches.filter(function (m) { return m.rating >= bestMatch.rating - options.distBest; });
    }
    return matches;
}
export function ViewTextToCompare(dimensionName, v) {
    if (v["@class"] == mm_Supports.name) {
        var viewNameSplitted = v.Label.toLocaleLowerCase().split("/");
        if (dimensionName == ref_BroadcastAreas.name && viewNameSplitted.length > 1) {
            return viewNameSplitted[1];
        }
        return viewNameSplitted[0];
    }
    return v.Label.toLocaleLowerCase();
}
export function findBestViews(dimensionName, ref, views, options) {
    if (options === void 0) { options = {}; }
    var matches = findBestMatch(ref["Name"].toLocaleLowerCase(), Array.from(new Set(views.map(function (e) { return ViewTextToCompare(dimensionName, e); }))), options);
    return matches.map(function (m) {
        return views.filter(function (v) { return ViewTextToCompare(dimensionName, v) === m.target; })
            .map(function (v) { return ({
            view: v,
            rating: m.rating
        }); });
    }).reduce(function (a, b) { return __spreadArray(__spreadArray([], a, true), b, true); }, []);
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { SerializeError, clone, duplicate, propertyOf } from "hub-lib/tools.bin";
import { ConsoleDebug, GetUser, IsMMUser } from "../utils/localstorage.bin";
import { Client } from "hub-lib/client/client.bin";
import { Notify } from "../utils/Notify.bin";
import { Trad, TradComposed } from "trad-lib";
import { OnSupportChange, SetGroup, SetPerformancesKPIFromOJD, UpdateAgencyState, UpdateBroadcastState, UpdateCurrencyState } from "./storeUtils";
import { eGroupCategories } from "hub-lib/dto/client/ref_Groups.bin";
import { RightManager, eRights, eFunctions } from "hub-lib/models/types/rights.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { ref_Estimates } from "hub-lib/dto/client/ref_Estimates.bin";
import { eDialogMode } from "../components/ConfigurableComponents/BasicGenericDialog.bin";
import moment from "moment";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
var initialState = { mode: undefined, enableEstimate: false, form: null, data: undefined, selectedEstimate: 0,
    // updateEstimate: false,
    attachments: undefined, agencyOptions: undefined, currencyOptions: undefined, broadcastOptions: undefined, lockNext: false, validationIssues: [], publications: undefined, groups: { MediaFamily: [] }, get: function () { return this.data && ToRefCampaign(this.data); } };
export function ToRefCampaign(campaign) { return __assign(__assign({}, duplicate(campaign)), { Start: campaign.Start ? new Date(campaign.Start) : undefined, End: campaign.End ? new Date(campaign.End) : undefined }); }
export function ToRefCampaignSerializable(campaign) { return __assign(__assign({}, duplicate(campaign)), { Start: campaign.Start ? new Date(campaign.Start).getTime() : undefined, End: campaign.End ? new Date(campaign.End).getTime() : undefined }); }
export function SetValidationIssues(campaign, oldValidationIssues) { var _GetUser, _GetUser$customer, _GetUser2, _GetUser2$customer; var issues = []; var mandatory = ["Name", "Start"]; if (((_GetUser = GetUser()) === null || _GetUser === void 0 ? void 0 : (_GetUser$customer = _GetUser.customer) === null || _GetUser$customer === void 0 ? void 0 : _GetUser$customer.AdvertiserMode) == ref_Campaigns.name) {
    if (RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read))
        mandatory.push("AdvertiserGroup");
    else
        mandatory.push("Advertiser");
} if (((_GetUser2 = GetUser()) === null || _GetUser2 === void 0 ? void 0 : (_GetUser2$customer = _GetUser2.customer) === null || _GetUser2$customer === void 0 ? void 0 : _GetUser2$customer.SupportMode) == ref_Campaigns.name)
    mandatory = __spreadArray(__spreadArray([], mandatory, true), ["Support", "Currency", "BroadcastArea"], false); mandatory.forEach(function (p) { if (!campaign[p])
    issues.push(p); }); return issues; }
export function TotalWarningData(data) { var grps = data.filter(function (d) { return d.time != "total"; }).map(function (d) { return d.Performances["GRP"]; }); var grpSums = grps.reduce(function (partial, grp) { return ({ sumObjective: partial.sumObjective + grp.objective, sumDone: partial.sumDone + grp.done }); }, { sumObjective: 0, sumDone: 0 }); var total = data.find(function (d) { return d.time == "total"; }); total.Performances["GRP"]["ObjectiveWarning"] = grps.length != 0 && grpSums.sumObjective != total.Performances["GRP"].objective; total.Performances["GRP"]["DoneWarning"] = grps.length != 0 && grpSums.sumDone != total.Performances["GRP"].done; }
function UpdatePerformances(newCampaign, oldCampaign) {
    return __awaiter(this, void 0, void 0, function () { var GetIndicateurName, TimeFormat, PeriodeChange, ModeChange, initTimeLine, indicateurs, periodChange, timeValues, defaultKPIs_1, optionalKPIs, _loop_1, _newCampaign$Performa, _newCampaign$Performa2, _Object$keys, _indicateurs$find, _i, _a, mediaId; return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                GetIndicateurName = function (mode) { if (mode == "days")
                    return Trad("number_of_days");
                else if (mode == "weeks")
                    return Trad("number_of_weeks"); return null; };
                TimeFormat = function (iterator, mode) { if (!newCampaign.Start)
                    return null; var prefixe = mode == "days" ? "J" : "S"; var date = moment(newCampaign.Start).add(iterator, mode == "days" ? "days" : "weeks"); return "".concat(prefixe).concat(iterator + 1, " - ").concat(date.format("DD/MM")); };
                PeriodeChange = function () { return moment(newCampaign.Start).format("DD/MM/YY") != moment(oldCampaign.Start).format("DD/MM/YY") || moment(newCampaign.End).format("DD/MM/YY") != moment(oldCampaign.End).format("DD/MM/YY"); };
                ModeChange = function (mediaId) { var _oldCampaign$Performa; return newCampaign.Performances[mediaId].TimeMode != (oldCampaign === null || oldCampaign === void 0 ? void 0 : (_oldCampaign$Performa = oldCampaign.Performances) === null || _oldCampaign$Performa === void 0 ? void 0 : _oldCampaign$Performa[mediaId].TimeMode); };
                initTimeLine = function (_data, timeKpis, keyTime, _oldData) { var line = { time: keyTime, Performances: {} }; var oldLine = _oldData === null || _oldData === void 0 ? void 0 : _oldData.find(function (d) { return d.time == keyTime; }); timeKpis.forEach(function (kpi) { var _oldLine$Performances; if (oldLine !== null && oldLine !== void 0 && (_oldLine$Performances = oldLine.Performances) !== null && _oldLine$Performances !== void 0 && _oldLine$Performances[kpi])
                    line.Performances[kpi] = clone(oldLine.Performances[kpi]);
                else
                    line.Performances[kpi] = { objective: 0, done: 0 }; }); _data.push(line); };
                if (!newCampaign.Performances) return [3 /*break*/, 5];
                return [4 /*yield*/, AggregatorFactory.GetInstance().Get(ref_Campaigns).Provide()];
            case 1:
                indicateurs = _b.sent();
                periodChange = PeriodeChange();
                timeValues = {};
                defaultKPIs_1 = ["GRP", "coverage", "CGRP"];
                optionalKPIs = ["contacts", "coverage_thousands", "repetition"];
                _loop_1 = function (mediaId) {
                    var timeKpis, totalPerformance, activeKPIs, indicateurName_1, value, indicateur, _c, _d, _e, _f, newPerformances, oldPerformances, i, key;
                    return __generator(this, function (_g) {
                        switch (_g.label) {
                            case 0:
                                timeKpis = [];
                                totalPerformance = (_newCampaign$Performa = newCampaign.Performances[mediaId].TimePerformance) === null || _newCampaign$Performa === void 0 ? void 0 : (_newCampaign$Performa2 = _newCampaign$Performa.find(function (tp) { return tp.time == "total"; })) === null || _newCampaign$Performa2 === void 0 ? void 0 : _newCampaign$Performa2.Performances;
                                activeKPIs = totalPerformance ? (_Object$keys = Object.keys(totalPerformance)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.filter(function (key) { return !defaultKPIs_1.includes(key); }) : [];
                                if (!(periodChange || ModeChange(mediaId))) return [3 /*break*/, 4];
                                indicateurName_1 = GetIndicateurName(newCampaign.Performances[mediaId].TimeMode);
                                value = 0;
                                if (!indicateurName_1) return [3 /*break*/, 3];
                                if (!!timeValues[indicateurName_1]) return [3 /*break*/, 2];
                                indicateur = (_indicateurs$find = indicateurs.find(function (i) { return i.indicateur.name == indicateurName_1; })) === null || _indicateurs$find === void 0 ? void 0 : _indicateurs$find.indicateur;
                                _c = timeValues;
                                _d = indicateurName_1;
                                _f = (_e = Math).round;
                                return [4 /*yield*/, indicateur.Compute([newCampaign])];
                            case 1:
                                _c[_d] = _f.apply(_e, [_g.sent()]);
                                _g.label = 2;
                            case 2:
                                value = timeValues[indicateurName_1];
                                _g.label = 3;
                            case 3:
                                newPerformances = newCampaign.Performances[mediaId].TimePerformance;
                                oldPerformances = oldCampaign.Performances[mediaId].TimePerformance;
                                initTimeLine(newPerformances, timeKpis, "total", oldPerformances);
                                for (i = 0; i < value; i++) {
                                    key = TimeFormat(i, newCampaign.Performances[mediaId].TimeMode);
                                    initTimeLine(newPerformances, timeKpis, key, oldPerformances);
                                }
                                TotalWarningData(newPerformances);
                                _g.label = 4;
                            case 4: return [2 /*return*/];
                        }
                    });
                };
                _i = 0, _a = Object.keys(newCampaign.Performances);
                _b.label = 2;
            case 2:
                if (!(_i < _a.length)) return [3 /*break*/, 5];
                mediaId = _a[_i];
                return [5 /*yield**/, _loop_1(mediaId)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                _i++;
                return [3 /*break*/, 2];
            case 5: return [2 /*return*/];
        }
    }); });
}
export var setCampaign = createAsyncThunk('campaignEditor/campaign', function (campaign, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _state$campaignEditor, time7642, state, isInitializing, oldCampaign, newCampaign_1, isCreating, kpiManager, properties, broadcastOptions, currencyOptions, cloneCampaignEditor, mediaFamilyOptions, enableEstimate, _campaign$Estimates, medias, _await$Client$searchV, support, validationIssues, _time7642, form, mode, error_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 16, , 17]);
                time7642 = new Date().getTime();
                ConsoleDebug('campaign param: ', campaign);
                state = thunkAPI.getState();
                ConsoleDebug('previous ', state.campaignEditor.data);
                isInitializing = Boolean(!state.campaignEditor.data);
                oldCampaign = (_state$campaignEditor = state.campaignEditor.data) !== null && _state$campaignEditor !== void 0 ? _state$campaignEditor : new ref_Campaigns();
                newCampaign_1 = clone(campaign);
                isCreating = newCampaign_1 && !newCampaign_1['@rid'];
                kpiManager = KPIsManagerCache.GetInstance(ref_Campaigns.name);
                return [4 /*yield*/, kpiManager.GetUserProperties()];
            case 1:
                properties = _b.sent();
                if (newCampaign_1.Start && ['string', 'number'].includes(typeof newCampaign_1.Start))
                    newCampaign_1.Start = new Date(newCampaign_1.Start);
                if (newCampaign_1.End && ['string', 'number'].includes(typeof newCampaign_1.End))
                    newCampaign_1.End = new Date(newCampaign_1.End);
                broadcastOptions = undefined;
                currencyOptions = undefined;
                if (!isInitializing && oldCampaign.Support !== newCampaign_1.Support) {
                    OnSupportChange("ref_Campaigns", oldCampaign, newCampaign_1);
                }
                if (!newCampaign_1.Media) return [3 /*break*/, 4];
                cloneCampaignEditor = clone(state.campaignEditor);
                return [4 /*yield*/, UpdateBroadcastState(cloneCampaignEditor, newCampaign_1)];
            case 2:
                broadcastOptions = _b.sent();
                return [4 /*yield*/, UpdateCurrencyState(cloneCampaignEditor, newCampaign_1)];
            case 3:
                currencyOptions = _b.sent();
                _b.label = 4;
            case 4:
                mediaFamilyOptions = null;
                enableEstimate = undefined;
                if (isInitializing) {
                    if (!isCreating)
                        enableEstimate = (campaign === null || campaign === void 0 ? void 0 : (_campaign$Estimates = campaign.Estimates) === null || _campaign$Estimates === void 0 ? void 0 : _campaign$Estimates.length) !== 0;
                    else
                        enableEstimate = false;
                }
                if (!isInitializing) return [3 /*break*/, 7];
                if (!newCampaign_1.Performances)
                    newCampaign_1.Performances = {};
                if (!newCampaign_1.Media) return [3 /*break*/, 5];
                if (!newCampaign_1.Performances[campaign.Media])
                    newCampaign_1.Performances[campaign.Media] = {};
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, KPIsManagerCache.GetMedias()];
            case 6:
                medias = _b.sent();
                medias.forEach(function (m) { if (!newCampaign_1.Performances[m["@rid"]])
                    newCampaign_1.Performances[m["@rid"]] = {}; });
                console.log("campaign.Performances", newCampaign_1.Performances);
                _b.label = 7;
            case 7:
                if (!(properties.some(function (p) { return p.name == "ModelProperties.SupportDetails"; }) && oldCampaign.Support !== newCampaign_1.Support && !isInitializing)) return [3 /*break*/, 9];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Supports, { "@rid": newCampaign_1.Support })];
            case 8:
                support = (_await$Client$searchV = _b.sent()) === null || _await$Client$searchV === void 0 ? void 0 : _await$Client$searchV[0];
                newCampaign_1.ModelProperties["SupportDetails"] = support === null || support === void 0 ? void 0 : support.Description;
                _b.label = 9;
            case 9:
                if (!(properties.some(function (p) { return p.name == "ModelProperties.MediaFamily"; }) && oldCampaign.Support !== newCampaign_1.Support)) return [3 /*break*/, 11];
                return [4 /*yield*/, SetGroup(newCampaign_1, newCampaign_1.Support, eGroupCategories.MediaFamily, "MediaFamily", "MediaFamilyOther", !isInitializing)];
            case 10:
                mediaFamilyOptions = _b.sent();
                _b.label = 11;
            case 11:
                if (!(!isInitializing && isCreating)) return [3 /*break*/, 13];
                return [4 /*yield*/, SetPerformancesKPIFromOJD(newCampaign_1, oldCampaign)];
            case 12:
                _b.sent();
                _b.label = 13;
            case 13:
                validationIssues = SetValidationIssues(newCampaign_1, state.campaignEditor.validationIssues);
                _time7642 = new Date().getTime();
                ConsoleDebug("SetCampaign Elapsed ".concat(_time7642 - time7642, "ms"), newCampaign_1, validationIssues);
                form = null;
                if (!(RightManager.hasRight(eFunctions.ref_Estimates, eRights.read) && (enableEstimate != state.campaignEditor.enableEstimate || (newCampaign_1 === null || newCampaign_1 === void 0 ? void 0 : newCampaign_1.AdvertiserGroup) != (oldCampaign === null || oldCampaign === void 0 ? void 0 : oldCampaign.AdvertiserGroup) || (newCampaign_1 === null || newCampaign_1 === void 0 ? void 0 : newCampaign_1.Advertiser) != (oldCampaign === null || oldCampaign === void 0 ? void 0 : oldCampaign.Advertiser) || (newCampaign_1 === null || newCampaign_1 === void 0 ? void 0 : newCampaign_1.Brand) != (oldCampaign === null || oldCampaign === void 0 ? void 0 : oldCampaign.Brand) || (newCampaign_1 === null || newCampaign_1 === void 0 ? void 0 : newCampaign_1.Product) != (oldCampaign === null || oldCampaign === void 0 ? void 0 : oldCampaign.Product)))) return [3 /*break*/, 15];
                mode = isCreating ? eDialogMode.create : eDialogMode.modify;
                return [4 /*yield*/, Client.getForm(ref_Estimates.name, (_a = {}, _a[propertyOf('Campaign')] = newCampaign_1, _a.mode = mode, _a))];
            case 14:
                form = _b.sent();
                _b.label = 15;
            case 15: return [2 /*return*/, { campaign: ToRefCampaignSerializable(newCampaign_1), enableEstimate: enableEstimate, form: form, /*createEstimate,*/ broadcastOptions: broadcastOptions, validationIssues: validationIssues, currencyOptions: currencyOptions, mediaFamilyOptions: mediaFamilyOptions }];
            case 16:
                error_1 = _b.sent();
                console.error(error_1);
                Notify(Trad('error'), 'error');
                Client.log({ Category: 'ERROR', Action: 'setCampaign', Informations: SerializeError(error_1) });
                return [3 /*break*/, 17];
            case 17: return [2 /*return*/];
        }
    });
}); });
export var initCampaignAgencyOptions = createAsyncThunk('campaignEditor/initAgencyOptions', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, agencyOptions, error_2; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            _a.trys.push([0, 2, , 3]);
            state = thunkAPI.getState();
            return [4 /*yield*/, UpdateAgencyState(state.campaignEditor, state.campaignEditor.get())];
        case 1:
            agencyOptions = _a.sent();
            return [2 /*return*/, { agencyOptions: agencyOptions }];
        case 2:
            error_2 = _a.sent();
            console.error(error_2);
            return [3 /*break*/, 3];
        case 3: return [2 /*return*/];
    }
}); }); });
export var initCampaignCurrencyOptions = createAsyncThunk('campaignEditor/initCurrencyOptions', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, currencyOptions, error_3; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            _a.trys.push([0, 2, , 3]);
            state = thunkAPI.getState();
            return [4 /*yield*/, UpdateCurrencyState(state.campaignEditor, state.campaignEditor.get())];
        case 1:
            currencyOptions = _a.sent();
            return [2 /*return*/, { currencyOptions: currencyOptions }];
        case 2:
            error_3 = _a.sent();
            console.error(error_3);
            return [3 /*break*/, 3];
        case 3: return [2 /*return*/];
    }
}); }); });
export var initCampaignBroadcastOptions = createAsyncThunk('campaignEditor/initBroadcastOptions', function (_, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var state, broadcastOptions, error_4; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            _a.trys.push([0, 2, , 3]);
            state = thunkAPI.getState();
            return [4 /*yield*/, UpdateBroadcastState(state.campaignEditor, state.campaignEditor.get())];
        case 1:
            broadcastOptions = _a.sent();
            return [2 /*return*/, { broadcastOptions: broadcastOptions }];
        case 2:
            error_4 = _a.sent();
            console.error(error_4);
            return [3 /*break*/, 3];
        case 3: return [2 /*return*/];
    }
}); }); });
export var initAttachments = createAsyncThunk('campaignEditor/initAttachments', function (_data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () { var _Text, _data$Attachments, _data$Attachments$Ext, _Text2, _data$Attachments2, _data$Attachments2$In, attachments; return __generator(this, function (_a) {
    try {
        attachments = { "External": (_Text = _data === null || _data === void 0 ? void 0 : (_data$Attachments = _data.Attachments) === null || _data$Attachments === void 0 ? void 0 : (_data$Attachments$Ext = _data$Attachments["External"]) === null || _data$Attachments$Ext === void 0 ? void 0 : _data$Attachments$Ext.Text) !== null && _Text !== void 0 ? _Text : "", "Internal": (_Text2 = _data === null || _data === void 0 ? void 0 : (_data$Attachments2 = _data.Attachments) === null || _data$Attachments2 === void 0 ? void 0 : (_data$Attachments2$In = _data$Attachments2["Internal"]) === null || _data$Attachments2$In === void 0 ? void 0 : _data$Attachments2$In.Text) !== null && _Text2 !== void 0 ? _Text2 : "" };
        return [2 /*return*/, { attachments: attachments }];
    }
    catch (error) {
        console.error(error);
    }
    return [2 /*return*/];
}); }); });
export var campaignEditorSlice = createSlice({ name: 'campaignEditor', initialState: clone(initialState), extraReducers: function (builder) {
        builder.addCase(setCampaign.pending, function (state, action) { state.lockNext = true; });
        var fulfilledCallback = function (state, action) { var _action$payload, _action$payload2, _action$payload3, _action$payload4, _action$payload5, _action$payload6, _action$payload7, _action$payload8, _action$payload9; if ((_action$payload = action.payload) !== null && _action$payload !== void 0 && _action$payload.campaign)
            state.data = action.payload.campaign; if ((_action$payload2 = action.payload) !== null && _action$payload2 !== void 0 && _action$payload2.validationIssues)
            state.validationIssues = action.payload.validationIssues; if (action !== null && action !== void 0 && (_action$payload3 = action.payload) !== null && _action$payload3 !== void 0 && _action$payload3.attachments)
            state.attachments = action.payload.attachments; if (action !== null && action !== void 0 && (_action$payload4 = action.payload) !== null && _action$payload4 !== void 0 && _action$payload4.agencyOptions)
            state.agencyOptions = action.payload.agencyOptions; if (action !== null && action !== void 0 && (_action$payload5 = action.payload) !== null && _action$payload5 !== void 0 && _action$payload5.currencyOptions)
            state.currencyOptions = action.payload.currencyOptions; if (action !== null && action !== void 0 && (_action$payload6 = action.payload) !== null && _action$payload6 !== void 0 && _action$payload6.broadcastOptions)
            state.broadcastOptions = action.payload.broadcastOptions; if (action !== null && action !== void 0 && (_action$payload7 = action.payload) !== null && _action$payload7 !== void 0 && _action$payload7.mediaFamilyOptions)
            state.groups.MediaFamily = action.payload.mediaFamilyOptions; if (action !== null && action !== void 0 && (_action$payload8 = action.payload) !== null && _action$payload8 !== void 0 && _action$payload8.form)
            state.form = action.payload.form; if ((action === null || action === void 0 ? void 0 : (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.enableEstimate) !== undefined)
            state.enableEstimate = action.payload.enableEstimate; };
        builder.addCase(setCampaign.fulfilled, function (state, action) { fulfilledCallback(state, action); state.lockNext = false; });
        builder.addCase(initAttachments.fulfilled, fulfilledCallback);
        builder.addCase(initCampaignAgencyOptions.fulfilled, fulfilledCallback);
        builder.addCase(initCampaignCurrencyOptions.fulfilled, fulfilledCallback);
        builder.addCase(initCampaignBroadcastOptions.fulfilled, fulfilledCallback);
    }, reducers: { setCampaignMode: function (state, action) { state.mode = action.payload; }, setCampaignSync: function (state, action) { if (!action.payload) {
            var cloneValues = clone(initialState);
            Object.entries(cloneValues).forEach(function (_ref) { var key = _ref[0], value = _ref[1]; state[key] = value; });
            return;
        } var newCampaign = __assign(__assign({}, duplicate(action.payload)), { Start: action.payload.Start ? new Date(action.payload.Start).getTime() : undefined, End: action.payload.End ? new Date(action.payload.End).getTime() : undefined }); if (!newCampaign.ModelProperties)
            newCampaign.ModelProperties = {}; state.data = newCampaign; state.validationIssues = SetValidationIssues(newCampaign, state.validationIssues); console.log("SetCampaignSync", newCampaign); }, setCampaignPublications: function (state, action) { state.publications = action.payload ? duplicate(action.payload) : undefined; }, clearCampaignEditor: function (state, action) { state.data = undefined; state.currencyOptions = undefined; state.broadcastOptions = undefined; }, setSelectedEstimate: function (state, action) { state.selectedEstimate = action.payload; },
        //     if (state.createEstimate != action.payload)
        //         state.data.Estimates = [];
        //     state.createEstimate = action.payload;
        //     state.selectedEstimate = 0;
        // },
        // setUpdateEstimate: (state, action: PayloadAction<boolean>) => {
        //     state.createEstimate = action.payload;
        //     state.updateEstimate = action.payload;
        // },
        setEnableEstimate: function (state, action) { var _state$data$Estimates; state.enableEstimate = action.payload; if (!action.payload)
            state.data.Estimates = [];
        else if (!((_state$data$Estimates = state.data.Estimates) !== null && _state$data$Estimates !== void 0 && _state$data$Estimates.length)) {
            var isMMUser = IsMMUser();
            if (!state.data.Estimates)
                state.data.Estimates = [];
            state.data.Estimates.push({ EstimateLib: TradComposed("estimate_number", ["1"]), Source: isMMUser ? "MM" : "Sellsy", ExternalID: null, CreateParams: {} });
        } state.selectedEstimate = 0; }, AddNewEstimate: function (state, action) {
            var _a = action.payload, nb = _a.nb, source = _a.source, clear = _a.clear, createParams = _a.createParams;
            var isMMUser = IsMMUser();
            if (clear) { // state.createEstimate = true;
                state.data.Estimates = []; //setState({ ...state, selected: 0, CreateEstimate: true });*/
            }
            state.data.Estimates.push({ EstimateLib: TradComposed("estimate_number", [nb.toString()]), Source: (source !== null && source !== void 0 ? source : isMMUser) ? "MM" : "Sellsy", ExternalID: null, CreateParams: createParams !== null && createParams !== void 0 ? createParams : {} }); //forceUpdate();
        }, setAttachment: function (state, action) { state.attachments[action.payload.type] = action.payload.value; } } }); // Action creators are generated for each case reducer function
export var setCampaignMode = (_a = campaignEditorSlice.actions, _a.setCampaignMode), setCampaignSync = _a.setCampaignSync, clearCampaignEditor = _a.clearCampaignEditor, setSelectedEstimate = _a.setSelectedEstimate, setEnableEstimate = _a.setEnableEstimate, /*setCreateEstimate, setUpdateEstimate,*/ AddNewEstimate = _a.AddNewEstimate, setAttachment = _a.setAttachment, setCampaignPublications = _a.setCampaignPublications;
export var campaignEditorReducer = campaignEditorSlice.reducer;

import { EventEmitter } from 'events';
export var eventAdded = 'added';
export var eventDeleted = 'deleted';
export var eventSelected = 'selected';
export var eventContentChanged = 'contentChanged';
export var eventTabManagerChanged = 'tabManagerChanged';
export var TabManagerEvent = new EventEmitter();
var TabManager = /** @class */ (function () {
    function TabManager(id) {
        var _this = this;
        this.id = void 0;
        this.onTabChanged = new EventEmitter();
        this.selectedTab = void 0;
        this.tabs = [];
        this.autoId = 0;
        this.initMode = void 0;
        this.notifyChanged = function () { if (!_this.initMode)
            TabManagerEvent.emit(eventTabManagerChanged, _this); };
        this.id = id;
        this.onTabChanged.addListener(eventAdded, this.notifyChanged);
        this.onTabChanged.addListener(eventDeleted, this.notifyChanged);
        this.onTabChanged.addListener(eventSelected, this.notifyChanged);
        this.onTabChanged.addListener(eventContentChanged, this.notifyChanged);
    }
    TabManager.prototype.addTab = function (tab) {
        var _this = this;
        tab.id = this.autoId++;
        tab.onTabChanged = function () { _this.onTabChanged.emit(eventContentChanged); };
        if (!this.initMode && this.tabs.length == 0)
            this.selectedTab = tab;
        if (this.tabs.length < 5) {
            this.tabs.push(tab);
            if (!this.initMode)
                this.selectedTab = tab;
            this.onTabChanged.emit(eventAdded, [tab]);
        }
    };
    TabManager.prototype.deleteTab = function (tab) { if (this.tabs.length > 1) {
        if (tab == this.selectedTab) {
            var newIndex = this.tabs.indexOf(tab);
            this.tabs = this.tabs.filter(function (t) { return t != tab; });
            if (newIndex >= this.tabs.length)
                newIndex = this.tabs.length - 1;
            this.selectedTab = this.tabs[newIndex];
        }
        else {
            this.tabs = this.tabs.filter(function (t) { return t != tab; });
        }
        this.onTabChanged.emit(eventDeleted, tab);
    } };
    TabManager.prototype.select = function (tab) { this.selectedTab = tab; this.onTabChanged.emit(eventSelected, tab); };
    return TabManager;
}());
export { TabManager };
var ATab = /** @class */ (function () {
    function ATab() {
        this.id = void 0;
        this.name = void 0;
        this.onTabChanged = void 0;
        this.getComponent = void 0;
        this.save = void 0;
    }
    return ATab;
}());
export { ATab };

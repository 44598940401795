var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ref_Attachments = /** @class */ (function () {
    function ref_Attachments() {
    }
    return ref_Attachments;
}());
export { ref_Attachments };
var ref_Comments = /** @class */ (function (_super) {
    __extends(ref_Comments, _super);
    function ref_Comments() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.AttachementType = "comment";
        return _this;
    }
    return ref_Comments;
}(ref_Attachments));
export { ref_Comments };
var ref_Files = /** @class */ (function (_super) {
    __extends(ref_Files, _super);
    function ref_Files() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.AttachementType = "file";
        return _this;
    }
    return ref_Files;
}(ref_Attachments));
export { ref_Files };
export var eVisualStatus;
(function (eVisualStatus) {
    eVisualStatus[eVisualStatus["Create"] = 0] = "Create";
    eVisualStatus[eVisualStatus["Pending"] = 1] = "Pending";
    eVisualStatus[eVisualStatus["Ended"] = 2] = "Ended";
    eVisualStatus[eVisualStatus["Sent"] = 3] = "Sent";
})(eVisualStatus || (eVisualStatus = {}));
var ref_Visuals = /** @class */ (function () {
    function ref_Visuals() {
    }
    return ref_Visuals;
}());
export { ref_Visuals };
var ref_HyperLinks = /** @class */ (function (_super) {
    __extends(ref_HyperLinks, _super);
    function ref_HyperLinks() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.AttachementType = "hyperlink";
        _this.Type = "Tech";
        return _this;
    }
    return ref_HyperLinks;
}(ref_Attachments));
export { ref_HyperLinks };

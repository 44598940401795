export var checkMaximum = function (d, rows) { if (d.Minimum > d.Maximum)
    return { valid: false }; if (d.Minimum === 0 && d.Maximum === 0)
    return { valid: false }; if (rows.some(function (r) { return d.Minimum <= r.Maximum && d.Maximum >= r.Minimum; }))
    return { valid: false }; return { valid: true }; };
export var checkMinimum = function (d, rows) { if (d.Minimum > d.Maximum)
    return { valid: false, message: 'minmum_not_inferior_maximum' }; if (d.Minimum === 0 && d.Maximum === 0)
    return { valid: false, message: 'minmum_maximum_zero' }; if (rows.some(function (r) { return d.Minimum <= r.Maximum && d.Maximum >= r.Minimum; }))
    return { valid: false, message: "interval_exists" }; return { valid: true }; };
export var checkValueRate = function () { var msg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false; return function (d, rows, type) { var val = type ? d[type] : d; if (!(val !== null && val !== void 0 && val.Rate) && !(val !== null && val !== void 0 && val.Value))
    return { valid: false, message: msg ? "missing_value_rate" : null }; return { valid: true }; }; };
export var checkDiscountClass = function (d) { if (!d.DiscountClass)
    return { valid: false, message: "missing_discountClass" }; return { valid: true }; };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Offers } from 'hub-lib/dto/client/ref_Offers.bin';
import { KPIsManagerCache } from 'hub-lib/models/KPIsManager.bin';
import { ref_Model } from 'hub-lib/models/orientdb/ref_Model.bin';
import { clone, extractSub, GetHashCode, propertyOf } from 'hub-lib/tools.bin';
var initialState = {
    // pivotGridConfigToShow: null
    data: null, offers: null,
    get: function () { return this.data; }
};
export var setOfferData = createAsyncThunk('offer/setOfferData', function (data, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var _state$offer$data, state, currentData, currentOffers, offers, _medias$find, _await$Client$searchV, _await$Client$searchV2, _await$Client$searchV3, _await$Client$searchV4, _await$Client$searchV5, medias, presse, model, err_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log("[setData]", data);
                state = thunkAPI.getState();
                currentData = clone((_state$offer$data = state.offer.data) !== null && _state$offer$data !== void 0 ? _state$offer$data : {});
                currentOffers = state.offer.offers;
                offers = [];
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 7]);
                if (GetHashCode(extractSub(currentData, ['BroadcastArea', 'Support', 'Start', 'End'])) == GetHashCode(extractSub(data, ['BroadcastArea', 'Support', 'Start', 'End']))) {
                    console.log("[setOfferData] nothing changed", extractSub(currentData, ['BroadcastArea', 'Support', 'Start', 'End']), extractSub(data, ['BroadcastArea', 'Support', 'Start', 'End']));
                    return [2 /*return*/, { data: data, offers: currentOffers }];
                }
                Object.assign(currentData, data);
                if (!(data.BroadcastArea && data.Support)) return [3 /*break*/, 5];
                return [4 /*yield*/, KPIsManagerCache.GetMedias()];
            case 2:
                medias = _b.sent();
                presse = medias === null || medias === void 0 ? void 0 : (_medias$find = medias.find(function (media) { return media.Code == "PR"; })) === null || _medias$find === void 0 ? void 0 : _medias$find["@rid"];
                return [4 /*yield*/, Client.searchVertex(ref_Model.name, (_a = {}, _a[propertyOf("DocumentType")] = "Offer", _a[propertyOf("Country")] = data.BroadcastArea, _a[propertyOf("MediaTypes")] = [presse], _a))];
            case 3:
                model = (_await$Client$searchV = _b.sent()) === null || _await$Client$searchV === void 0 ? void 0 : (_await$Client$searchV2 = _await$Client$searchV.data) === null || _await$Client$searchV2 === void 0 ? void 0 : (_await$Client$searchV3 = _await$Client$searchV2.results) === null || _await$Client$searchV3 === void 0 ? void 0 : _await$Client$searchV3[0];
                if (!model) return [3 /*break*/, 5];
                return [4 /*yield*/, Client.searchVertex(ref_Offers.name, { Support: data.Support, BroadcastArea: data.BroadcastArea, Media: presse, Model: model["@rid"], Start: data.Start ? data.Start : undefined, End: data.End ? data.End : undefined })];
            case 4:
                offers = (_await$Client$searchV4 = _b.sent()) === null || _await$Client$searchV4 === void 0 ? void 0 : (_await$Client$searchV5 = _await$Client$searchV4.data) === null || _await$Client$searchV5 === void 0 ? void 0 : _await$Client$searchV5.results;
                _b.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                err_1 = _b.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(err_1)];
            case 7: return [2 /*return*/, { data: data, offers: offers }];
        }
    });
}); });
export var offerSlice = createSlice({ name: 'offer', initialState: initialState, reducers: { setDataSync: function (state, action) { var _state$data; console.log("[setDataSync]", action.payload); var currentData = clone((_state$data = state.data) !== null && _state$data !== void 0 ? _state$data : {}); Object.assign(currentData, action.payload); state.data = currentData; } // setCurrentTCD: (state, action: PayloadAction<AggregateExport>) => {
        //     // state.currentTCD = action.payload;
        // },
        // setPivotGridConfigToShow: (state, action: PayloadAction<ref_PivotGridConfigurations>) => {
        //     // state.pivotGridConfigToShow = action.payload;
        // }
    }, extraReducers: function (builder) {
        var fulfilledCallback = function (state, action) { var _action$payload, _action$payload2; console.log("[setOfferData.fulfilled]", action.payload); if ((_action$payload = action.payload) !== null && _action$payload !== void 0 && _action$payload.data)
            state.data = action.payload.data; if ((_action$payload2 = action.payload) !== null && _action$payload2 !== void 0 && _action$payload2.offers)
            state.offers = action.payload.offers; };
        builder.addCase(setOfferData.fulfilled, function (state, action) {
            fulfilledCallback(state, action); // state.lockNext = false;
        });
    } });
export var setDataSync = offerSlice.actions.setDataSync;
export var offerReducer = offerSlice.reducer;

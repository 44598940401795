var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ref_Discount } from "../models/types/vertex.bin";
import { ref_DiscountTypes } from "../models/orientdb/ref_DiscountTypes.bin";
import { arrayUnique, clone, extractSub, toArray, toDictionaryList } from "../tools.bin";
import { ref_DiscountClasses } from "../models/orientdb/ref_DiscountClasses.bin";
import { ref_Agreements } from "../dto/client/ref_Agreements.bin";
import { ref_GlobalAgreements } from "../dto/client/ref_GlobalAgreements.bin";
import { DataProvider } from "../provider";
import BigNumber from "bignumber.js";
import { eRights, RightManager } from "../models/types/rights.bin";
import { kpis } from "../types";
import { clnt_Referentials } from "../dto/client/clnt_Referentials";
import { ref_BroadcastAreas } from "../models/orientdb/ref_BroadcastAreas.bin";
export var netTypes = ["CO", "FO", "FOS"];
export var categoryTypesNames = [
    ["Modulations", "Gracieux"],
    ["Remises"],
    ["Taxes", "Frais", "Honoraires"]
];
var getBase = function (msg, idx, cofo) {
    switch (idx) {
        case 0:
            return msg.KPIs.Gross;
        case 1:
            return msg.KPIs.GrossBa;
        case 2:
            return DiscountManager.getNet("Net", msg, cofo);
        case 3:
            return DiscountManager.getNet("Total", msg, cofo);
        default:
            return msg.KPIs.Gross;
    }
};
var setBase = function (msg, idx, value, cofo) {
    DiscountManager.init(msg);
    switch (idx) {
        case 0:
            return msg.KPIs.GrossBa = value;
        case 1:
            return msg.KPIs["Net".concat(cofo)] = value;
        case 2:
            return msg.KPIs["Total".concat(cofo)] = value;
        default:
            return msg.KPIs.Gross;
    }
};
export var IsValidKPIVolumeBase = function (lnk, ag) { return __awaiter(void 0, void 0, void 0, function () {
    var categoryTypes, groups, i, group, indexLnk;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, DiscountManager.getCategoryTypes()];
            case 1:
                categoryTypes = _a.sent();
                groups = categoryTypes === null || categoryTypes === void 0 ? void 0 : categoryTypes.map(function (cat) { var _a; return (_a = ag.Discounts) === null || _a === void 0 ? void 0 : _a.filter(function (r) { return cat.includes(r.DiscountType); }); });
                if (!(groups === null || groups === void 0 ? void 0 : groups.length))
                    return [2 /*return*/, true];
                for (i = 0; i < groups.length; i++) {
                    group = groups[i];
                    if (!(group === null || group === void 0 ? void 0 : group.length))
                        continue;
                    indexLnk = 0;
                    if (lnk.Id.startsWith('Net'))
                        indexLnk = 1;
                    if (lnk.Id.startsWith('Total'))
                        indexLnk = 2;
                    return [2 /*return*/, i >= indexLnk];
                }
                return [2 /*return*/];
        }
    });
}); };
var discountClasses = undefined;
var discountTypes = undefined;
var addDiscounts = function (target, source) {
    return __spreadArray(__spreadArray([], target.filter(function (d) { return !source.some(function (dm) { return dm.DiscountClass == d.DiscountClass; }); }), true), source, true);
};
var DiscountManager = /** @class */ (function () {
    function DiscountManager() {
    }
    DiscountManager.SortDiscounts = function (discounts) {
        var cloneArray = __spreadArray([], (discounts !== null && discounts !== void 0 ? discounts : []), true);
        var results = [];
        var categories = DiscountManager.getCategoryTypesLoaded();
        var _loop_1 = function (cat) {
            var categoryDiscount = cloneArray.filter(function (d) { return cat.includes(d.DiscountClass); });
            results = __spreadArray(__spreadArray([], results, true), categoryDiscount.sort(DiscountManager.Sort), true);
        };
        for (var _i = 0, categories_1 = categories; _i < categories_1.length; _i++) {
            var cat = categories_1[_i];
            _loop_1(cat);
        }
        return results;
    };
    DiscountManager.Sort = function (d1, d2) {
        if (d1.Rank === d2.Rank) {
            if (d1.LastPosition == d2.LastPosition)
                return 0;
            if (d1.LastPosition)
                return 1;
            if (d2.LastPosition)
                return -1;
        }
        return d1.Rank - d2.Rank;
    };
    DiscountManager.lockDiscountsLockedFromMAP = function (message) {
        var _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var saveAllDiscounts, discountsUnlockedFromUser, remisesMAP_1, views_1;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!((message === null || message === void 0 ? void 0 : message.Import) && !((_b = message === null || message === void 0 ? void 0 : message.Import) === null || _b === void 0 ? void 0 : _b['autoLock']))) return [3 /*break*/, 2];
                        message.Import['autoLock'] = true;
                        saveAllDiscounts = (_c = message.Discounts) !== null && _c !== void 0 ? _c : [];
                        discountsUnlockedFromUser = saveAllDiscounts.filter(function (s) { return !s.Agreement && !s.Locked; });
                        if (!discountsUnlockedFromUser.length) return [3 /*break*/, 2];
                        remisesMAP_1 = (_e = (_d = message.Import) === null || _d === void 0 ? void 0 : _d.Data) === null || _e === void 0 ? void 0 : _e['REMISES'];
                        if (!(remisesMAP_1 === null || remisesMAP_1 === void 0 ? void 0 : remisesMAP_1.length)) return [3 /*break*/, 2];
                        return [4 /*yield*/, DataProvider.getVwMMDiscountClasses()];
                    case 1:
                        views_1 = _f.sent();
                        //console.log(`[INIT AGR] views`, views)
                        discountsUnlockedFromUser
                            .forEach(function (d) {
                            var viewsFound = views_1.filter(function (v) { return v.Referential == d.DiscountClass; });
                            //console.log(`[INIT AGR] viewsFound`, viewsFound)
                            if (viewsFound === null || viewsFound === void 0 ? void 0 : viewsFound.length) {
                                var remiseMap = remisesMAP_1.find(function (r) { return viewsFound.some(function (v) { var _b; return v.ExternalID == ((_b = r.MM_DEGRESSIF_ID) === null || _b === void 0 ? void 0 : _b.toString()); }); });
                                d.Locked = (remiseMap === null || remiseMap === void 0 ? void 0 : remiseMap['TYPEAPP']) == 2;
                            }
                        });
                        _f.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    DiscountManager.initGlobalAgreement = function (message, opt) {
        var _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var _e, refreshCurrent, globalAgreement, updateCascade, previousGlobalAgreement, saveAllDiscounts, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        if (message.PricingLocked)
                            return [2 /*return*/];
                        if (!RightManager.hasRight(ref_GlobalAgreements.name, eRights.read))
                            return [2 /*return*/];
                        _e = __assign({ updateCascade: true, refreshCurrent: false, agreement: undefined }, opt), refreshCurrent = _e.refreshCurrent, globalAgreement = _e.agreement, updateCascade = _e.updateCascade;
                        if (refreshCurrent && !message.GlobalAgreement)
                            return [2 /*return*/];
                        previousGlobalAgreement = message.GlobalAgreement;
                        saveAllDiscounts = __spreadArray([], ((_b = message.Discounts) !== null && _b !== void 0 ? _b : []), true);
                        if (!!globalAgreement) return [3 /*break*/, 5];
                        if (!refreshCurrent) return [3 /*break*/, 2];
                        return [4 /*yield*/, DataProvider.search(ref_GlobalAgreements.name, {
                                "@rid": message.GlobalAgreement,
                                Active: true,
                            })];
                    case 1:
                        _f = _g.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, getAvailableGlobalAgreements(message)];
                    case 3:
                        _f = _g.sent();
                        _g.label = 4;
                    case 4:
                        globalAgreement = (_f)[0];
                        _g.label = 5;
                    case 5:
                        message.GlobalAgreement = globalAgreement === null || globalAgreement === void 0 ? void 0 : globalAgreement["@rid"];
                        message.GlobalAgreementLastUpdate = new Date();
                        //message.DiscountMode = 'cascade';
                        if (globalAgreement) { // prevent old @rid => call will be null
                            (_c = globalAgreement.Discounts) === null || _c === void 0 ? void 0 : _c.forEach(function (d) { return d.Agreement = globalAgreement['@rid']; });
                            message.DiscountMode = globalAgreement.DiscountMode;
                        }
                        // add discounts that are not locked and not from an agreement
                        message.Discounts = saveAllDiscounts.filter(function (s) { return !Boolean(s.Agreement) && !s.Locked; });
                        // add discounts of the global agreement
                        message.Discounts = addDiscounts(message.Discounts, (_d = globalAgreement === null || globalAgreement === void 0 ? void 0 : globalAgreement.Discounts) !== null && _d !== void 0 ? _d : []);
                        // if global agreement exists and did not changed, then apply discounts that are not locked and that also exists in global agreement
                        if (!(opt === null || opt === void 0 ? void 0 : opt.replaceUnlocked) && message.GlobalAgreement && (message.GlobalAgreement == previousGlobalAgreement))
                            message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(function (s) { return globalAgreement.Discounts.some(function (g) { return g.DiscountClass == s.DiscountClass; }) && !s.Locked; }));
                        // add all discounts that are from an agreement
                        message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(function (s) { return s.Agreement == message.Agreement; }));
                        // add locked discounts
                        message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(function (s) { return s.Locked; }));
                        message.Discounts
                            .filter(function (d) { return d.Locked; })
                            .forEach(function (d) {
                            var _b, _c;
                            if (d.Agreement != message.Agreement) {
                                if ((_c = (_b = globalAgreement === null || globalAgreement === void 0 ? void 0 : globalAgreement.Discounts) === null || _b === void 0 ? void 0 : _b.some) === null || _c === void 0 ? void 0 : _c.call(_b, function (dm) { return dm.DiscountClass == d.DiscountClass; }))
                                    d.Agreement = globalAgreement['@rid'];
                                else
                                    d.Agreement = null;
                            }
                        });
                        if (!message.DiscountMode)
                            message.DiscountMode = "cascade";
                        if (!updateCascade) return [3 /*break*/, 7];
                        return [4 /*yield*/, DiscountManager.UpdateCascade(message, null)];
                    case 6:
                        _g.sent();
                        _g.label = 7;
                    case 7: return [2 /*return*/, globalAgreement];
                }
            });
        });
    };
    DiscountManager.initAgreement = function (item, opt) {
        var _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var messages, _e, refreshCurrent, agreement, updateCascade, globalAgreement, globalAgreementID, _loop_2, _i, messages_1, message;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!RightManager.hasRight(ref_Agreements.name, eRights.read))
                            return [2 /*return*/];
                        messages = toArray(item).filter(function (m) { return !(m === null || m === void 0 ? void 0 : m.PricingLocked); });
                        _e = __assign({ updateCascade: true, refreshCurrent: false, agreement: undefined }, opt), refreshCurrent = _e.refreshCurrent, agreement = _e.agreement, updateCascade = _e.updateCascade;
                        if (!agreement) return [3 /*break*/, 2];
                        globalAgreementID = arrayUnique(messages.map(function (m) { return m.GlobalAgreement; }));
                        if (!globalAgreementID) return [3 /*break*/, 2];
                        return [4 /*yield*/, DataProvider.search(ref_GlobalAgreements.name, {
                                "@rid": globalAgreementID,
                                Active: true,
                            })];
                    case 1:
                        globalAgreement = (_f.sent())[0];
                        _f.label = 2;
                    case 2:
                        _loop_2 = function (message) {
                            var getMsg, saveAllDiscounts, previousGlobalAgreement, appliedGlobalAgreement, config;
                            return __generator(this, function (_g) {
                                switch (_g.label) {
                                    case 0:
                                        getMsg = function () { return extractSub(message, [
                                            "@rid",
                                            "AdvertiserGroup",
                                            "Advertiser",
                                            "Group",
                                            "Brand",
                                            "Product",
                                            "BroadcastArea",
                                            "Format",
                                            "Placement",
                                            "Support",
                                            "Currency",
                                            "Media",
                                            "Start",
                                            "End",
                                            "Discounts",
                                            "KPIs"
                                        ]); };
                                        if (!((!agreement && !refreshCurrent) || (refreshCurrent && message.Agreement))) return [3 /*break*/, 2];
                                        return [4 /*yield*/, DataProvider.search(ref_Agreements.name, __assign(__assign({}, (refreshCurrent && { "@rid": message.Agreement })), { Active: true, prepared: true, message: getMsg() }))];
                                    case 1:
                                        agreement = (_g.sent())[0];
                                        _g.label = 2;
                                    case 2:
                                        saveAllDiscounts = __spreadArray([], ((_b = message.Discounts) !== null && _b !== void 0 ? _b : []), true);
                                        // clear discounts of the message
                                        message.Discounts = [];
                                        previousGlobalAgreement = message.GlobalAgreement;
                                        return [4 /*yield*/, DiscountManager.initGlobalAgreement(message, { updateCascade: false, agreement: message.GlobalAgreement ? globalAgreement : undefined })];
                                    case 3:
                                        appliedGlobalAgreement = _g.sent();
                                        // if global agreement exists and did not changed, then apply discounts that are not locked and that also exists in global agreement
                                        if (((_c = appliedGlobalAgreement === null || appliedGlobalAgreement === void 0 ? void 0 : appliedGlobalAgreement.Discounts) === null || _c === void 0 ? void 0 : _c.length) && message.GlobalAgreement && (message.GlobalAgreement == previousGlobalAgreement))
                                            message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(function (s) { return appliedGlobalAgreement.Discounts.some(function (g) { return g.DiscountClass == s.DiscountClass; }) && !s.Locked; }));
                                        // add discounts that are not locked and not from an agreement
                                        message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(function (s) { return !Boolean(s.Agreement) && !s.Locked; }));
                                        config = ["CO", "FO", "FOS"];
                                        (_d = agreement === null || agreement === void 0 ? void 0 : agreement.Discounts) === null || _d === void 0 ? void 0 : _d.forEach(function (d) {
                                            var _b, _c;
                                            d.Agreement = agreement['@rid'];
                                            if (!config.includes("CO")) {
                                                d.FO = DiscountManager.getModulation(d, "FO");
                                                d.CO = (_b = saveAllDiscounts.find(function (dm) { return dm.DiscountClass == d.DiscountClass; })) === null || _b === void 0 ? void 0 : _b.CO;
                                            }
                                            if (!config.includes("FO")) {
                                                d.FOS = DiscountManager.getModulation(d, "FOS");
                                                d.FO = (_c = saveAllDiscounts.find(function (dm) { return dm.DiscountClass == d.DiscountClass; })) === null || _c === void 0 ? void 0 : _c.FO;
                                            }
                                        });
                                        message.Agreement = agreement ? agreement["@rid"] : null;
                                        message.AgreementLastUpdate = new Date();
                                        // if there is an agreement => add discounts of the agreement
                                        if (agreement) {
                                            message.DiscountMode = agreement.DiscountMode;
                                            message.Discounts = addDiscounts(message.Discounts, agreement.Discounts);
                                        }
                                        // if messages from MAP
                                        return [4 /*yield*/, DiscountManager.lockDiscountsLockedFromMAP(message)];
                                    case 4:
                                        // if messages from MAP
                                        _g.sent();
                                        message.Discounts = addDiscounts(message.Discounts, saveAllDiscounts.filter(function (s) { return s.Locked; }));
                                        // final step
                                        message.Discounts
                                            .filter(function (d) { return d.Locked; })
                                            .forEach(function (d) {
                                            var _b, _c;
                                            if ((_c = (_b = agreement === null || agreement === void 0 ? void 0 : agreement.Discounts) === null || _b === void 0 ? void 0 : _b.some) === null || _c === void 0 ? void 0 : _c.call(_b, function (dm) { return dm.DiscountClass == d.DiscountClass; }))
                                                d.Agreement = agreement['@rid'];
                                            else if (d.Agreement != message.GlobalAgreement)
                                                d.Agreement = null;
                                        });
                                        if (!message.DiscountMode)
                                            message.DiscountMode = "cascade";
                                        if (!updateCascade) return [3 /*break*/, 6];
                                        return [4 /*yield*/, DiscountManager.UpdateCascade(message, null)];
                                    case 5:
                                        _g.sent();
                                        _g.label = 6;
                                    case 6: return [2 /*return*/];
                                }
                            });
                        };
                        _i = 0, messages_1 = messages;
                        _f.label = 3;
                    case 3:
                        if (!(_i < messages_1.length)) return [3 /*break*/, 6];
                        message = messages_1[_i];
                        return [5 /*yield**/, _loop_2(message)];
                    case 4:
                        _f.sent();
                        _f.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    DiscountManager.GetValue = function (discounts, discountClass, netType, type) {
        var _b, _c, _d, _e;
        DiscountManager.cleanDiscounts(discounts);
        var discount = discounts === null || discounts === void 0 ? void 0 : discounts.find(function (d) { return d.DiscountClass === discountClass; });
        if (discount) {
            if (type == "ValueCPM")
                return (_b = discount.ValueCPM) !== null && _b !== void 0 ? _b : 0;
            var res = (_e = (_d = (_c = DiscountManager.valid(discount[netType])) !== null && _c !== void 0 ? _c : (netType === "FOS" ? DiscountManager.valid(discount.FO) : undefined)) !== null && _d !== void 0 ? _d : DiscountManager.valid(discount.CO)) !== null && _e !== void 0 ? _e : discount;
            return type === "Rate" ? res.Rate : res.Value;
        }
        return undefined;
    };
    DiscountManager.Load = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, DiscountManager.GetDiscountClasses()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, DiscountManager.GetDiscountTypes()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DiscountManager.GetDiscountClasses = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!discountClasses) return [3 /*break*/, 2];
                        return [4 /*yield*/, DataProvider.search((ref_DiscountClasses.name))];
                    case 1:
                        discountClasses = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, discountClasses];
                }
            });
        });
    };
    DiscountManager.GetDiscountTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!discountTypes) return [3 /*break*/, 2];
                        return [4 /*yield*/, DataProvider.search((ref_DiscountTypes.name))];
                    case 1:
                        discountTypes = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, discountTypes];
                }
            });
        });
    };
    DiscountManager.GetDiscountClassesLoaded = function () {
        return discountClasses;
    };
    DiscountManager.GetDiscountTypesLoaded = function () {
        return discountTypes;
    };
    DiscountManager.initKPI = function (msg, kpi) {
        if (!msg.KPIs)
            msg.KPIs = new kpis();
        if (!msg.KPIs.hasOwnProperty(kpi))
            msg.KPIs[kpi] = 0;
    };
    DiscountManager.init = function (msg) {
        DiscountManager.initKPI(msg, "NetCO");
        DiscountManager.initKPI(msg, "NetFO");
        DiscountManager.initKPI(msg, "NetFOS");
        DiscountManager.initKPI(msg, "TotalCO");
        DiscountManager.initKPI(msg, "TotalFO");
        DiscountManager.initKPI(msg, "TotalFOS");
    };
    DiscountManager.getNet = function (str, msg, cofo) {
        return msg.KPIs[str + cofo];
    };
    DiscountManager.SetFees = function (msg, fee, discountClass, discountType) {
        var _b;
        return __awaiter(this, void 0, void 0, function () {
            var fees, rate, dClass;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        fees = (_b = msg === null || msg === void 0 ? void 0 : msg.Discounts) === null || _b === void 0 ? void 0 : _b.find(function (d) { return d.DiscountClass == discountClass; });
                        if (!fees) {
                            fees = new ref_Discount();
                            msg.Discounts.push(fees);
                        }
                        rate = fee / 100;
                        fees.DiscountClass = discountClass;
                        fees.DiscountType = discountType;
                        fees.Rank = 1;
                        fees.IsRate = true;
                        fees.Rate = rate;
                        fees.CO = { Rate: rate, Value: 0 };
                        fees.Agreement = null;
                        return [4 /*yield*/, DataProvider.search(ref_DiscountClasses.name, { '@rid': discountClass })];
                    case 1:
                        dClass = (_c.sent())[0];
                        fees.Operator = dClass.Operator;
                        return [2 /*return*/, fees];
                }
            });
        });
    };
    DiscountManager.UpdateCascade = function (msg, campaign) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: 
                    //if (msg?.PricingLocked) return;
                    return [4 /*yield*/, DiscountManager.Load()];
                    case 1:
                        //if (msg?.PricingLocked) return;
                        _b.sent();
                        return [2 /*return*/, DiscountManager.UpdateCascadeSync(msg, campaign)];
                }
            });
        });
    };
    DiscountManager.UpdateCascadeSync = function (msg, campaign) {
        //if (msg.DiscountMode != "cascade" && msg.Discounts?.length)
        //msg.Discounts = [...msg.Discounts].sort((a, b) => a.Rank - b.Rank);
        if (!msg.Discounts)
            msg.Discounts = [];
        var computations = { CO: [], FO: [], FOS: [] };
        DiscountManager.cleanDiscounts(msg.Discounts);
        msg.Discounts.sort(DiscountManager.Sort);
        var list = toDictionaryList(msg.Discounts, function (d) { return d.DiscountClass; });
        // if (Object.values(list).length == 0)
        //     return computations;
        Object.values(list).forEach(function (val) {
            if (val.length > 1) {
                console.log(msg);
                throw new Error("Too many discounts type:".concat(val[0].DiscountType, " class:").concat(val[0].DiscountClass));
            }
        });
        var categoryTypes = DiscountManager.getCategoryTypesLoaded();
        var groups = categoryTypes === null || categoryTypes === void 0 ? void 0 : categoryTypes.map(function (cat) { var _b, _c; return (_c = (_b = msg.Discounts) === null || _b === void 0 ? void 0 : _b.filter(function (r) { return cat.includes(r.DiscountType); })) !== null && _c !== void 0 ? _c : []; });
        var cofos = ["CO", "FO", "FOS"];
        var setRow = function (row) {
            if (row.Value == -0)
                row.Value = 0;
            if (row.Rate == -0)
                row.Rate = 0;
            if (isNaN(row.Rate))
                row.Rate = 0;
            if (isNaN(row.Value))
                row.Value = 0;
        };
        var computeRows = function (rows, currentBase, idx, cofo, cascade) {
            if (cascade === void 0) { cascade = true; }
            rows.sort(sortPosition);
            var totalRemise = 0;
            rows.forEach(function (row) {
                var _b;
                var rowBase = currentBase + (cascade ? totalRemise : 0);
                var discountClass = DiscountManager.GetDiscountClassesLoaded().find(function (dc) { return dc["@rid"] === row.DiscountClass; });
                if (discountClass === null || discountClass === void 0 ? void 0 : discountClass.Base) {
                    var base_1 = discountClass.Base;
                    switch (base_1.Type) {
                        case "DiscountType":
                            var allFrais = (_b = msg.Discounts) === null || _b === void 0 ? void 0 : _b.filter(function (r) { var _b; return (r === null || r === void 0 ? void 0 : r.DiscountType) === ((_b = base_1.Data) === null || _b === void 0 ? void 0 : _b.toString()) && r !== row; });
                            var res = allFrais.map(function (frais) { var _b, _c; return (_c = (_b = DiscountManager.getModulation(frais, cofo)) === null || _b === void 0 ? void 0 : _b.Value) !== null && _c !== void 0 ? _c : 0; }).reduce(function (a, b) { return a + b; }, 0);
                            rowBase = res;
                            break;
                        case "KPI":
                            rowBase = msg.KPIs[base_1.Field + cofo];
                            break;
                        default:
                            console.error("base.Type not found:", base_1);
                            break;
                    }
                }
                var _c = DiscountManager.getRemise(row, rowBase, cofo, msg.KPIs, campaign === null || campaign === void 0 ? void 0 : campaign.KPIs), remise = _c.remise, rate = _c.rate;
                setRow(row);
                if (!isNaN(remise)) {
                    totalRemise += remise;
                    if (row.IsRate) {
                        var base = DiscountManager.valid(row[cofo]);
                        if (base)
                            base.Value = remise;
                    }
                }
                var cloneBase = clone(DiscountManager.getModulation(row, cofo));
                var cloneRow = clone(row);
                cloneRow[cofo] = cloneBase;
                if (row.IsRate)
                    cloneBase.Value = remise;
                else
                    cloneBase.Rate = rate;
                computations[cofo].push({
                    Base: rowBase,
                    Discount: cloneRow
                });
            });
            return totalRemise;
        };
        cofos.forEach(function (cofo) {
            groups === null || groups === void 0 ? void 0 : groups.forEach(function (grows, idx) {
                var _b;
                var currentBase = (_b = getBase(msg, idx, cofo)) !== null && _b !== void 0 ? _b : 0; // base
                if (msg.DiscountMode === "cumul") {
                    var ranks = Array.from(new Set(grows.map(function (r) { return r.Rank; })));
                    ranks.sort(function (a, b) { return (a - b); });
                    ranks.forEach(function (rank) {
                        var currentRows = grows.filter(function (row) { return row.Rank === rank; });
                        var totalRemise = computeRows(currentRows, currentBase, idx, cofo, false);
                        currentBase += totalRemise;
                    });
                }
                else {
                    var totalRemise = computeRows(grows, currentBase, idx, cofo, true);
                    currentBase += totalRemise;
                }
                setBase(msg, idx, currentBase, cofo);
            });
        });
        return computations;
    };
    var _a;
    _a = DiscountManager;
    DiscountManager.GetRefDiscountTypes = function (names) { return __awaiter(void 0, void 0, Promise, function () {
        var types, res;
        return __generator(_a, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, DiscountManager.GetDiscountTypes()];
                case 1:
                    types = _b.sent();
                    res = null;
                    res = types.filter(function (t) { return names.includes(t.Name); });
                    return [2 /*return*/, res];
            }
        });
    }); };
    DiscountManager.getCategoryTypes = function (filterNames) { return __awaiter(void 0, void 0, void 0, function () {
        var categoryTypes, idx, names, res;
        var _b;
        return __generator(_a, function (_c) {
            switch (_c.label) {
                case 0:
                    categoryTypes = [];
                    idx = 0;
                    _c.label = 1;
                case 1:
                    if (!(idx < categoryTypesNames.length)) return [3 /*break*/, 4];
                    names = (_b = categoryTypesNames[idx]) === null || _b === void 0 ? void 0 : _b.filter(function (n) { return (filterNames ? filterNames(n) : true); });
                    return [4 /*yield*/, DiscountManager.GetRefDiscountTypes(names)];
                case 2:
                    res = _c.sent();
                    categoryTypes[idx] = res.map(function (r) { return r["@rid"]; });
                    _c.label = 3;
                case 3:
                    idx++;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/, categoryTypes];
            }
        });
    }); };
    DiscountManager.getCategoryTypesLoaded = function (filterNames) {
        var _b;
        var categoryTypes = [];
        var _loop_3 = function (idx) {
            var names = (_b = categoryTypesNames[idx]) === null || _b === void 0 ? void 0 : _b.filter(function (n) { return (filterNames ? filterNames(n) : true); });
            var res = DiscountManager.GetDiscountTypesLoaded().filter(function (t) { return names.includes(t.Name); });
            categoryTypes[idx] = res.map(function (r) { return r["@rid"]; });
        };
        for (var idx = 0; idx < categoryTypesNames.length; idx++) {
            _loop_3(idx);
        }
        return categoryTypes;
    };
    DiscountManager.valid = function (v) {
        return v !== null && v !== void 0 ? v : undefined;
    };
    DiscountManager.getModulation = function (discount, cofo) {
        var _b, _c, _d;
        return (_d = (_c = (_b = DiscountManager.valid(discount[cofo])) !== null && _b !== void 0 ? _b : (cofo === "FOS" ? DiscountManager.valid(discount.FO) : undefined)) !== null && _c !== void 0 ? _c : DiscountManager.valid(discount.CO)) !== null && _d !== void 0 ? _d : { Rate: 0, Value: 0 };
    };
    DiscountManager.getRemise = function (discount, rowBase, cofo, KPIs, campaignKPIs) {
        var _b;
        var realMod = DiscountManager.valid(discount[cofo]);
        var mod = DiscountManager.getModulation(discount, cofo);
        var remise = 0;
        var rate = 0;
        if (discount.IsRate) {
            remise = new BigNumber(mod.Rate).multipliedBy(rowBase).abs().multipliedBy(100).toNumber();
            remise = discount.Operator * Math.round(remise) / 100;
            rate = mod.Rate;
            if (realMod)
                realMod.Value = remise;
        }
        else {
            if (discount.IsCPM) {
                var kpiValue = (_b = KPIs[discount.KpiCPM]) !== null && _b !== void 0 ? _b : campaignKPIs === null || campaignKPIs === void 0 ? void 0 : campaignKPIs[discount.KpiCPM];
                remise = new BigNumber(discount.ValueCPM).multipliedBy(kpiValue).dividedBy(10).toNumber();
                remise = Math.round(remise) / 100;
                if (realMod)
                    realMod.Value = remise;
            }
            else
                remise = Math.abs(mod.Value) * discount.Operator;
            rate = rowBase ? (remise / rowBase) : 0;
            if (realMod)
                realMod.Rate = rate;
        }
        return { remise: remise, rate: rate };
    };
    DiscountManager.setRowType = function (t, row, prop, value) {
        if (t) {
            if (!row[t])
                row[t] = { Rate: 0, Value: 0 };
            row[t][prop] = value;
        }
    };
    DiscountManager.cleanDiscounts = function (discounts) {
        var valid = function (v) {
            if (((v === null || v === void 0 ? void 0 : v.Value) === undefined || (v === null || v === void 0 ? void 0 : v.Value) === null) && ((v === null || v === void 0 ? void 0 : v.Rate) === undefined || (v === null || v === void 0 ? void 0 : v.Rate) === null))
                return false;
            return true;
        };
        discounts === null || discounts === void 0 ? void 0 : discounts.forEach(function (d) {
            if (!valid(d.CO))
                delete d.CO;
            if (!valid(d.FO))
                delete d.FO;
            if (!valid(d.FOS))
                delete d.FOS;
        });
    };
    return DiscountManager;
}());
export { DiscountManager };
var sortPosition = function (a, b) {
    if (a.LastPosition && !b.LastPosition)
        return 1;
    if (!a.LastPosition && b.LastPosition)
        return -1;
    return 0;
};
export var agreementPerimeter = [
    "@rid",
    "AdvertiserGroup",
    "Advertiser",
    "Group",
    "Brand",
    "Product",
    "BroadcastArea",
    "Format",
    "Placement",
    "Support",
    "Currency",
    "Media",
    "Start",
    "End",
    "KPIs"
];
export function getAvailableDiscountRates(message) {
    return __awaiter(this, void 0, Promise, function () {
        var discountRates, clntReferentials, getParentBroadcastArea, searchRates;
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!message.BroadcastArea || !message.Media)
                        return [2 /*return*/, null];
                    discountRates = {};
                    if (message.Advertiser)
                        discountRates[message.Advertiser] = null;
                    if (message.AdvertiserGroup)
                        discountRates[message.AdvertiserGroup] = null;
                    if (Object.keys(discountRates).length == 0)
                        return [2 /*return*/, null];
                    return [4 /*yield*/, DataProvider.search(clnt_Referentials.name, { "Referential": Object.keys(discountRates) })];
                case 1:
                    clntReferentials = (_b.sent());
                    if (clntReferentials.length == 0)
                        return [2 /*return*/, null];
                    Object.keys(discountRates).forEach(function (key) { var _b, _c; return discountRates[key] = (_c = (_b = clntReferentials.find(function (c) { return c.Referential == key; })) === null || _b === void 0 ? void 0 : _b.DiscountRates) === null || _c === void 0 ? void 0 : _c[message.Media]; });
                    getParentBroadcastArea = function (broadcastAreaId) { return __awaiter(_this, void 0, void 0, function () {
                        var broadcastArea;
                        var _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0: return [4 /*yield*/, DataProvider.search(ref_BroadcastAreas.name, { "@rid": broadcastAreaId, parent: true })];
                                case 1:
                                    broadcastArea = (_b = (_c.sent())) === null || _b === void 0 ? void 0 : _b[0];
                                    return [2 /*return*/, broadcastArea === null || broadcastArea === void 0 ? void 0 : broadcastArea["parent"]];
                            }
                        });
                    }); };
                    searchRates = function (broadcastAreaId) { return __awaiter(_this, void 0, void 0, function () {
                        var _i, _b, key, rates, parent;
                        var _c;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    for (_i = 0, _b = Object.keys(discountRates); _i < _b.length; _i++) {
                                        key = _b[_i];
                                        rates = (_c = discountRates === null || discountRates === void 0 ? void 0 : discountRates[key]) === null || _c === void 0 ? void 0 : _c.find(function (r) { return r.BroadcastArea == broadcastAreaId; });
                                        if (rates)
                                            return [2 /*return*/, rates];
                                    }
                                    return [4 /*yield*/, getParentBroadcastArea(broadcastAreaId)];
                                case 1:
                                    parent = _d.sent();
                                    /*recurse(broadcastAreas, <any>"children", item => {
                                        if (item["@rid"] == broadcastAreaId) {
                                            if (item["parent"])
                                                parent = item["parent"];
                                            //else
                                            //    break;
                                        }
                                    })*/
                                    if (parent)
                                        return [2 /*return*/, searchRates(parent)];
                                    return [2 /*return*/, null];
                            }
                        });
                    }); };
                    return [2 /*return*/, searchRates(message.BroadcastArea)];
            }
        });
    });
}
export function InitDiscountRates(message, rates, oldRates) {
    return __awaiter(this, void 0, void 0, function () {
        var discountClasses, keyToRemove_1, discounts;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (message === null || message === void 0 ? void 0 : message.PricingLocked)
                        return [2 /*return*/];
                    return [4 /*yield*/, DiscountManager.GetDiscountClasses()];
                case 1:
                    discountClasses = _b.sent();
                    if (!message.Discounts)
                        message.Discounts = [];
                    if (rates == null) {
                        if (!oldRates)
                            return [2 /*return*/];
                        keyToRemove_1 = Object.keys(oldRates.Rates).filter(function (k) { return !message.Discounts.some(function (d) { return d.DiscountClass == k && d.Locked; }); });
                        if (keyToRemove_1.length)
                            message.Discounts = message.Discounts.filter(function (d) { return !keyToRemove_1.includes(d.DiscountClass); });
                        return [2 /*return*/];
                    }
                    discounts = Object.keys(rates.Rates).filter(function (k) { return !message.Discounts.some(function (d) { return d.DiscountClass == k && d.Locked; }); })
                        .map(function (k) {
                        var discountClass = discountClasses.find(function (c) { return c['@rid'] == k; });
                        var discount = new ref_Discount();
                        discount.IsRate = true;
                        discount.DiscountClass = discountClass['@rid'];
                        discount.DiscountType = discountClass.DiscountType;
                        discount.LastPosition = discountClass.LastPosition;
                        discount.Operator = discountClass.Operator;
                        discount.Rank = discountClass.Rank;
                        discount.CO = { Rate: rates.Rates[k], Value: 0 };
                        return discount;
                    });
                    if (discounts.length)
                        message.Discounts = addDiscounts(message.Discounts, discounts);
                    return [2 /*return*/];
            }
        });
    });
}
export var getAvailableAgreements = function (message) { return __awaiter(void 0, void 0, void 0, function () {
    var agreements, messageAgreement;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!RightManager.hasRight(ref_Agreements.name, eRights.read))
                    return [2 /*return*/, []];
                return [4 /*yield*/, DataProvider.search(ref_Agreements.name, {
                        Active: true,
                        prepared: true,
                        message: extractSub(message, agreementPerimeter)
                    })];
            case 1:
                agreements = _b.sent();
                if (!(message.Agreement && !agreements.some(function (a) { return a["@rid"] == message.Agreement; }))) return [3 /*break*/, 3];
                return [4 /*yield*/, DataProvider.search(ref_Agreements.name, { "@rid": message.Agreement, Active: [true, false] })];
            case 2:
                messageAgreement = (_b.sent())[0];
                if (messageAgreement) {
                    messageAgreement["warning"] = true;
                    agreements.unshift(messageAgreement);
                }
                _b.label = 3;
            case 3:
                if (!agreements.length) {
                    console.log("applyAgreement no agreement");
                    return [2 /*return*/, undefined];
                }
                return [2 /*return*/, agreements];
        }
    });
}); };
export var getAvailableGlobalAgreements = function (message) { return __awaiter(void 0, void 0, void 0, function () {
    var globalAgreements;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, DataProvider.search(ref_GlobalAgreements.name, {
                    Active: true,
                    message: extractSub(message, [
                        "@rid",
                        "BroadcastArea",
                        "Currency",
                        "Media",
                        "Start",
                        "End"
                    ])
                })];
            case 1:
                globalAgreements = _b.sent();
                // LEGACY
                globalAgreements === null || globalAgreements === void 0 ? void 0 : globalAgreements.forEach(function (g) { var _b; return (_b = g === null || g === void 0 ? void 0 : g.Discounts) === null || _b === void 0 ? void 0 : _b.forEach(function (d) { return d.Agreement = g['@rid']; }); });
                return [2 /*return*/, globalAgreements];
        }
    });
}); };
var discountTypesNonEditables = undefined;
export var DiscountCanBeEdited = function (type) {
    return !(discountTypesNonEditables === null || discountTypesNonEditables === void 0 ? void 0 : discountTypesNonEditables.includes(type));
};
export function InitEditableDiscountsList() {
    return __awaiter(this, void 0, void 0, function () {
        var fees;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, DiscountManager.Load()];
                case 1:
                    _b.sent();
                    if (!(discountTypesNonEditables === undefined)) return [3 /*break*/, 3];
                    return [4 /*yield*/, DiscountManager.GetDiscountTypes()];
                case 2:
                    fees = (_b.sent()).find(function (t) { return t.Name == 'Frais'; });
                    discountTypesNonEditables = [fees === null || fees === void 0 ? void 0 : fees["@rid"]];
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
export var getAvailableDiscountClasses = function (discounts, cofo, media, ignoredTypes) {
    var availableTypes = DiscountManager.GetDiscountTypesLoaded().filter(function (t) { return (cofo == "CO" || (DiscountCanBeEdited === null || DiscountCanBeEdited === void 0 ? void 0 : DiscountCanBeEdited(t['@rid'])))
        && t.Name != "Barter" && !(ignoredTypes === null || ignoredTypes === void 0 ? void 0 : ignoredTypes.includes(t.Name)); });
    return DiscountManager.GetDiscountClassesLoaded()
        .filter(function (c) { var _b; return !media || !c.Medias || ((_b = c.Medias) === null || _b === void 0 ? void 0 : _b.includes(media)); })
        .filter(function (c) { return !(discounts === null || discounts === void 0 ? void 0 : discounts.some(function (d) { return d.DiscountClass == c["@rid"]; })); })
        .filter(function (c) { return availableTypes.some(function (t) { return t["@rid"] == c.DiscountType; }); });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EncapsulatedMethod, ErrorCatching } from "./EncapsulatedMethod";
import { EventEmitter } from 'events';
import { clone, duplicate } from "hub-lib/tools.bin";
// pour activer tous les logs:
// node .\server\server.bin.js -v dbsd
var logEvent = new EventEmitter();
var LogBase = /** @class */ (function () {
    function LogBase() {
    }
    return LogBase;
}());
export { LogBase };
/**
 * Layer types
 */
export var eLayerType;
(function (eLayerType) {
    eLayerType["DataAccess"] = "DataAccess";
    eLayerType["Performance"] = "Performance";
    eLayerType["Business"] = "Business";
    eLayerType["Service"] = "Service";
    eLayerType["User"] = "User";
    eLayerType["Error"] = "Error";
    eLayerType["ConnexionLost"] = "ConnexionLost";
    eLayerType["Ava"] = "Ava";
    eLayerType["Default"] = "Default";
    eLayerType["Rights"] = "Rights";
    eLayerType["MediaOcean"] = "MediaOcean";
    eLayerType["MasterData"] = "MasterData";
    eLayerType["Hook"] = "Hook";
    eLayerType["Worker"] = "Worker";
    eLayerType["Boot"] = "Boot";
    eLayerType["LockingEventLoop"] = "LockingEventLoop";
    eLayerType["SocketClient"] = "SocketClient";
    eLayerType["SocketError"] = "SocketError";
    eLayerType["SocketMessage"] = "SocketMessage";
    eLayerType["TSMError"] = "TSMError";
    eLayerType["OfferError"] = "OfferError";
    eLayerType["Medialand"] = "Medialand";
    eLayerType["SSO"] = "SSO";
    eLayerType["Mail"] = "Mail";
    eLayerType["MAP"] = "MAP";
    eLayerType["Task"] = "Task";
    eLayerType["Security"] = "Security";
    eLayerType["File"] = "File";
    eLayerType["Sellsy"] = "Sellsy";
})(eLayerType || (eLayerType = {}));
export var InfoProviders = [];
export var BeforeLog = [];
var consoleLog = function (report) { return console.log(report); };
// only error on console
SetOutput(eLayerType.Error, consoleLog);
var detailLevel = 0;
var logActivation = {
    Error: true,
    Default: true
};
export var BaseLogActivated = function () { return process.argv.includes(argVerbose) || process.argv.includes("-verb"); };
/** Check if logs are activated */
var argVerbose = "-v";
var argVerboseLevel = "-vLevel";
export var getDicoLogs = function () { return logActivation; };
export var logActivated = function (layer) {
    if (layer === void 0) { layer = eLayerType.Default; }
    return BaseLogActivated() && logActivation[eLayerType[layer]];
};
var ActivateArgs = function () {
    var _a, _b;
    if (process.argv.includes(argVerboseLevel)) {
        var idxV = process.argv.indexOf(argVerboseLevel);
        var parsed = parseInt((_a = process.argv) === null || _a === void 0 ? void 0 : _a[idxV + 1], 10);
        if (!isNaN(parsed)) {
            SetLevelLogs(parsed);
        }
    }
    if (process.argv.includes("-verb")) {
        var idxV = process.argv.indexOf("-verb");
        var parsed = parseInt((_b = process.argv) === null || _b === void 0 ? void 0 : _b[idxV + 1], 10);
        if (!isNaN(parsed)) {
            SetLevelLogs(parsed);
        }
    }
};
export var consoleLogsActivated = function () {
    var _a, _b;
    if (!BaseLogActivated()) {
        console.log("\x1b[36m", "Add ".concat(argVerbose, " arg to activate logs."), "\x1b[0m");
        console.log("\x1b[36m", "".concat(argVerbose, " (default conf: ").concat(eLayerType.Default, ", ").concat(eLayerType.Error, ")"), "\x1b[0m");
        var all = '';
        for (var val in eLayerType) {
            if (val == eLayerType.Default)
                continue;
            console.log("\x1b[36m", "".concat(argVerbose, " ").concat(val[0], " (").concat(val, ")"), "\x1b[0m");
            all += val[0];
        }
        console.log("\x1b[36m", "".concat(argVerbose, " ").concat(all, " (for all)"), "\x1b[0m");
        console.log("\x1b[36m", "Add ".concat(argVerboseLevel, " number (for specifying log level, 0 default if not specified)"), "\x1b[0m");
    }
    else {
        console.log("\x1b[32m", "Logs activated", "\x1b[0m");
        var idx = process.argv.indexOf(argVerbose);
        if (idx == -1)
            idx = process.argv.indexOf("-verb");
        var opt_1 = (_b = (_a = process.argv) === null || _a === void 0 ? void 0 : _a[idx + 1]) === null || _b === void 0 ? void 0 : _b.toLowerCase();
        if (opt_1 && !(opt_1 === null || opt_1 === void 0 ? void 0 : opt_1.startsWith("-"))) {
            var layers = Object.keys(eLayerType);
            layers === null || layers === void 0 ? void 0 : layers.forEach(function (l) {
                if (l == eLayerType.Default)
                    return;
                if (opt_1.includes(l[0].toLowerCase()))
                    ActivateLog(l);
            });
        }
        ActivateLog(eLayerType.Rights);
        ActivateLog(eLayerType.MediaOcean);
        ActivateLog(eLayerType.SocketError);
        ActivateLog(eLayerType.MasterData);
        ActivateArgs();
    }
    ActivateLog(eLayerType.Business);
    ActivateLog(eLayerType.TSMError);
    ActivateLog(eLayerType.OfferError);
    ActivateLog(eLayerType.Hook);
    ActivateLog(eLayerType.Worker);
    ActivateLog(eLayerType.Boot);
    ActivateLog(eLayerType.LockingEventLoop);
    ActivateLog(eLayerType.SocketClient);
    ActivateLog(eLayerType.Medialand);
    ActivateLog(eLayerType.Performance);
    ActivateLog(eLayerType.SSO);
    ActivateLog(eLayerType.Mail);
    ActivateLog(eLayerType.Security);
    ActivateLog(eLayerType.File);
    ActivateLog(eLayerType.Task);
    ActivateLog(eLayerType.Sellsy);
    ActivateLog(eLayerType.MAP);
    ActivateLog(eLayerType.SocketMessage);
    ActivateLog(eLayerType.User);
    ActivateLog(eLayerType.ConnexionLost);
    ActivateLog(eLayerType.Ava);
};
export function SetLevelLogs(level) {
    detailLevel = level;
    console.log("\x1b[32m", "Log level details: ".concat(detailLevel), "\x1b[0m");
}
export function log(report, layer, details, override) {
    var _a;
    if (layer === void 0) { layer = eLayerType.Default; }
    if (details === void 0) { details = 0; }
    if (override === void 0) { override = undefined; }
    try {
        BeforeLog === null || BeforeLog === void 0 ? void 0 : BeforeLog.forEach(function (b) {
            try {
                b === null || b === void 0 ? void 0 : b();
            }
            catch (error) {
                console.error(error);
            }
        });
        if (details > detailLevel)
            return;
        if (!logActivated(layer))
            return;
        var logB_1 = {
            Date: (_a = report.Date) !== null && _a !== void 0 ? _a : new Date(),
            Layer: layer,
            Report: report
        };
        InfoProviders.forEach(function (i) {
            var data = i();
            if (data)
                for (var k in data) {
                    logB_1[k] = data[k];
                }
        });
        logB_1 = __assign(__assign({}, logB_1), override);
        logEvent.emit(layer, logB_1);
    }
    catch (e) {
        console.log("cannot log");
        console.error(e);
    }
}
export function logError(err, data) {
    var messageError = "";
    try {
        messageError = err === null || err === void 0 ? void 0 : err.toString();
    }
    catch (error) {
    }
    var SerializedError = {};
    try {
        SerializedError = err && JSON.parse(JSON.stringify(err));
    }
    catch (error) {
    }
    var report = __assign(__assign({}, data), { Error: { /*error: err,*/ message: err === null || err === void 0 ? void 0 : err.message, stack: err === null || err === void 0 ? void 0 : err.stack }, Msg: messageError, SerializedError: SerializedError });
    log(report, eLayerType.Error);
}
export function ErrorLog(args) {
    if (args === void 0) { args = undefined; }
    return ErrorCatching(function (reason, propertyName, args, context) {
        var _a, _b, _c, _d, _e, _f, _g;
        var contextCloned = null;
        try {
            contextCloned = clone(context);
        }
        catch (error) {
            contextCloned = 'cannot clone context';
        }
        var report = duplicate(__assign({ Function: propertyName, Message: null, Args: args, ContextType: (_a = context === null || context === void 0 ? void 0 : context.constructor) === null || _a === void 0 ? void 0 : _a.name, Context: contextCloned, Error: reason, ErrorMessage: reason === null || reason === void 0 ? void 0 : reason.message, ErrorStack: reason === null || reason === void 0 ? void 0 : reason.stack, Category: "Attribute_catch", Action: (_c = (_b = context === null || context === void 0 ? void 0 : context.constructor) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : "" }, args));
        if ((_f = (_e = (_d = report.Context) === null || _d === void 0 ? void 0 : _d.Session) === null || _e === void 0 ? void 0 : _e.userArgs) === null || _f === void 0 ? void 0 : _f.password)
            delete report.Context.Session.userArgs.password;
        if ((_g = report.Context) === null || _g === void 0 ? void 0 : _g.Session)
            delete report.Context.Session;
        log(report, eLayerType.Error);
        throw reason;
    });
}
/**
 * Check current log details, and may return empty object if details is > to current detail level
 * @param report
 * @param details
 */
export function logDetail(report, details) {
    if (details === void 0) { details = 0; }
    if (details > detailLevel)
        return {};
    return report;
}
/**
 * Activate logs for a given layer
 * @param key Layer log
 */
export function ActivateLog(key) {
    if (key) {
        console.log("\x1b[32m", "Activation logs: ".concat(key), "\x1b[0m");
        logActivation[key] = true;
    }
}
/**
 * Desactivate logs for a given layer
 * @param key Layer log
 */
export function DesactivateLog(key) {
    logActivation[key] = true;
}
export function GetActivateLog() {
    var logs = Object.keys(logActivation);
    return logs.filter(function (l) { return logActivation[l]; });
}
export function SetOutput(layer, output, clearAll) {
    if (clearAll === void 0) { clearAll = false; }
    if (clearAll)
        logEvent.removeAllListeners(layer);
    logEvent.on(layer, output);
}
export var logMethod = function (p) {
    var layer = ((p === null || p === void 0 ? void 0 : p.layer) !== undefined) ? p === null || p === void 0 ? void 0 : p.layer : eLayerType.Default;
    var hrstart = undefined;
    var Id = Date.now();
    var date = undefined;
    return EncapsulatedMethod(function (propertyName, args, caller) {
        var _a;
        if (!logActivated(layer))
            return;
        (_a = p === null || p === void 0 ? void 0 : p.before) === null || _a === void 0 ? void 0 : _a.forEach(function (ptr) { return ptr(); });
        date = Date.now();
        hrstart = process.hrtime();
    }, function (propertyName, args, res, caller) {
        var _a, _b;
        if (!logActivated(layer))
            return;
        try {
            var hrend = process.hrtime(hrstart);
            var report_1 = __assign({ Id: Id, Date: date, Function: propertyName, Description: p === null || p === void 0 ? void 0 : p.message, Time: hrend[1] / 1000000, Caller: caller }, logDetail({
                Params: args,
                ResultsCount: res === null || res === void 0 ? void 0 : res.length
            }, 0));
            (_a = p === null || p === void 0 ? void 0 : p.infoProviders) === null || _a === void 0 ? void 0 : _a.forEach(function (prov) {
                var i = prov();
                for (var k in i) {
                    report_1[k] = i[k];
                }
            });
            // désactivation temporaire
            // log(report, layer);
        }
        catch (error) {
            console.log("\x1b[31m", "Cannot log.", "\x1b[0m");
        }
        (_b = p === null || p === void 0 ? void 0 : p.after) === null || _b === void 0 ? void 0 : _b.forEach(function (ptr) { return ptr(); });
    });
};

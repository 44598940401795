var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { eStatusType } from "../dto/client/ref_Messages.bin";
import { groupBy, memoizeAsync } from "../tools.bin";
import { logError } from "tools-lib";
import { kpis } from "../types";
/*
lnk_HasCurrencyExtended =>
Company_Class: "ref_Advertisers"
Company_Name: "CAUDALIE ESPAGNE"
Company_rid: "#49:13"
Currency_Code: ["€"]
Currency_Name: ["EUR"]
Currency_rid: ["#359:0"]
End: undefined
Parent_Class: ["ref_AdvertiserGroups"]
Parent_Name: ["CAUDALIE"]
Parent_rid: ["#202:0"]
Start: undefined
*/
/*
@class: "lnk_ChangeRate"
@rid: "#362:28"
@version: 1
Company: "#52:12"
End: Thu Dec 31 2020 00:00:00 GMT+0100 (heure normale d’Europe centrale) {}
Rate: 0.85
Start: Wed Jan 01 2020 00:00:00 GMT+0100 (heure normale d’Europe centrale) {}
in: "#359:0"
out: "#359:4"
*/
var ReturnCurrencyProvider = /** @class */ (function () {
    function ReturnCurrencyProvider(expires) {
        this.allRestit = undefined;
        this.allRates = undefined;
        this.expires = expires;
    }
    ReturnCurrencyProvider.prototype.delayExpiration = function () {
        var _this = this;
        if (this.expires) {
            clearTimeout(this.expiresTimeout);
            this.expiresTimeout = setTimeout(function () {
                _this.allRestit = undefined;
                _this.allRates = undefined;
                _this.memoFunc = null;
            }, this.expires);
        }
    };
    ReturnCurrencyProvider.prototype.HasExpired = function () {
        return (!this.allRestit || !this.allRates);
    };
    ReturnCurrencyProvider.prototype.GetCurrencySync = function (groupAdvertiser, advertiser, buyCurrency, Start, End) {
        var _this = this;
        var _a, _b, _c, _d, _e;
        if (!this.allRestit || !this.allRates) {
            console.error("CurrenciesManager expired !!");
            return null;
        }
        if (Start)
            Start = new Date(Start);
        if (End)
            End = new Date(End);
        /** Looking for currency of advert or group advert */
        var getRestit = function (_rid) { return _rid && _this.allRestit.find(function (rest) { var _a; return ((_a = rest.Company_rid) === null || _a === void 0 ? void 0 : _a.toString()) === _rid; }); };
        var currency = (_d = (_c = (_b = ((_a = getRestit(advertiser)) !== null && _a !== void 0 ? _a : getRestit(groupAdvertiser))) === null || _b === void 0 ? void 0 : _b.Currency_rid) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.toString();
        if (currency) {
            var getRate = function (_rid) {
                var _a;
                return _rid && ((_a = _this.allRates) === null || _a === void 0 ? void 0 : _a.filter(function (r) {
                    var _a;
                    return ((_a = r.Company) === null || _a === void 0 ? void 0 : _a.toString()) === _rid
                        && r.out === currency
                        && r.in === buyCurrency
                        && r.Active == true;
                }));
            };
            /** Check if we can find any configured change rate */
            var rateAdv = getRate(advertiser);
            var rateGrp = getRate(groupAdvertiser);
            var addDays = function (date, days) {
                var before = date.getTimezoneOffset();
                date.setDate(date.getDate() + days);
                var after = date.getTimezoneOffset();
                date.setMinutes(date.getMinutes() - (after - before));
            };
            var isBetween = function (date) { return function (r) {
                if (date < r.Start)
                    return false;
                if (r.End && isFinite(r.End) && date > r.End)
                    return false;
                return true;
            }; };
            var rates = [];
            var start = new Date(Start);
            var end = new Date(End);
            while (start <= end) {
                var whileRate = (_e = rateAdv === null || rateAdv === void 0 ? void 0 : rateAdv.find(isBetween(start))) !== null && _e !== void 0 ? _e : rateGrp === null || rateGrp === void 0 ? void 0 : rateGrp.find(isBetween(start));
                rates.push((whileRate === null || whileRate === void 0 ? void 0 : whileRate.Rate) || 1);
                addDays(start, 1);
            }
            var rate = rates.length ? (rates.reduce(function (a, b) { return a + b; }) / rates.length) : 1;
            return { rate: rate, currency: currency };
        }
    };
    ReturnCurrencyProvider.prototype.GetCurrency = function (groupAdvertiser, advertiser, buyCurrency, Start, End) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (Start)
                            Start = new Date(Start);
                        if (End)
                            End = new Date(End);
                        if (!ReturnCurrencyProvider.HasCurrencyProvider || !ReturnCurrencyProvider.ChangeRateProvider)
                            throw new Error("CurrenciesManager not configured");
                        return [4 /*yield*/, this.init()];
                    case 1:
                        _a.sent();
                        this.delayExpiration();
                        return [2 /*return*/, this.GetCurrencySync(groupAdvertiser, advertiser, buyCurrency, Start, End)];
                }
            });
        });
    };
    ReturnCurrencyProvider.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, allRestit, allRates;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(!this.allRestit || !this.allRates)) return [3 /*break*/, 2];
                        if (!this.memoFunc)
                            this.memoFunc = memoizeAsync((function () { return __awaiter(_this, void 0, void 0, function () {
                                var allRestit, allRates;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, ReturnCurrencyProvider.HasCurrencyProvider()];
                                        case 1:
                                            allRestit = _a.sent();
                                            return [4 /*yield*/, ReturnCurrencyProvider.ChangeRateProvider()];
                                        case 2:
                                            allRates = _a.sent();
                                            allRates.forEach(function (rate) {
                                                rate.Start = new Date(rate.Start);
                                                if (rate.End)
                                                    rate.End = new Date(rate.End);
                                            });
                                            return [2 /*return*/, { allRestit: allRestit, allRates: allRates }];
                                    }
                                });
                            }); }).bind(this));
                        return [4 /*yield*/, this.memoFunc()];
                    case 1:
                        _a = _b.sent(), allRestit = _a.allRestit, allRates = _a.allRates;
                        this.allRestit = allRestit;
                        this.allRates = allRates;
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ReturnCurrencyProvider.prototype.ComputeCampaignBudget = function (campaign, _messages) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var kpisKeys_1, computeMessages, _i, _c, _d, media, messagesByMedia, error_1;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 6, , 7]);
                        kpisKeys_1 = Object.keys(new kpis());
                        if (!campaign.KPIs)
                            campaign.KPIs = new kpis();
                        // Remove cancelled messages
                        _messages = (_b = (_a = _messages === null || _messages === void 0 ? void 0 : _messages.filter) === null || _a === void 0 ? void 0 : _a.call(_messages, function (m) { return m.Status != eStatusType.Cancelled; })) !== null && _b !== void 0 ? _b : [];
                        if ((_messages === null || _messages === void 0 ? void 0 : _messages.length) > 0) {
                            campaign.KPIs.MaxStartDate = new Date((_messages.reduce(function (a, b) { return (new Date(a.Start) > new Date(b.Start) ? a : b); })).Start);
                            campaign.KPIs.MinEndDate = new Date((_messages.reduce(function (a, b) { return (new Date(a.End) < new Date(b.End) ? a : b); })).End);
                        }
                        computeMessages = function (messages, target) { return __awaiter(_this, void 0, void 0, function () {
                            var _loop_1, this_1, _i, messages_1, m, state_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        kpisKeys_1.forEach(function (kpiName) { return target[kpiName] = 0; });
                                        target.CountMessages = (messages === null || messages === void 0 ? void 0 : messages.length) || 0;
                                        if (!messages) return [3 /*break*/, 4];
                                        _loop_1 = function (m) {
                                            var currency_1;
                                            return __generator(this, function (_b) {
                                                switch (_b.label) {
                                                    case 0:
                                                        if (!(campaign.ReturnedCurrency && m.Currency != campaign.ReturnedCurrency)) return [3 /*break*/, 2];
                                                        return [4 /*yield*/, this_1.GetCurrency(campaign.AdvertiserGroup, campaign.Advertiser, m.Currency, m.Start, m.End)];
                                                    case 1:
                                                        currency_1 = _b.sent();
                                                        if (!currency_1) {
                                                            kpisKeys_1.forEach(function (kpiName) { return target[kpiName] = "invalid"; });
                                                            return [2 /*return*/, { value: void 0 }];
                                                        }
                                                        kpisKeys_1.forEach(function (kpiName) { return target[kpiName] += currency_1.rate * (m === null || m === void 0 ? void 0 : m.KPIs[kpiName]); });
                                                        return [3 /*break*/, 3];
                                                    case 2:
                                                        kpisKeys_1.forEach(function (kpiName) { var _a; return target[kpiName] += (_a = m === null || m === void 0 ? void 0 : m.KPIs[kpiName]) !== null && _a !== void 0 ? _a : 0; });
                                                        _b.label = 3;
                                                    case 3: return [2 /*return*/];
                                                }
                                            });
                                        };
                                        this_1 = this;
                                        _i = 0, messages_1 = messages;
                                        _a.label = 1;
                                    case 1:
                                        if (!(_i < messages_1.length)) return [3 /*break*/, 4];
                                        m = messages_1[_i];
                                        return [5 /*yield**/, _loop_1(m)];
                                    case 2:
                                        state_1 = _a.sent();
                                        if (typeof state_1 === "object")
                                            return [2 /*return*/, state_1.value];
                                        _a.label = 3;
                                    case 3:
                                        _i++;
                                        return [3 /*break*/, 1];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); };
                        return [4 /*yield*/, computeMessages(_messages, campaign.KPIs)];
                    case 1:
                        _e.sent();
                        if (!campaign.KPIsMedia)
                            campaign.KPIsMedia = {};
                        if (!campaign.Performances)
                            campaign.Performances = {};
                        if (campaign.Media) {
                            if (!campaign.Performances[campaign.Media])
                                campaign.Performances[campaign.Media] = {};
                        }
                        _i = 0, _c = Object.entries(groupBy(_messages, function (m) { return m.Media; }));
                        _e.label = 2;
                    case 2:
                        if (!(_i < _c.length)) return [3 /*break*/, 5];
                        _d = _c[_i], media = _d[0], messagesByMedia = _d[1];
                        if (!campaign.Performances[media])
                            campaign.Performances[media] = {};
                        campaign.KPIsMedia[media] = new kpis();
                        return [4 /*yield*/, computeMessages(messagesByMedia, campaign.KPIsMedia[media])];
                    case 3:
                        _e.sent();
                        _e.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5:
                        ;
                        return [3 /*break*/, 7];
                    case 6:
                        error_1 = _e.sent();
                        logError(error_1, {
                            Category: "ComputeCampaignBudget",
                            Description: "Error while computing campaign budget",
                        });
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    return ReturnCurrencyProvider;
}());
export { ReturnCurrencyProvider };

export var NielsenMediaDeliveryFrequence;
(function (NielsenMediaDeliveryFrequence) {
    NielsenMediaDeliveryFrequence["DAILY"] = "DAILY";
    NielsenMediaDeliveryFrequence["WEEKLY"] = "WEEKLY";
    NielsenMediaDeliveryFrequence["MONTHLY"] = "MONTHLY";
})(NielsenMediaDeliveryFrequence || (NielsenMediaDeliveryFrequence = {}));
var ref_NielsenClientSubscription = /** @class */ (function () {
    function ref_NielsenClientSubscription() {
        this.client = "";
        this.dataStartDate = 0;
        this.folderToPutFiles = "";
        this.majorCategoriesToInclude = [];
        this.midCategoriesToInclude = [];
        this.minorCategoriesToInclude = [];
        this.advertisersToInclude = [];
        this.brandsToInclude = [];
        this.brandProductsToInclude = [];
        this.productsToInclude = [];
        this.mediaTypesToInclude = [];
        this.mediaChannelsToInclude = [];
        this.spotaspot = true;
        this.deliveryFrequencyByMedia = [
            {
                "media": "Cinema",
                "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
                "deliveryDay": 1
            },
            {
                "media": "Digital",
                "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
                "deliveryDay": 1
            },
            {
                "media": "Outdoor",
                "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
                "deliveryDay": 1
            },
            {
                "media": "Press",
                "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
                "deliveryDay": 1
            },
            {
                "media": "Radio",
                "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
                "deliveryDay": 1
            },
            {
                "media": "Social",
                "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
                "deliveryDay": 1
            },
            {
                "media": "Video",
                "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
                "deliveryDay": 1
            }
        ];
    }
    return ref_NielsenClientSubscription;
}());
export { ref_NielsenClientSubscription };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Persons } from 'hub-lib/models/orientdb/ref_Persons.bin';
import { duplicate } from 'hub-lib/tools.bin';
import { store } from './store';
import { lnk_HasCompany } from "hub-lib/models/orientdb/lnk_HasCompany.bin";
import { ConsoleDebug } from '../utils/localstorage.bin';
var initialState = { store: {} };
export var storageSlice = createSlice({ name: 'storage', initialState: initialState, reducers: { setStorage: function (state, action) { state.store = duplicate(action.payload); ConsoleDebug("setStorage", state.store); } } });
export function storeElements(rows) { var _rows$flatMap; var currentStore = store.getState().verticesStorage; var allUsers = Array.from(new Set((_rows$flatMap = rows === null || rows === void 0 ? void 0 : rows.flatMap(function (r) { return [r.Updated_by, r.Created_by]; })) !== null && _rows$flatMap !== void 0 ? _rows$flatMap : [])).filter(function (u) { return u; }).filter(function (u) { return !currentStore[u]; }); if (allUsers.length) {
    Client.searchVertex(lnk_HasCompany.name, { Account: allUsers, properties: ["Account", "out.FirstName as FirstName", "out.LastName as LastName"] }).then(function (res) { return res.data.results.map(function (r) { return (__assign(__assign({}, r), { "@rid": r.Account, "@class": ref_Persons.name })); }); }).then(function (persons) { var storage = duplicate(store.getState().verticesStorage.store); persons.forEach(function (p) { return storage[p["@rid"]] = p; }); store.dispatch(setStorage(storage)); });
} }
export var setStorage = storageSlice.actions.setStorage;
export var storageReducer = storageSlice.reducer;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var ImportsSourceType;
(function (ImportsSourceType) {
    ImportsSourceType["MESSAGEMAP"] = "MAP";
    ImportsSourceType["AGREEMENTMAP"] = "AGREEMENTMAP";
    ImportsSourceType["ADWONE"] = "ADWONE";
    ImportsSourceType["DSP"] = "DSP";
    ImportsSourceType["POPCORN"] = "POPCORN";
    ImportsSourceType["CODIPRESSE"] = "CODIPRESSE";
    ImportsSourceType["MEDIAVISION"] = "MEDIAVISION";
    ImportsSourceType["TSM_CONTACTS"] = "TSM_CONTACTS";
    ImportsSourceType["MEDIAAPI"] = "MEDIAPILOT";
})(ImportsSourceType || (ImportsSourceType = {}));
export var ImportsStatus;
(function (ImportsStatus) {
    ImportsStatus["PREPARING"] = "PREPARING";
    ImportsStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ImportsStatus["PENDING"] = "PENDING";
    ImportsStatus["IMPORTING"] = "IMPORTING";
    ImportsStatus["DONE"] = "DONE";
    ImportsStatus["CANCELED"] = "CANCELED";
    ImportsStatus["EXPIRED"] = "EXPIRED";
    ImportsStatus["FAILED"] = "FAILED"; // todo if big error
})(ImportsStatus || (ImportsStatus = {}));
export var ImportElementStatus;
(function (ImportElementStatus) {
    ImportElementStatus["NEW"] = "NEW";
    ImportElementStatus["CHANGED"] = "CHANGED";
    ImportElementStatus["EXIST"] = "EXIST";
    ImportElementStatus["UPDATED"] = "UPDATED";
    ImportElementStatus["OBSOLETE"] = "OBSOLETE";
    ImportElementStatus["REMOVED"] = "REMOVED";
    ImportElementStatus["IMPORTED"] = "IMPORTED";
})(ImportElementStatus || (ImportElementStatus = {}));
var SourceTarget = /** @class */ (function () {
    function SourceTarget() {
    }
    return SourceTarget;
}());
export { SourceTarget };
var FilterMessageMap = /** @class */ (function () {
    function FilterMessageMap() {
    }
    return FilterMessageMap;
}());
export { FilterMessageMap };
var FilterAgreementMap = /** @class */ (function () {
    function FilterAgreementMap() {
    }
    return FilterAgreementMap;
}());
export { FilterAgreementMap };
var FiltersAdwone = /** @class */ (function () {
    function FiltersAdwone() {
    }
    return FiltersAdwone;
}());
export { FiltersAdwone };
export var eFilterRefType;
(function (eFilterRefType) {
    eFilterRefType["AdvertiserGroup"] = "ref_AdvertiserGroups";
    eFilterRefType["Advertiser"] = "ref_Advertisers";
    eFilterRefType["Medias"] = "ref_Media";
})(eFilterRefType || (eFilterRefType = {}));
var ref_Imports = /** @class */ (function () {
    function ref_Imports() {
    }
    return ref_Imports;
}());
export { ref_Imports };
var ref_ImportsExtended = /** @class */ (function (_super) {
    __extends(ref_ImportsExtended, _super);
    function ref_ImportsExtended() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ref_ImportsExtended;
}(ref_Imports));
export { ref_ImportsExtended };

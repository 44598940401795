import { GetTemplate } from "hub-lib/dto/client/ref_SchedulerConfigurations.bin";
export function TemplateToCSS(template) {
    var _template$textStyle, _template$textStyle2, _template$textStyle3, _template$textStyle4, _template$textStyle4$;
    if (!template)
        return {}; //let background = template.color?.code;
    var fontWeight = (_template$textStyle = template.textStyle) !== null && _template$textStyle !== void 0 && _template$textStyle.bold ? "bold" : "normal";
    var fontStyle = (_template$textStyle2 = template.textStyle) !== null && _template$textStyle2 !== void 0 && _template$textStyle2.italic ? "italic" : null;
    var textDecoration = (_template$textStyle3 = template.textStyle) !== null && _template$textStyle3 !== void 0 && _template$textStyle3.underline ? "underline" : null;
    var color = (_template$textStyle4 = template.textStyle) === null || _template$textStyle4 === void 0 ? void 0 : (_template$textStyle4$ = _template$textStyle4.color) === null || _template$textStyle4$ === void 0 ? void 0 : _template$textStyle4$.code;
    return { background: color, fontWeight: fontWeight, fontStyle: fontStyle, textDecoration: textDecoration, color: color };
}
export function MessagesToCSS(messages, template) { if (!template)
    return {}; var templ = GetTemplate(messages, template === null || template === void 0 ? void 0 : template.Style); if (templ)
    return TemplateToCSS(templ); return {}; }

var kpis = /** @class */ (function () {
    function kpis() {
        this.Ratio = 0;
        this.RatioDiviser = 0;
        this.Gross = 0;
        this.GrossVal = 0;
        this.GrossBa = 0;
        this.BroadcastGross = 0;
        this.NetCO = 0;
        this.NetFO = 0;
        this.NetFOS = 0;
        this.NetNego = 0;
        this.TotalCO = 0;
        this.TotalFO = 0;
        this.TotalFOS = 0;
        this.FreeCount = 0;
        this.PaidCount = 0;
    }
    return kpis;
}());
export { kpis };

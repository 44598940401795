var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { kpis } from "../../types";
export var eStatusType;
(function (eStatusType) {
    eStatusType["None"] = "None";
    eStatusType["Simulated"] = "Simulated";
    eStatusType["Opted"] = "Opted";
    eStatusType["Confirmed"] = "Confirmed";
    eStatusType["Cancelled"] = "Cancelled";
})(eStatusType || (eStatusType = {}));
export var eStateType;
(function (eStateType) {
    eStateType["None"] = "None";
    eStateType["Bound"] = "Bound";
    eStateType["Confirmed"] = "Confirmed";
    eStateType["Billed"] = "Billed";
    eStateType["Cancelled"] = "Cancelled";
})(eStateType || (eStateType = {}));
var NegotiatedKPIs = /** @class */ (function () {
    function NegotiatedKPIs() {
    }
    return NegotiatedKPIs;
}());
export { NegotiatedKPIs };
var FactInfo = /** @class */ (function () {
    function FactInfo() {
    }
    return FactInfo;
}());
export { FactInfo };
var Deversement = /** @class */ (function () {
    function Deversement() {
        this.InfoFO = new FactInfo();
        this.InfoCO = new FactInfo();
    }
    return Deversement;
}());
export { Deversement };
var ref_Messages = /** @class */ (function () {
    function ref_Messages() {
        this.Status = eStatusType.None;
        this.State = eStateType.None;
        this.KPIs = new kpis();
        this.ModelProperties = {};
        this.DiscountMode = "cascade";
        this.Deversement = new Deversement();
        this.Attachments = {}; // not in DB !!!
    }
    return ref_Messages;
}());
export { ref_Messages };
var ref_MessagesNegotiated = /** @class */ (function (_super) {
    __extends(ref_MessagesNegotiated, _super);
    function ref_MessagesNegotiated() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ref_MessagesNegotiated;
}(ref_Messages));
export { ref_MessagesNegotiated };

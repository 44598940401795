var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "hub-lib/client/client.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Brands } from "hub-lib/models/orientdb/ref_Brands.bin";
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { clearEmptyValues, compareObjects, distinct, duplicate, extractSub, toArray } from "hub-lib/tools.bin";
var AdvertiserStoreSingleRid = /** @class */ (function () {
    function AdvertiserStoreSingleRid() {
        this.AdvertiserGroup = void 0;
        this.Advertiser = void 0;
        this.Brand = void 0;
        this.Product = void 0;
        this.Campaign = void 0;
    }
    return AdvertiserStoreSingleRid;
}());
export { AdvertiserStoreSingleRid };
;
var AdvertiserStoreMultiRid = /** @class */ (function () {
    function AdvertiserStoreMultiRid() {
        this.AdvertiserGroup = void 0;
        this.Advertiser = void 0;
        this.Brand = void 0;
        this.Product = void 0;
        this.Campaign = void 0;
    }
    return AdvertiserStoreMultiRid;
}());
export { AdvertiserStoreMultiRid };
;
var StoreStateHierarchy = /** @class */ (function () {
    function StoreStateHierarchy() {
        this.AdvertiserGroupOptions = void 0;
        this.AdvertiserOptions = void 0;
        this.BrandOptions = void 0;
        this.ProductOptions = void 0;
        this.CampaignOptions = void 0;
    }
    return StoreStateHierarchy;
}());
export { StoreStateHierarchy };
var StoreState = /** @class */ (function (_super) {
    __extends(StoreState, _super);
    function StoreState() {
        var _this = _super.apply(this, arguments) || this;
        _this.isLoaded = void 0;
        _this.store = void 0;
        _this.AdvertiserGroupLoading = void 0;
        _this.AdvertiserLoading = void 0;
        _this.BrandLoading = void 0;
        _this.ProductLoading = void 0;
        _this.BrandDisabled = void 0;
        _this.ProductDisabled = void 0;
        return _this;
    }
    return StoreState;
}(StoreStateHierarchy));
export { StoreState };
var initialState = { isLoaded: false, store: {}, AdvertiserGroupOptions: undefined, AdvertiserOptions: undefined, BrandOptions: undefined, ProductOptions: undefined, CampaignOptions: undefined, AdvertiserGroupLoading: false, AdvertiserLoading: false, BrandLoading: false, ProductLoading: false, BrandDisabled: false, ProductDisabled: false };
var addDefaultValues = function (vertex, store, options) { return __awaiter(void 0, void 0, void 0, function () { var defaultItems, ridParams, missing; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            defaultItems = (store === null || store === void 0 ? void 0 : store.filter(function (o) { return !options.some(function (o) { return o["@rid"] == o["@rid"]; }); })) || [];
            if (!defaultItems.length) return [3 /*break*/, 2];
            ridParams = defaultItems.length == 1 ? defaultItems[0] : defaultItems;
            return [4 /*yield*/, Client.searchVertexTyped(vertex, { "@rid": ridParams, properties: ["Name", "Active"] })];
        case 1:
            missing = _a.sent();
            if (missing !== null && missing !== void 0 && missing.length)
                return [2 /*return*/, __spreadArray(__spreadArray([], options, true), missing, true)];
            _a.label = 2;
        case 2: return [2 /*return*/, options];
    }
}); }); };
export var setStore = createAsyncThunk('storefilters/setStore', function (_ref, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var store, _advertiserStorage, newStorage_1, currentStorage_1, isLoaded, addedAdvertisers, addedBrands, addedProducts, removedAdvertisers_1, removedBrands_1, removedProducts_1, removedGPAdvertisers, _store$Product, _addedProducts, _store$Brand2, _addedBrands, _store$Advertiser2, _addedAdvertisers, _currentStorage$store4, _currentStorage$store5, _currentStorage$store6, _currentStorage$store7, _store$Brand, brands, _store$Advertiser, adertisers, _store$AdvertiserGrou, adertiserGroups, params, _a, _removedGPAdvertisers, _store$Advertiser4, _removedAdvertisers2, _store$Brand4, _removedBrands2, _store$Product3, _removedAdvertisers, _b, _store$Advertiser5, _removedBrands, results, _removedProducts, _store$Brand5, _c, productsOfRemainingBrands_1, advertiserGroupsChanged, advertisersChanged, brandsChanged, _store$AdvertiserGrou3, _newStorage$store$Adv, _d, _e, advertisersId, _store$Advertiser6, _newStorage$store$Bra, _f, _g, brandsId, _store$Advertiser7, _store$Product4, _h, _j, error_1;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                store = _ref.store;
                _k.label = 1;
            case 1:
                _k.trys.push([1, 37, , 38]);
                store = clearEmptyValues(duplicate(store));
                newStorage_1 = { store: store, isLoaded: true };
                currentStorage_1 = (_advertiserStorage = thunkAPI.getState().advertiserStorage) !== null && _advertiserStorage !== void 0 ? _advertiserStorage : {};
                isLoaded = currentStorage_1.isLoaded;
                addedAdvertisers = null;
                addedBrands = null;
                addedProducts = null;
                removedAdvertisers_1 = null;
                removedBrands_1 = null;
                removedProducts_1 = null;
                removedGPAdvertisers = null;
                if (!isLoaded) return [3 /*break*/, 8];
                addedProducts = (_store$Product = store.Product) === null || _store$Product === void 0 ? void 0 : _store$Product.filter(function (p) { var _currentStorage$store; return !((_currentStorage$store = currentStorage_1.store.Product) !== null && _currentStorage$store !== void 0 && _currentStorage$store.includes(p)); });
                if (!((_addedProducts = addedProducts) !== null && _addedProducts !== void 0 && _addedProducts.length && RightManager.hasRight(ref_Brands.name, eRights.read))) return [3 /*break*/, 3];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Brands, { children: addedProducts, properties: ["@rid"] })];
            case 2:
                brands = _k.sent();
                store.Brand = distinct(__spreadArray(__spreadArray([], ((_store$Brand = store.Brand) !== null && _store$Brand !== void 0 ? _store$Brand : []), true), brands.map(function (b) { return b["@rid"]; }), true), function (k) { return k; });
                _k.label = 3;
            case 3:
                addedBrands = (_store$Brand2 = store.Brand) === null || _store$Brand2 === void 0 ? void 0 : _store$Brand2.filter(function (p) { var _currentStorage$store2; return !((_currentStorage$store2 = currentStorage_1.store.Brand) !== null && _currentStorage$store2 !== void 0 && _currentStorage$store2.includes(p)); });
                if (!((_addedBrands = addedBrands) !== null && _addedBrands !== void 0 && _addedBrands.length && RightManager.hasRight(ref_Advertisers.name, eRights.read))) return [3 /*break*/, 5];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Advertisers, { children: addedBrands, properties: ["@rid"] })];
            case 4:
                adertisers = _k.sent();
                store.Advertiser = distinct(__spreadArray(__spreadArray([], ((_store$Advertiser = store.Advertiser) !== null && _store$Advertiser !== void 0 ? _store$Advertiser : []), true), adertisers.map(function (a) { return a["@rid"]; }), true), function (k) { return k; });
                _k.label = 5;
            case 5:
                addedAdvertisers = (_store$Advertiser2 = store.Advertiser) === null || _store$Advertiser2 === void 0 ? void 0 : _store$Advertiser2.filter(function (p) { var _currentStorage$store3; return !((_currentStorage$store3 = currentStorage_1.store.Advertiser) !== null && _currentStorage$store3 !== void 0 && _currentStorage$store3.includes(p)); });
                if (!((_addedAdvertisers = addedAdvertisers) !== null && _addedAdvertisers !== void 0 && _addedAdvertisers.length && RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read))) return [3 /*break*/, 7];
                return [4 /*yield*/, Client.searchVertexTyped(ref_AdvertiserGroups, { children: addedAdvertisers, properties: ["@rid"] })];
            case 6:
                adertiserGroups = _k.sent();
                store.AdvertiserGroup = distinct(__spreadArray(__spreadArray([], ((_store$AdvertiserGrou = store.AdvertiserGroup) !== null && _store$AdvertiserGrou !== void 0 ? _store$AdvertiserGrou : []), true), adertiserGroups.map(function (a) { return a["@rid"]; }), true), function (k) { return k; });
                _k.label = 7;
            case 7:
                removedProducts_1 = (_currentStorage$store4 = currentStorage_1.store.Product) === null || _currentStorage$store4 === void 0 ? void 0 : _currentStorage$store4.filter(function (p) { var _store$Product2; return !((_store$Product2 = store.Product) !== null && _store$Product2 !== void 0 && _store$Product2.includes(p)); });
                removedBrands_1 = (_currentStorage$store5 = currentStorage_1.store.Brand) === null || _currentStorage$store5 === void 0 ? void 0 : _currentStorage$store5.filter(function (p) { var _store$Brand3; return !((_store$Brand3 = store.Brand) !== null && _store$Brand3 !== void 0 && _store$Brand3.includes(p)); });
                removedAdvertisers_1 = (_currentStorage$store6 = currentStorage_1.store.Advertiser) === null || _currentStorage$store6 === void 0 ? void 0 : _currentStorage$store6.filter(function (p) { var _store$Advertiser3; return !((_store$Advertiser3 = store.Advertiser) !== null && _store$Advertiser3 !== void 0 && _store$Advertiser3.includes(p)); });
                removedGPAdvertisers = (_currentStorage$store7 = currentStorage_1.store.AdvertiserGroup) === null || _currentStorage$store7 === void 0 ? void 0 : _currentStorage$store7.filter(function (p) { var _store$AdvertiserGrou2; return !((_store$AdvertiserGrou2 = store.AdvertiserGroup) !== null && _store$AdvertiserGrou2 !== void 0 && _store$AdvertiserGrou2.includes(p)); });
                _k.label = 8;
            case 8:
                params = { properties: ["@rid", "Name"] };
                if (!(currentStorage_1 !== null && currentStorage_1 !== void 0 && currentStorage_1.AdvertiserGroupOptions)) return [3 /*break*/, 9];
                newStorage_1.AdvertiserGroupOptions = currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.AdvertiserGroupOptions;
                return [3 /*break*/, 11];
            case 9:
                if (!RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read)) return [3 /*break*/, 11];
                _a = newStorage_1;
                return [4 /*yield*/, Client.searchVertexTyped(ref_AdvertiserGroups)];
            case 10:
                _a.AdvertiserGroupOptions = _k.sent();
                _k.label = 11;
            case 11:
                if (!isLoaded) return [3 /*break*/, 19];
                if (!((_removedGPAdvertisers = removedGPAdvertisers) !== null && _removedGPAdvertisers !== void 0 && _removedGPAdvertisers.length && (_store$Advertiser4 = store.Advertiser) !== null && _store$Advertiser4 !== void 0 && _store$Advertiser4.length && RightManager.hasRight(ref_Advertisers.name, eRights.read))) return [3 /*break*/, 13];
                _b = [__spreadArray([], ((_removedAdvertisers = removedAdvertisers_1) !== null && _removedAdvertisers !== void 0 ? _removedAdvertisers : []), true)];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Advertisers, { ref_AdvertiserGroups: removedGPAdvertisers, properties: ['@rid'] })];
            case 12:
                removedAdvertisers_1 = __spreadArray.apply(void 0, _b.concat([(_k.sent()).map(function (e) { return e["@rid"]; }), true]));
                removedAdvertisers_1 = removedAdvertisers_1.filter(function (b) { return store.Advertiser.includes(b); });
                store.Advertiser = store.Advertiser.filter(function (b) { return !removedAdvertisers_1.includes(b); });
                _k.label = 13;
            case 13:
                if (!((_removedAdvertisers2 = removedAdvertisers_1) !== null && _removedAdvertisers2 !== void 0 && _removedAdvertisers2.length && (_store$Brand4 = store.Brand) !== null && _store$Brand4 !== void 0 && _store$Brand4.length && RightManager.hasRight(ref_Brands.name, eRights.read))) return [3 /*break*/, 15];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Brands, { ref_Advertisers: removedAdvertisers_1, properties: ["@rid", "out(\"lnk_hierarchy\") as parents"] })];
            case 14:
                results = _k.sent();
                if ((_store$Advertiser5 = store.Advertiser) !== null && _store$Advertiser5 !== void 0 && _store$Advertiser5.length)
                    results = results.filter(function (r) { return !store.Advertiser.some(function (a) { var _r$parents; return (_r$parents = r["parents"]) === null || _r$parents === void 0 ? void 0 : _r$parents.includes(a); }); });
                removedBrands_1 = __spreadArray(__spreadArray([], ((_removedBrands = removedBrands_1) !== null && _removedBrands !== void 0 ? _removedBrands : []), true), results.map(function (e) { return e["@rid"]; }), true);
                removedBrands_1 = removedBrands_1.filter(function (b) { return store.Brand.includes(b); });
                store.Brand = store.Brand.filter(function (b) { return !removedBrands_1.includes(b); });
                _k.label = 15;
            case 15:
                if (!((_removedBrands2 = removedBrands_1) !== null && _removedBrands2 !== void 0 && _removedBrands2.length && (_store$Product3 = store.Product) !== null && _store$Product3 !== void 0 && _store$Product3.length && RightManager.hasRight(ref_Products.name, eRights.read))) return [3 /*break*/, 19];
                _c = [__spreadArray([], ((_removedProducts = removedProducts_1) !== null && _removedProducts !== void 0 ? _removedProducts : []), true)];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Products, { ref_Brands: removedBrands_1, properties: ['@rid'] })];
            case 16:
                removedProducts_1 = __spreadArray.apply(void 0, _c.concat([(_k.sent()).map(function (e) { return e["@rid"]; }), true]));
                removedProducts_1 = removedProducts_1.filter(function (b) { return store.Product.includes(b); });
                if (!((_store$Brand5 = store.Brand) !== null && _store$Brand5 !== void 0 && _store$Brand5.length)) return [3 /*break*/, 18];
                return [4 /*yield*/, Client.searchVertexTyped(ref_Products, { ref_Brands: store.Brand, properties: ['@rid'] })];
            case 17:
                productsOfRemainingBrands_1 = _k.sent();
                removedProducts_1 = removedProducts_1.filter(function (b) { return !productsOfRemainingBrands_1.some(function (p) { return p["@rid"] == b; }); });
                _k.label = 18;
            case 18:
                store.Product = store.Product.filter(function (b) { return !removedProducts_1.includes(b); });
                _k.label = 19;
            case 19:
                advertiserGroupsChanged = function () { return !compareObjects(extractSub(currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.store, ["AdvertiserGroup"]), extractSub(newStorage_1.store, ["AdvertiserGroup"])); };
                advertisersChanged = function () { return !compareObjects(extractSub(currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.store, ["Advertiser"]), extractSub(newStorage_1.store, ["Advertiser"])); };
                brandsChanged = function () { return !compareObjects(extractSub(currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.store, ["Brand"]), extractSub(newStorage_1.store, ["Brand"])); };
                if (!(!isLoaded || advertiserGroupsChanged() || advertisersChanged() || brandsChanged())) return [3 /*break*/, 36];
                if (!RightManager.hasRight(ref_Advertisers.name, eRights.read)) return [3 /*break*/, 24];
                if ((_store$AdvertiserGrou3 = store.AdvertiserGroup) !== null && _store$AdvertiserGrou3 !== void 0 && _store$AdvertiserGrou3.length)
                    params.ref_AdvertiserGroups = store.AdvertiserGroup;
                if (!(!isLoaded || advertiserGroupsChanged())) return [3 /*break*/, 21];
                _d = newStorage_1;
                return [4 /*yield*/, Client.searchVertexTyped(ref_Advertisers, params)];
            case 20:
                _d.AdvertiserOptions = _k.sent();
                _k.label = 21;
            case 21:
                if (!!isLoaded) return [3 /*break*/, 23];
                _e = newStorage_1;
                return [4 /*yield*/, addDefaultValues(ref_Advertisers, store.Advertiser, newStorage_1.AdvertiserOptions)];
            case 22:
                _e.AdvertiserOptions = _k.sent();
                _k.label = 23;
            case 23:
                if ((_newStorage$store$Adv = newStorage_1.store.Advertiser) !== null && _newStorage$store$Adv !== void 0 && _newStorage$store$Adv.length) {
                    advertisersId = toArray(store.Advertiser);
                    newStorage_1.store.Advertiser = advertisersId.filter(function (rid) { var _newStorage$Advertise; return ((_newStorage$Advertise = newStorage_1.AdvertiserOptions) !== null && _newStorage$Advertise !== void 0 ? _newStorage$Advertise : currentStorage_1.AdvertiserOptions).some(function (o) { return o["@rid"] == rid; }); });
                    params.ref_Advertisers = store.Advertiser;
                    delete params.ref_AdvertiserGroups;
                }
                _k.label = 24;
            case 24:
                if (!RightManager.hasRight(ref_Brands.name, eRights.read)) return [3 /*break*/, 30];
                if (!((_store$Advertiser6 = store.Advertiser) !== null && _store$Advertiser6 !== void 0 && _store$Advertiser6.length)) return [3 /*break*/, 29];
                if (!(!isLoaded || advertiserGroupsChanged() || advertisersChanged())) return [3 /*break*/, 26];
                _f = newStorage_1;
                return [4 /*yield*/, Client.searchVertexTyped(ref_Brands, params)];
            case 25:
                _f.BrandOptions = _k.sent();
                _k.label = 26;
            case 26:
                if (!!isLoaded) return [3 /*break*/, 28];
                _g = newStorage_1;
                return [4 /*yield*/, addDefaultValues(ref_Brands, store.Brand, newStorage_1.BrandOptions)];
            case 27:
                _g.BrandOptions = _k.sent();
                _k.label = 28;
            case 28:
                if ((_newStorage$store$Bra = newStorage_1.store.Brand) !== null && _newStorage$store$Bra !== void 0 && _newStorage$store$Bra.length) {
                    brandsId = toArray(store.Brand);
                    store.Brand = brandsId.filter(function (rid) { var _newStorage$BrandOpti; return ((_newStorage$BrandOpti = newStorage_1.BrandOptions) !== null && _newStorage$BrandOpti !== void 0 ? _newStorage$BrandOpti : currentStorage_1.BrandOptions).some(function (o) { return o["@rid"] == rid; }); });
                    params.ref_Brands = store.Brand;
                    delete params.ref_Advertisers;
                }
                newStorage_1.BrandDisabled = false;
                return [3 /*break*/, 30];
            case 29:
                delete store.Brand;
                newStorage_1.BrandOptions = [];
                newStorage_1.BrandDisabled = true;
                _k.label = 30;
            case 30:
                if (!RightManager.hasRight(ref_Products.name, eRights.read)) return [3 /*break*/, 36];
                if (!((_store$Advertiser7 = store.Advertiser) !== null && _store$Advertiser7 !== void 0 && _store$Advertiser7.length)) return [3 /*break*/, 35];
                if (!(!isLoaded || advertiserGroupsChanged() || advertisersChanged() || brandsChanged())) return [3 /*break*/, 34];
                _h = newStorage_1;
                return [4 /*yield*/, Client.searchVertexTyped(ref_Products, params)];
            case 31:
                _h.ProductOptions = _k.sent();
                if (!!isLoaded) return [3 /*break*/, 33];
                _j = newStorage_1;
                return [4 /*yield*/, addDefaultValues(ref_Products, store.Product, newStorage_1.ProductOptions)];
            case 32:
                _j.ProductOptions = _k.sent();
                _k.label = 33;
            case 33:
                if ((_store$Product4 = store.Product) !== null && _store$Product4 !== void 0 && _store$Product4.length)
                    store.Product = store.Product.filter(function (rid) { return newStorage_1.ProductOptions.some(function (o) { return o["@rid"] == rid; }); });
                newStorage_1.ProductDisabled = false;
                _k.label = 34;
            case 34: return [3 /*break*/, 36];
            case 35:
                delete store.Product;
                newStorage_1.ProductOptions = [];
                newStorage_1.ProductDisabled = true;
                _k.label = 36;
            case 36: // if (applyFilters) {
            //     const advancedFilters = FilterStorage.getAdvancedFilters();
            //     if (advancedFilters?.AdvertiserGroup?.length > 1)
            //         newStorage.AdvertiserGroupOptions = newStorage.AdvertiserGroupOptions.filter((advGrpOptions) => advancedFilters.AdvertiserGroup.some((grpFilter) => advGrpOptions["@rid"] === grpFilter))
            //     if (advancedFilters?.Advertiser?.length > 1)
            //         newStorage.AdvertiserOptions = newStorage.AdvertiserOptions.filter((advOption) => advancedFilters.Advertiser.some((advFilter) => advOption["@rid"] === advFilter))
            //     if (advancedFilters?.Brand?.length > 1)
            //         newStorage.BrandOptions = newStorage.BrandOptions.filter((brOption) => advancedFilters.Brand.some((brFilter) => brOption["@rid"] == brFilter))
            //     if (advancedFilters?.Product?.length > 1)
            //         newStorage.ProductOptions = newStorage.ProductOptions.filter((brOption) => advancedFilters.Product.some((brFilter) => brOption["@rid"] == brFilter))
            // }
            return [2 /*return*/, newStorage_1];
            case 37:
                error_1 = _k.sent();
                console.log(error_1);
                return [3 /*break*/, 38];
            case 38: return [2 /*return*/];
        }
    });
}); });
export var StoreSlice = createSlice({ name: 'storefilters', initialState: initialState, extraReducers: function (builder) {
        builder.addCase(setStore.pending, function (state, action) { var _state$store, _action$meta$arg$stor; var previousStore = duplicate((_state$store = state === null || state === void 0 ? void 0 : state.store) !== null && _state$store !== void 0 ? _state$store : {}); var newStore = duplicate((_action$meta$arg$stor = action.meta.arg.store) !== null && _action$meta$arg$stor !== void 0 ? _action$meta$arg$stor : {}); if (!compareObjects(extractSub(previousStore, ["AdvertiserGroup"]), extractSub(newStore, ["AdvertiserGroup"]))) {
            state.AdvertiserLoading = true;
            state.BrandLoading = true;
            state.ProductLoading = true;
        }
        else if (!compareObjects(extractSub(previousStore, ["Advertiser"]), extractSub(newStore, ["Advertiser"]))) {
            state.BrandLoading = true;
            state.ProductLoading = true;
        }
        else if (!compareObjects(extractSub(previousStore, ["Brand"]), extractSub(newStore, ["Brand"]))) {
            state.ProductLoading = true;
        } }); // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(setStore.fulfilled, function (state, action) { Object.entries(action.payload).forEach(function (_ref2) { var k = _ref2[0], v = _ref2[1]; state[k] = v; }); state.AdvertiserGroupLoading = false; state.AdvertiserLoading = false; state.BrandLoading = false; state.ProductLoading = false; });
    }, reducers: { initStore: function (state, action) { state.store = action.payload; } } }); // Action creators are generated for each case reducer function
export var initStore = StoreSlice.actions.initStore;
export var AdvertiserStoreReducer = StoreSlice.reducer;

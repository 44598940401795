var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _Favorites;
import { Client } from "hub-lib/client/client.bin";
import { ref_Favorites } from "hub-lib/models/orientdb/ref_Favorites.bin";
import { distinct, propertyOf } from "hub-lib/tools.bin";
var Favorites = /** @class */ (function () {
    function Favorites() {
        var _this = this;
        this.uniqFavorites = null;
        this._getTable = function () { return __awaiter(_this, void 0, void 0, function () {
            var _await$Client$searchV;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Client.searchVertexTyped(ref_Favorites, (_a = {}, _a[propertyOf('Table')] = "all", _a))];
                    case 1: return [2 /*return*/, (_await$Client$searchV = _b.sent()) === null || _await$Client$searchV === void 0 ? void 0 : _await$Client$searchV[0]];
                }
            });
        }); };
    }
    Favorites.GetInstance = function () { if (!Favorites.instance) {
        Favorites.instance = new Favorites();
    } return Favorites.instance; };
    Favorites.prototype.Initialize = function () {
        return __awaiter(this, void 0, void 0, function () { var _a, allExistingFavorites, _b; return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = this;
                    return [4 /*yield*/, this._getTable()];
                case 1:
                    _a.uniqFavorites = _c.sent();
                    if (!!this.uniqFavorites) return [3 /*break*/, 5];
                    return [4 /*yield*/, Client.searchVertexTyped(ref_Favorites, {})];
                case 2:
                    allExistingFavorites = _c.sent();
                    this.uniqFavorites = new ref_Favorites();
                    this.uniqFavorites.Table = "all";
                    this.uniqFavorites.TableIds = distinct(allExistingFavorites.map(function (fav) { return fav.TableIds; }).flat());
                    return [4 /*yield*/, Client.createVertex(ref_Favorites.name, this.uniqFavorites, false)];
                case 3:
                    _c.sent();
                    _b = this;
                    return [4 /*yield*/, this._getTable()];
                case 4:
                    _b.uniqFavorites = _c.sent();
                    _c.label = 5;
                case 5: return [2 /*return*/, this.uniqFavorites];
            }
        }); });
    };
    Favorites.prototype.GetTable = function () {
        return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, this.Initialize()];
        }); });
    };
    Favorites.prototype.GetCurrentTable = function () { return this.uniqFavorites; };
    return Favorites;
}());
export { Favorites };
_Favorites = Favorites;
Favorites.instance = void 0;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { eIndicateurType, eTotalDirection } from "./index.bin";
import { Trad } from "trad-lib";
import { JoinElements } from "hub-lib/tools.bin";
var CellValue = /** @class */ (function () {
    function CellValue() {
    }
    return CellValue;
}());
export { CellValue };
var Ventilation = /** @class */ (function () {
    function Ventilation() {
        var _this = this;
        this.Formated = "";
        this.getFlat = function () {
            var _a;
            if (!((_a = _this.Children) === null || _a === void 0 ? void 0 : _a.length))
                return _this.Data;
            return _this.Children.map(function (c) { return c.getFlat(); }).reduce(function (a, b) { return a.concat(b); });
        };
        this.Formated = Trad("none");
    }
    return Ventilation;
}());
export { Ventilation };
export function GetRowValue(row, signature) {
    var _a;
    return (_a = row.ValuesTotal.find(function (v) { return v.IndicateurSignature == signature; })) === null || _a === void 0 ? void 0 : _a.Value;
}
var Row = /** @class */ (function (_super) {
    __extends(Row, _super);
    function Row(dimension, data) {
        var _this = _super.call(this) || this;
        _this.Values = [];
        // this.DataColumns = [];
        _this.Data = data;
        _this.Dimension = dimension;
        return _this;
    }
    Row.getLevel = function (rows, cur) {
        if (cur === void 0) { cur = 0; }
        if (!(rows === null || rows === void 0 ? void 0 : rows.length))
            return cur;
        var max = cur;
        rows.forEach(function (r) {
            if (r.Dimension.field == '@rid')
                return;
            var calc = Row.getLevel(r.Children, cur + 1);
            if (calc > max)
                max = calc;
        });
        return max;
    };
    Row.getLevelData = function (rows) {
        if (!(rows === null || rows === void 0 ? void 0 : rows.length))
            return [];
        var children = rows.map(function (c) { return c.Children; }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (c) { return c; });
        return __spreadArray([rows], this.getLevelData(children), true);
    };
    Row.ToArray = function (table, row, first) {
        var _a, _b, _c;
        if (first === void 0) { first = true; }
        var arrays = [];
        var levels = Row.getLevelData(row.Children);
        var afterArray = [];
        for (var i = 0; i < levels.length; i++) {
            var level = levels[i];
            if (((_b = (_a = level === null || level === void 0 ? void 0 : level[0]) === null || _a === void 0 ? void 0 : _a.Dimension) === null || _b === void 0 ? void 0 : _b.field) != '@rid')
                afterArray.push({
                    Formated: JoinElements(level.map(function (l) { return l.Formated; })), Value: JoinElements(level.map(function (l) { return l.Formated; })),
                    Type: "cell"
                });
        }
        var valuesCells = row.ValuesTotal
            .filter(function (v) { return !Object.values(eTotalDirection).some(function (d) { var _a; return (_a = v.IndicateurSignatureFull) === null || _a === void 0 ? void 0 : _a.startsWith(d); }); })
            .filter(function (v) { return table.IndicateursByKey[v.IndicateurSignature]; });
        var thisArray = __spreadArray(__spreadArray([], afterArray, true), valuesCells, true);
        if (!first && row.Dimension.field != "@rid")
            thisArray.unshift({
                Formated: row.Formated, Value: row.Formated,
                Type: "cell"
            });
        //if (levels.length == 0)
        arrays.push(thisArray);
        (_c = row.Children) === null || _c === void 0 ? void 0 : _c.forEach(function (c) {
            var _a;
            (_a = Row.ToArray(table, c, false)) === null || _a === void 0 ? void 0 : _a.forEach(function (cc) {
                arrays.push(!first ? __spreadArray([thisArray[0]], cc, true) : __spreadArray([], cc, true));
            });
        });
        return arrays;
    };
    return Row;
}(Ventilation));
export { Row };
var Column = /** @class */ (function () {
    function Column() {
    }
    return Column;
}());
export { Column };
var ArrayTable = /** @class */ (function () {
    function ArrayTable() {
    }
    return ArrayTable;
}());
export { ArrayTable };
var Table = /** @class */ (function () {
    function Table() {
    }
    Table.ToArray = function (table) {
        var res = [];
        var cells = [];
        var at = new ArrayTable();
        at.array = res;
        at.table = cells;
        var addHeaders = function () {
            /*
            let headers = [...table.Columns.map(r => r.Cell?.Formated), ...table.Indicateurs.map(i => {
                if (i.type === eIndicateurType.info)
                    return i.name; //  already translated in that case
                return Trad(i.name);
            })];
            res.push(headers);
            cells.push(headers.map(h => { return { Formated: h, Type: "header" } }))
            */
            function createExcelMatrix(data) {
                var result = [];
                function traverse(node, path) {
                    var newPath = __spreadArray(__spreadArray([], path, true), [node], false);
                    if (!node.Children || node.Children.length === 0) {
                        result.push(newPath);
                    }
                    else {
                        node.Children.forEach(function (child) { return traverse(child, newPath); });
                    }
                }
                data.forEach(function (rootNode) { return traverse(rootNode, []); });
                // Déterminer la longueur maximale des chemins
                var maxColumns = Math.max.apply(Math, result.map(function (row) { return row.length; }));
                // Remplir les chemins plus courts avec des cellules vides
                var formattedResult = result.map(function (row) {
                    while (row.length < maxColumns) {
                        row.push(null);
                    }
                    return row;
                });
                // rotate the matrix
                var rotatedResult = formattedResult[0].map(function (_, i) { return formattedResult.map(function (row) { return row[i]; }); });
                return rotatedResult;
            }
            createExcelMatrix(table.Columns).forEach(function (element) {
                res.push(element.map(function (c) { var _a; return (_a = c === null || c === void 0 ? void 0 : c.Cell) === null || _a === void 0 ? void 0 : _a.Formated; }));
                cells.push(element.map(function (c) { var _a, _b, _c, _d; return ({ Formated: (_b = (_a = c === null || c === void 0 ? void 0 : c.Cell) === null || _a === void 0 ? void 0 : _a.Formated) !== null && _b !== void 0 ? _b : "", Value: (_d = (_c = c === null || c === void 0 ? void 0 : c.Cell) === null || _c === void 0 ? void 0 : _c.Formated) !== null && _d !== void 0 ? _d : null, Type: "header" }); }));
            });
            // hierarchycal columns to flat 2 dimensions matrice headers
            // Object.values(dicoHeaders).forEach((columns, deepth) => {
            //     let headers = columns.flatMap(c => {
            //         const value = c.column.Cell?.Formated;
            //         // multiply the header if it has children
            //         return Array(c.numberOfFlatChildren).fill(value);
            //     });
            //     res.push(headers);
            //     cells.push(headers.map(h => { return { Formated: h, Type: "header" } }))
            // });
        };
        var addRows = function () {
            var _a;
            return (_a = table.Rows) === null || _a === void 0 ? void 0 : _a.forEach(function (r) { return Row.ToArray(table, r).forEach(function (c) {
                res.push(c.map(function (cell) {
                    var indicateur = table.IndicateursByKey[cell.IndicateurSignature];
                    if ((indicateur === null || indicateur === void 0 ? void 0 : indicateur.type) === eIndicateurType.info && cell.Formated != "" && cell.Formated)
                        return cell.Formated;
                    return cell.Value;
                }));
                cells.push(c);
            }); });
        };
        addHeaders();
        addRows();
        return at;
    };
    return Table;
}());
export { Table };

var DataProvider = /** @class */ (function () {
    function DataProvider() {
    }
    DataProvider.search = function () {
        throw new Error("Method not implemented.");
    };
    DataProvider.searchMemoized = function () {
        throw new Error("Method not implemented.");
    };
    DataProvider.getMetadata = function () {
        throw new Error("Method not implemented.");
    };
    DataProvider.getOJDWaves = function () {
        throw new Error("Method not implemented.");
    };
    DataProvider.getOJD = function () {
        throw new Error("Method not implemented.");
    };
    DataProvider.getVwMMDiscountClasses = function () {
        throw new Error("Method not implemented.");
    };
    return DataProvider;
}());
export { DataProvider };

export * from "./ADWGrid";
export * from "./ADWGridComponent";
import * as ADWGridImp from "./ADWGrid";
import * as ADWGridComponentImp from "./ADWGridComponent";
var AdwGrid = /** @class */ (function () {
    function AdwGrid() {
    }
    return AdwGrid;
}());
export { AdwGrid };
AdwGrid.ADWGrid = ADWGridImp;
AdwGrid.ADWGridComponent = ADWGridComponentImp;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export function EncapsulatedMethod(previous, next, returnnext) {
    if (returnnext === void 0) { returnnext = false; }
    return function (target, propertyName, propertyDesciptor) {
        var method = propertyDesciptor.value;
        var caller = target.constructor.name;
        propertyDesciptor.value = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            previous(propertyName, args, caller);
            // invoke greet() and get its return value
            var result = method.apply(this, args);
            if (result && typeof result["then"] === 'function') {
                var prom = result;
                result = prom.then(function (r) {
                    var retnext = next(propertyName, args, r, caller);
                    if (returnnext)
                        return retnext;
                    return r;
                });
            }
            else {
                var retnext = next(propertyName, args, result, caller);
                if (returnnext)
                    return retnext;
            }
            // return the result of invoking the method
            return result;
        };
        return propertyDesciptor;
    };
}
var CustomError = /** @class */ (function (_super) {
    __extends(CustomError, _super);
    function CustomError(_error) {
        var _this = _super.call(this, _error === null || _error === void 0 ? void 0 : _error.toString()) || this;
        _this.error = _error;
        return _this;
    }
    return CustomError;
}(Error));
export function ErrorHandler(msg) {
    return ErrorCatching(function (error, propertyName, args) {
        throw new CustomError({ msg: msg, propertyName: propertyName, args: args, error: error, toString: function () { return msg; } });
    });
}
export function ErrorCatching(error) {
    return function (target, propertyName, propertyDesciptor) {
        var method = propertyDesciptor.value;
        propertyDesciptor.value = function () {
            var _this = this;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var result = undefined;
            try {
                result = method.apply(this, args);
                if (result && typeof result["then"] === 'function') {
                    var prom = result;
                    result = prom.catch(function (e) { return error(e, propertyName, args, _this); });
                }
            }
            catch (e) {
                error(e, propertyName, args, this);
            }
            // return the result of invoking the method
            return result;
        };
        return propertyDesciptor;
    };
}
export function EncapsulatedMethodAsync(previous, next, error) {
    return function (target, propertyName, propertyDesciptor) {
        var method = propertyDesciptor.value;
        propertyDesciptor.value = function () {
            var _this = this;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return new Promise(function (resolve, reject) {
                var store = {};
                var catchException = function (ex) {
                    if (error != undefined)
                        error.apply(_this, [propertyName, args, ex, store]).then(function (r) { return reject(ex); }).catch(reject);
                    else
                        reject(ex);
                };
                previous.apply(_this, [propertyName, args, store]).then(function (r) {
                    // invoke greet() and get its return value
                    var result = method.apply(_this, args);
                    if (result && typeof result["then"] === 'function') {
                        var prom = result;
                        prom.then(function (res) {
                            next.apply(_this, [propertyName, args, res, store]).then(function () { return resolve(res); }).catch(reject);
                        }).catch(catchException);
                    }
                    else {
                        next.apply(_this, [propertyName, args, result, store]).then(resolve).catch(reject);
                    }
                }).catch(catchException);
            });
        };
        return propertyDesciptor;
    };
}

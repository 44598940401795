var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { V } from "./orientdb/V.bin";
var ref_SupplierCampaigns = /** @class */ (function (_super) {
    __extends(ref_SupplierCampaigns, _super);
    function ref_SupplierCampaigns() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ref_SupplierCampaigns;
}(V));
export { ref_SupplierCampaigns };
var ref_Couplages = /** @class */ (function (_super) {
    __extends(ref_Couplages, _super);
    function ref_Couplages() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ref_Couplages;
}(V));
export { ref_Couplages };
var ref_Spots = /** @class */ (function (_super) {
    __extends(ref_Spots, _super);
    function ref_Spots() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ref_Spots;
}(V));
export { ref_Spots };
export var SpotEmplacement;
(function (SpotEmplacement) {
    SpotEmplacement[SpotEmplacement["Indifferent"] = 0] = "Indifferent";
    SpotEmplacement[SpotEmplacement["TE"] = 1] = "TE";
    SpotEmplacement[SpotEmplacement["T2"] = 2] = "T2";
    SpotEmplacement[SpotEmplacement["T3"] = 3] = "T3";
    SpotEmplacement[SpotEmplacement["AF"] = 4] = "AF";
    SpotEmplacement[SpotEmplacement["FE"] = 5] = "FE";
    SpotEmplacement[SpotEmplacement["TE_T2_FE"] = 8] = "TE_T2_FE";
    SpotEmplacement[SpotEmplacement["Sans"] = 9] = "Sans";
    SpotEmplacement[SpotEmplacement["TE_FE"] = 12] = "TE_FE";
    SpotEmplacement[SpotEmplacement["AAF"] = 13] = "AAF";
    SpotEmplacement[SpotEmplacement["D4"] = 14] = "D4";
    SpotEmplacement[SpotEmplacement["D6"] = 15] = "D6";
    SpotEmplacement[SpotEmplacement["TE_T2"] = 16] = "TE_T2";
    SpotEmplacement[SpotEmplacement["TE_T2_T3"] = 17] = "TE_T2_T3";
    SpotEmplacement[SpotEmplacement["TE_T2_T3_FE"] = 18] = "TE_T2_T3_FE";
    SpotEmplacement[SpotEmplacement["AAF_AF_FE"] = 19] = "AAF_AF_FE";
    SpotEmplacement[SpotEmplacement["AF_FE"] = 20] = "AF_FE";
})(SpotEmplacement || (SpotEmplacement = {}));
export var SpotType;
(function (SpotType) {
    SpotType[SpotType["R\u00E9serv\u00E9"] = 0] = "R\u00E9serv\u00E9";
    SpotType[SpotType["Confirm\u00E9"] = 1] = "Confirm\u00E9";
    SpotType[SpotType["Demande"] = 2] = "Demande";
    SpotType[SpotType["Annul\u00E9"] = 3] = "Annul\u00E9";
    SpotType[SpotType["Supprim\u00E9"] = 4] = "Supprim\u00E9";
})(SpotType || (SpotType = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AggregateExport, ExportBase } from "../../models/external.bin";
import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";
var ExportArgChoices = /** @class */ (function () {
    function ExportArgChoices() {
        this.dimensionsChoices = [];
        this.templatesChoices = [];
        this.subTotalChoices = ["week", "month", "trimester", "semester"];
        this.rowTotalChoices = ["top", "bottom"];
    }
    return ExportArgChoices;
}());
export { ExportArgChoices };
export function GetNewDefaultExport() {
    return __assign(__assign({}, new ExportBase()), new AggregateExport());
}
var ref_ExportConfigurations = /** @class */ (function (_super) {
    __extends(ref_ExportConfigurations, _super);
    function ref_ExportConfigurations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ref_ExportConfigurations;
}(ref_ConfigurationsBase));
export { ref_ExportConfigurations };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as XLSX from 'xlsx';
import { clone } from '../tools.bin';
import { orderBy } from '@progress/kendo-data-query';
export var DayColWidth = 15;
var BorderHeader = {
    style: "thin",
    color: {
        rgb: "000000"
    }
};
export var BorderStyle = {
    border: {
        left: BorderHeader,
        right: BorderHeader,
        top: BorderHeader,
        bottom: BorderHeader
    }
};
export var BorderEventStyle = {
    border: {
        top: {
            style: "medium",
            color: {
                rgb: "FFFFFF"
            }
        },
        bottom: {
            style: "medium",
            color: {
                rgb: "FFFFFF"
            }
        }
    }
};
export var TopBorderTotalStyle = {
    border: {
        top: {
            style: "thin",
            color: {
                rgb: "000000"
            }
        },
    }
};
export var HeaderCellStyle = {
    s: __assign(__assign({}, BorderStyle), { alignment: { vertical: "center", horizontal: "center" }, numFmt: "General", fill: {
            patternType: "solid",
            fgColor: {
                theme: 0,
                tint: -0.3499862666707358,
                rgb: "C7E4F1"
            },
            bgColor: {
                indexed: 64
            }
        }, font: {
            sz: "11",
            name: "Calibri",
            bold: true
        } })
};
/**
 * Set column width
 * @param sheet
 * @param colIndex
 * @param colWidth
 */
export function SetColWidth(sheet, colIndex, colWidth) {
    if (!sheet["!cols"])
        sheet["!cols"] = [];
    var column = sheet["!cols"][colIndex];
    if (!column) {
        column = {};
        sheet["!cols"][colIndex] = column;
    }
    column.wpx = colWidth;
}
export function SetRowHeight(sheet, rowIndex, rowHeight) {
    if (!sheet["!rows"])
        sheet["!rows"] = [];
    var row = sheet["!rows"][rowIndex];
    if (!row) {
        row = {};
        sheet["!rows"][rowIndex] = row;
    }
    row.hpx = rowHeight;
}
/**
 * Merge 2 cells
 * @param sheet
 * @param start
 * @param end
 */
export function MergeCells(sheet, start, end, opt) {
    var _a = __assign({ lastRowTemplated: false, firstRowTemplated: false, forceClean: false }, opt), forceClean = _a.forceClean, firstRowTemplated = _a.firstRowTemplated, lastRowTemplated = _a.lastRowTemplated;
    if (start.c === end.c && start.r === end.r)
        return;
    if (!sheet["!merges"])
        sheet["!merges"] = [];
    if (forceClean) {
        var codedCells_1 = [XLSX.utils.encode_cell(start), XLSX.utils.encode_cell(end)];
        sheet["!merges"] = sheet["!merges"]
            .filter(function (m) { return !codedCells_1.includes(XLSX.utils.encode_cell(m.s)) && !codedCells_1.includes(XLSX.utils.encode_cell(m.e)); });
        if (start.c == end.c) {
            sheet["!merges"] = sheet["!merges"].filter(function (m) {
                if (m.s.c != start.c || m.e.c != start.c)
                    return true;
                if (m.s.c == m.e.c && m.s.c == start.c)
                    if (m.s.r > Math.min(start.r, end.r) && m.s.r < Math.max(start.r, end.r)) {
                        return false;
                    }
                return true;
            });
        }
    }
    if (!firstRowTemplated && !lastRowTemplated)
        sheet["!merges"].push({ s: start, e: end });
    // Récupère le style de la 1ere cellule pour l'appliquer sur toutes les cellules du merge
    var cellStart = sheet[XLSX.utils.encode_cell(lastRowTemplated ? end : start)];
    if (cellStart) {
        if (!cellStart.s)
            cellStart.s = {};
        var style = __assign({}, cellStart.s);
        // Application du style de la 1ere cellule sur toutes les cellules du merge
        // + ajout de wrapText (retour à la ligne)
        var first = true;
        var cpy = __assign({}, start);
        while (cpy.c <= end.c) {
            cpy.r = start.r;
            while (cpy.r <= end.r) {
                var coords = XLSX.utils.encode_cell(cpy);
                var last = (cpy.r == end.r) && (cpy.c == end.c);
                if (firstRowTemplated && !first || lastRowTemplated && !last) {
                    sheet[coords] = { t: "n", v: '' };
                    if (opt === null || opt === void 0 ? void 0 : opt.style)
                        sheet[coords].s = opt === null || opt === void 0 ? void 0 : opt.style;
                }
                else {
                    if (!sheet[coords])
                        sheet[coords] = { v: '', t: 'n' };
                    sheet[coords].s = style;
                    // if (sheet[coords].s && !sheet[coords].s.alignment)
                    //     sheet[coords].s.alignment = { vertical: 'center', wrapText: '1' };
                    // else {
                    //     sheet[coords].s.alignment.vertical = 'center';
                    //     sheet[coords].s.alignment.wrapText = '1';
                    // }
                }
                first = false;
                cpy.r++;
            }
            cpy.c++;
        }
    }
}
export function SetBorder(sheet, cell, position) {
    if (position === void 0) { position = 'top'; }
    var sheetCell = sheet[XLSX.utils.encode_cell(cell)];
    if (!sheetCell.s)
        sheetCell.s = {};
    if (!sheetCell.s['border'])
        sheetCell.s['border'] = {};
    sheetCell.s['border'][position] = {
        style: "dotted",
        color: {
            rgb: "FFC0C0C0",
        },
    };
}
export function CheckCellIntegrity(sheet, cell) {
    var _a, _b;
    switch (sheet[cell].t) {
        case 's':
            if (typeof sheet[cell].v != 'string')
                sheet[cell].v = (_b = ((_a = sheet[cell].v) !== null && _a !== void 0 ? _a : "")) === null || _b === void 0 ? void 0 : _b.toString();
            break;
        case 'n':
            if (typeof sheet[cell].v != 'number' && isNaN(Number(sheet[cell].v)))
                sheet[cell].t = 's';
            break;
        default:
            break;
    }
}
/**
 * Set value in excel
 * @param sheet
 * @param cell
 * @param value
 */
export function SetCellValue(sheet, cell, value, base) {
    if (base)
        base = clone(base);
    if (cell.c < 0 || cell.r < 0) {
        console.log("cannot be < 0");
        console.log(cell);
        console.log(value);
        console.trace();
        return;
    }
    var cellAdd = XLSX.utils.encode_cell(cell);
    sheet[cellAdd] = __assign(__assign(__assign({}, sheet[cellAdd]), ((value !== undefined && value !== null) && {
        t: "s",
        v: "".concat(value),
        h: "".concat(value),
        w: "".concat(value),
        r: "<t>".concat(value, "</t>")
    })), (value === null && { t: "n", v: '' }));
    if (base)
        Object.entries(base).forEach(function (_a) {
            var _b;
            var k = _a[0], v = _a[1];
            if (typeof sheet[cellAdd][k] == 'object' && typeof v == 'object')
                sheet[cellAdd][k] = __assign(__assign({}, sheet[cellAdd][k]), v);
            else
                sheet[cellAdd][k] = (_b = sheet[cellAdd][k]) !== null && _b !== void 0 ? _b : v;
        });
    CheckCellIntegrity(sheet, cellAdd);
}
/**
 * Apply style to cell
 * @param sheet
 * @param cell
 * @param value
 */
export function SetCellStyle(sheet, cells, value) {
    if (!cells)
        return;
    if (!Array.isArray(cells))
        cells = [cells];
    cells === null || cells === void 0 ? void 0 : cells.forEach(function (cell) {
        var _a;
        var cellAdd = XLSX.utils.encode_cell(cell);
        if (!sheet[cellAdd])
            sheet[cellAdd] = {};
        value = JSON.parse(JSON.stringify(value));
        sheet[cellAdd].s = __assign(__assign({}, sheet[cellAdd].s), value);
        if (!((_a = sheet[cellAdd]) === null || _a === void 0 ? void 0 : _a.v)) {
            sheet[cellAdd].v = '';
            sheet[cellAdd].t = 'n';
        }
    });
}
var replaceAll = function (str, search, replacement) {
    var regex = new RegExp(search, 'g');
    return str.replace(regex, replacement);
};
export function MyOrderBy(data, _descriptors) {
    var descriptors = clone(_descriptors);
    var newProps = [];
    descriptors.forEach(function (d) {
        if (!d.field.includes('.'))
            return;
        var newProp = replaceAll(d.field, '.', '__');
        newProps.push(newProp);
        data === null || data === void 0 ? void 0 : data.forEach(function (e) { e[newProp] = e[d.field]; });
        d.field = newProp;
    });
    var ordered = orderBy(data, descriptors);
    newProps.forEach(function (p) { return data.forEach(function (d) { return delete d[p]; }); });
    return ordered;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ReturnCurrencyProvider } from "hub-lib/business/ReturnCurrencyProvider.bin";
import { Client } from "hub-lib/client/client.bin";
import { lnk_ChangeRate } from "hub-lib/dto/client/lnk_ChangeRate.bin";
import { lnk_HasCurrency } from "hub-lib/models/orientdb/lnk_HasCurrency.bin";
import { Trad as TradCode, TradClassName, TradProvider } from "trad-lib";
import { Trad } from "hub-lib/dto/admin/Trad.bin";
import { IsDebugMode } from "./utils/localstorage.bin";
import { Notify, NotifyError } from "./utils/Notify.bin";
var base = Date.prototype.toJSON;
Date.prototype.toJSON = function () { var date = new Date(this); date.setHours(0, 0, 0, 0); var userTimezoneOffset = date.getTimezoneOffset() * 60000; var copy = new Date(date.getTime() - userTimezoneOffset); for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
} return base.apply(copy, args); };
ReturnCurrencyProvider.ChangeRateProvider = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, Client.searchVertex(lnk_ChangeRate.name)];
        case 1: return [2 /*return*/, (_a.sent()).data.results];
    }
}); }); };
ReturnCurrencyProvider.HasCurrencyProvider = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, Client.searchVertex(lnk_HasCurrency.name, { AllCompanies: true })];
        case 1: return [2 /*return*/, (_a.sent()).data.results];
    }
}); }); };
export var keyStoreTrads = 'all-trads';
function GetStorageTrad() { var stored = localStorage.getItem(keyStoreTrads); if (stored) {
    var storageTrads = JSON.parse(stored);
    if (storageTrads && !IsDebugMode()) {
        var expiration = new Date(storageTrads.expires);
        if (new Date().getTime() < expiration.getTime()) {
            return storageTrads.Trads;
        }
    }
} }
function SetStorageTrad(trads) { var expires = new Date(); expires.setMinutes(expires.getMinutes() + 10); var storageTrads = { expires: expires === null || expires === void 0 ? void 0 : expires.toString(), Trads: trads }; localStorage.setItem(keyStoreTrads, JSON.stringify(storageTrads)); }
function RemoveTrads() { localStorage.removeItem(keyStoreTrads); }
var flushTrad = function (url) { if (url.includes("/".concat(Trad.name)))
    RemoveTrads(); if (IsDebugMode())
    console.log("[onRequestDone]", url, Client.defaultAxiosConfig); };
Client.onRequestDone.on('POST', flushTrad);
Client.onRequestDone.on('PUT', flushTrad);
Client.onRequestDone.on('DELETE', flushTrad);
Client.onCreationVertex.addListener("successful", function (arg) { var _arg$res, _arg$res2; var baseMsg = TradCode('creation_success'); baseMsg += ".\n"; baseMsg += TradClassName(arg === null || arg === void 0 ? void 0 : arg.vertex); if (arg !== null && arg !== void 0 && arg.res && (arg === null || arg === void 0 ? void 0 : (_arg$res = arg.res) === null || _arg$res === void 0 ? void 0 : _arg$res.length) === 1 || arg !== null && arg !== void 0 && (_arg$res2 = arg.res) !== null && _arg$res2 !== void 0 && _arg$res2.Name) {
    var _arg$res3, _arg$res$;
    var name = (arg === null || arg === void 0 ? void 0 : (_arg$res3 = arg.res) === null || _arg$res3 === void 0 ? void 0 : _arg$res3.Name) || ((_arg$res$ = arg.res[0]) === null || _arg$res$ === void 0 ? void 0 : _arg$res$.Name);
    if (name) {
        baseMsg += ": ".concat(name);
    }
} Notify(baseMsg, "success"); });
Client.onCreationVertex.addListener("fail", function (arg) { NotifyError(__assign(__assign({}, arg), arg.res)); });
Client.onReadVertex.addListener("fail", function (arg) { NotifyError(__assign(__assign({}, arg), arg.res)); });
Client.onErrorEvent.addListener("fail", function (arg) { NotifyError(__assign(__assign({}, arg), arg.error)); });
Client.onPersistVertex.addListener("successful", function (arg) { var _arg$res4; var baseMsg = TradCode('persist_success'); if (arg !== null && arg !== void 0 && (_arg$res4 = arg.res) !== null && _arg$res4 !== void 0 && _arg$res4.length) {
    baseMsg += ": ".concat(arg.res.length);
} Notify(baseMsg, "success"); });
Client.onPersistVertex.addListener("fail", function (arg) { NotifyError(__assign(__assign({}, arg), arg.res)); });
Client.onUpdateVertex.addListener("successful", function (arg) { var _arg$params, _arg$params2; var baseMsg = TradCode('update_success'); baseMsg += ".\n"; baseMsg += TradClassName(arg === null || arg === void 0 ? void 0 : arg.vertex); if (arg !== null && arg !== void 0 && arg.params && (arg === null || arg === void 0 ? void 0 : (_arg$params = arg.params) === null || _arg$params === void 0 ? void 0 : _arg$params.length) > 0 || arg !== null && arg !== void 0 && (_arg$params2 = arg.params) !== null && _arg$params2 !== void 0 && _arg$params2.Name) {
    var _arg$params3, _arg$params$;
    var name = (arg === null || arg === void 0 ? void 0 : (_arg$params3 = arg.params) === null || _arg$params3 === void 0 ? void 0 : _arg$params3.Name) || ((_arg$params$ = arg.params[0]) === null || _arg$params$ === void 0 ? void 0 : _arg$params$.Name);
    if (name) {
        baseMsg += ": ".concat(name);
    }
} Notify(baseMsg, "success"); });
Client.onUpdateVertex.addListener("fail", function (arg) { NotifyError(arg.params); });
Client.onDeleteVertex.addListener("successful", function (arg) { var baseMsg = TradCode('delete_success'); baseMsg += ".\n"; baseMsg += TradClassName(arg === null || arg === void 0 ? void 0 : arg.vertex); Notify(baseMsg, "success"); });
Client.onDeleteVertex.addListener("fail", function (arg) { NotifyError(arg.res); });
TradProvider.TradGetter = function () { return __awaiter(void 0, void 0, void 0, function () { var trads, stored; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            trads = undefined;
            stored = GetStorageTrad();
            if (stored)
                return [2 /*return*/, stored];
            return [4 /*yield*/, Client.searchVertex(Trad.name)];
        case 1:
            trads = (_a.sent()).data.results;
            SetStorageTrad(trads);
            return [2 /*return*/, trads];
    }
}); }); };

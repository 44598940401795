import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
export function GetDiscountRate(discount, cofo) {
    var _baseValue$Rate;
    if (!discount)
        return null; // CO, FO ou FOS
    var baseValue = DiscountManager.getModulation(discount, cofo);
    var value = ((_baseValue$Rate = baseValue === null || baseValue === void 0 ? void 0 : baseValue.Rate) !== null && _baseValue$Rate !== void 0 ? _baseValue$Rate : 0) * 100 * discount.Operator;
    if (value == -0)
        value = 0;
    return value;
}

export var eReportStatus;
(function (eReportStatus) {
    eReportStatus["IN_PROGRESS"] = "IN_PROGRESS";
    eReportStatus["DONE"] = "DONE";
    eReportStatus["FAILED"] = "FAILED";
})(eReportStatus || (eReportStatus = {}));
var ref_Reports = /** @class */ (function () {
    function ref_Reports() {
        this.Active = true;
        this.Date = new Date();
        this.Progress = {
            Status: eReportStatus.IN_PROGRESS,
            Total: 100,
            Current: 0,
        };
        this.Errors = [];
    }
    return ref_Reports;
}());
export { ref_Reports };

import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { db } from './db.bin';
import { Trad } from "../trad-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
var dico = {};
Object.entries(db).forEach(function (e) {
    var value = {};
    e[1].mandatory.forEach(function (p) {
        value[p] = function (obj) {
            var valid = {
                isValid: obj.hasOwnProperty(p) && obj[p] !== "",
                errorText: Trad("required"),
                property: p
            };
            return valid;
        };
    });
    dico[e[0]] = value;
});
//SetValidation<ref_Campaigns>(ref_Campaigns, "Name", (c: ref_Campaigns) => { return { isValid: !isEmpty(c.Name), errorText: "Nom requis", property: "Name" } })
SetValidation(ref_Campaigns, "Advertiser", validAdvertiser);
SetValidation(ref_Campaigns, "AdvertiserGroup", validGroupAdvertiser);
SetValidation(ref_Campaigns, "Support", validSupport);
SetValidation(ref_Messages, "Advertiser", validAdvertiser);
SetValidation(ref_Messages, "AdvertiserGroup", validGroupAdvertiser);
function validGroupAdvertiser(c, className) {
    var _a, _b;
    var isValid = (((_b = (_a = RightManager.GetUser()) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.AdvertiserMode) != className)
        || !RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read)
        || (!isEmpty(c.Group) && isEmpty(c.Advertiser) && isEmpty(c.AdvertiserGroup))
        || (isEmpty(c.Group) && (!isEmpty(c.Advertiser) || !isEmpty(c.AdvertiserGroup)));
    return {
        isValid: isValid,
        errorText: !isValid ? Trad("required") : "",
        property: "AdvertiserGroup"
    };
}
function validAdvertiser(c, className, validated) {
    var _a, _b;
    var isValid = (((_b = (_a = RightManager.GetUser()) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.AdvertiserMode) != className)
        || !RightManager.hasRight(ref_Advertisers.name, eRights.read)
        || (!isEmpty(c.Group) && isEmpty(c.Advertiser) && isEmpty(c.AdvertiserGroup))
        || (isEmpty(c.Group) && (!isEmpty(c.Advertiser) || !isEmpty(c.AdvertiserGroup)));
    return {
        isValid: isValid,
        errorText: !isValid ? Trad("required") : "",
        property: "Advertiser"
    };
}
function validSupport(c, className, validated) {
    var _a, _b;
    var isValid = (((_b = (_a = RightManager.GetUser()) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.SupportMode) != className) || !isEmpty(c.Support);
    return {
        isValid: isValid,
        errorText: !isValid ? Trad("required") : "",
        property: "Support"
    };
}
function isEmpty(value) {
    return (value == null || value === '');
}
var Validation = /** @class */ (function () {
    function Validation() {
    }
    return Validation;
}());
function SetValidation(prototype, property, validator) {
    if (!dico[prototype.name])
        dico[prototype.name] = {};
    dico[prototype.name][property] = validator;
}
export function IsValid(prototype, data, property) {
    var _a, _b;
    return (_b = (_a = dico === null || dico === void 0 ? void 0 : dico[prototype.name]) === null || _a === void 0 ? void 0 : _a[property]) === null || _b === void 0 ? void 0 : _b.call(_a, data, prototype.name);
}
export function IsObjectValid(prototype, data) {
    var _a;
    var type = dico === null || dico === void 0 ? void 0 : dico[prototype.name];
    if (!type)
        return [];
    return (_a = Object.entries(type).map(function (p) { return p[1](data); })) === null || _a === void 0 ? void 0 : _a.filter(function (p) { return !p.isValid; });
}
export function IsValidProperty(prototype, data, property) {
    var _a, _b;
    return (_b = (_a = dico === null || dico === void 0 ? void 0 : dico[prototype]) === null || _a === void 0 ? void 0 : _a[property]) === null || _b === void 0 ? void 0 : _b.call(_a, data);
}

var ExternalCompany = /** @class */ (function () {
    function ExternalCompany() {
    }
    return ExternalCompany;
}());
export { ExternalCompany };
;
var ExternalClients = /** @class */ (function () {
    function ExternalClients() {
    }
    return ExternalClients;
}());
export { ExternalClients };

var _a;
import * as RecordID from "orientjs/lib/recordid.js";
/**
 * Description of vertex property
 */
var ClassProperty = /** @class */ (function () {
    function ClassProperty() {
    }
    return ClassProperty;
}());
export { ClassProperty };
/**
 * Property types
 * Based on: https://orientdb.com/docs/3.0.x/general/Types.html
 */
export var ePropType;
(function (ePropType) {
    ePropType[ePropType["Boolean"] = 0] = "Boolean";
    ePropType[ePropType["Integer"] = 1] = "Integer";
    ePropType[ePropType["Short"] = 2] = "Short";
    ePropType[ePropType["Long"] = 3] = "Long";
    ePropType[ePropType["Float"] = 4] = "Float";
    ePropType[ePropType["Double"] = 5] = "Double";
    ePropType[ePropType["Datetime"] = 6] = "Datetime";
    ePropType[ePropType["String"] = 7] = "String";
    ePropType[ePropType["Binary"] = 8] = "Binary";
    ePropType[ePropType["Embedded"] = 9] = "Embedded";
    ePropType[ePropType["EmbeddedList"] = 10] = "EmbeddedList";
    ePropType[ePropType["EmbeddedSet"] = 11] = "EmbeddedSet";
    ePropType[ePropType["EmbeddedMap"] = 12] = "EmbeddedMap";
    ePropType[ePropType["Link"] = 13] = "Link";
    ePropType[ePropType["LinkList"] = 14] = "LinkList";
    ePropType[ePropType["LinkSet"] = 15] = "LinkSet";
    ePropType[ePropType["LinkMap"] = 16] = "LinkMap";
    ePropType[ePropType["Byte"] = 17] = "Byte";
    ePropType[ePropType["Transient"] = 18] = "Transient";
    ePropType[ePropType["Date"] = 19] = "Date";
    ePropType[ePropType["Custom"] = 20] = "Custom";
    ePropType[ePropType["Decimal"] = 21] = "Decimal";
    ePropType[ePropType["LinkBag"] = 22] = "LinkBag";
    ePropType[ePropType["Any"] = 23] = "Any";
})(ePropType || (ePropType = {}));
export function IsLink(type) {
    return [
        ePropType.Link,
        ePropType.LinkList,
        ePropType.LinkSet,
        ePropType.LinkBag
    ].includes(type);
}
export var eHandleTypes = (_a = {},
    _a[ePropType.Boolean] = "boolean",
    _a[ePropType.Integer] = "number",
    _a[ePropType.Short] = "number",
    _a[ePropType.Long] = "number",
    _a[ePropType.Float] = "number",
    _a[ePropType.Double] = "number",
    _a[ePropType.Datetime] = "Date",
    _a[ePropType.String] = "string",
    _a[ePropType.Binary] = "number",
    _a[ePropType.Embedded] = "Object",
    _a[ePropType.EmbeddedList] = "Object",
    _a[ePropType.EmbeddedSet] = "Object",
    _a[ePropType.EmbeddedMap] = "Object",
    _a[ePropType.Link] = "Object",
    _a[ePropType.LinkList] = "Object",
    _a[ePropType.LinkSet] = "Object",
    _a[ePropType.LinkMap] = "Object",
    _a[ePropType.Byte] = "number",
    _a[ePropType.Transient] = "any",
    _a[ePropType.Date] = "Date",
    _a[ePropType.Custom] = "any",
    _a[ePropType.Decimal] = "number",
    _a[ePropType.LinkBag] = "any",
    _a[ePropType.Any] = "any",
    _a);
export var linkable = ["Embedded",
    "EmbeddedList",
    "EmbeddedSet",
    "EmbeddedMap",
    "Link",
    "LinkList",
    "LinkSet",
    "LinkMap",];
/*Belkacem*/
export var test = undefined;
export function IsInt(value) {
    var numberTypes = [
        ePropType.Integer,
        ePropType.Short,
        ePropType.Long,
        ePropType.Byte
    ];
    return numberTypes.includes(value);
}
export function IsLinkset(value) {
    var numberTypes = [
        ePropType.LinkSet
    ];
    return numberTypes.includes(value);
}
export function parseLinkset(values) {
    if (Array.isArray(values))
        return values.map(function (value) { return typeof value === "string" ? RecordID.parse(value) : value; });
    return new RecordID(values);
}
export function IsDecimal(value) {
    var numberTypes = [
        ePropType.Float,
        ePropType.Double,
        ePropType.Decimal,
    ];
    return numberTypes.includes(value);
}

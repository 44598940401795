import { MessageAggregator } from "./MessageAggregator";
import { CampaignAggregator } from "./CampaignAggregator";
var AggregatorFactory = /** @class */ (function () {
    function AggregatorFactory() {
        this._aggregators = {};
    }
    AggregatorFactory.GetInstance = function () {
        if (!AggregatorFactory._instance) {
            AggregatorFactory._instance = new AggregatorFactory();
        }
        return AggregatorFactory._instance;
    };
    AggregatorFactory.prototype.Get = function (objectType) {
        var key = typeof objectType == "string" ? objectType : objectType.name;
        if (!this._aggregators[key]) {
            switch (key) {
                case "ref_Messages":
                    this._aggregators[key] = new MessageAggregator();
                    break;
                case "ref_Campaigns":
                    this._aggregators[key] = new CampaignAggregator();
                    break;
            }
        }
        return this._aggregators[key];
    };
    return AggregatorFactory;
}());
export { AggregatorFactory };

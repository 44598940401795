var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "hub-lib/client/client.bin";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { ref_AdvertisingCompanyRole } from "hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { ref_SubAgencies } from "hub-lib/models/orientdb/ref_SubAgencies.bin";
import { SupportExtended } from "hub-lib/dto/referential/SupportExtended.bin";
import { eCompare } from "hub-lib/operators.bin";
import { clearEmptyValues, compareObjects, duplicate, extractSub } from "hub-lib/tools.bin";
var advComRole = undefined;
var advFinRole = undefined;
var SupportStoreSingleRid = /** @class */ (function () {
    function SupportStoreSingleRid() {
        this.Media = void 0;
        this.BroadcastArea = void 0;
        this.Currency = void 0;
        this.Support = void 0;
        this.AdvCompany_Com = void 0;
        this.AdvCompany_Fin = void 0;
        this.Format = void 0;
        this.Placement = void 0;
    }
    return SupportStoreSingleRid;
}());
export { SupportStoreSingleRid };
;
var SupportStoreMultiRid = /** @class */ (function () {
    function SupportStoreMultiRid() {
        this.Media = void 0;
        this.BroadcastArea = void 0;
        this.Currency = void 0;
        this.Support = void 0;
        this.AdvCompany_Com = void 0;
        this.AdvCompany_Fin = void 0;
        this.Format = void 0;
        this.Placement = void 0;
    }
    return SupportStoreMultiRid;
}());
export { SupportStoreMultiRid };
;
var StoreStateHierarchy = /** @class */ (function () {
    function StoreStateHierarchy() {
        this.MediaOptions = void 0;
        this.BroadcastAreaOptions = void 0;
        this.CurrencyOptions = void 0;
        this.AllBroadcastAreaOptions = void 0;
        this.AllCurrencyOptions = void 0;
        this.SupportOptions = void 0;
        this.AdvCompany_ComOptions = void 0;
        this.AdvCompany_FinOptions = void 0;
        this.FormatOptions = void 0;
        this.PlacementOptions = void 0;
    }
    return StoreStateHierarchy;
}());
export { StoreStateHierarchy };
var StoreState = /** @class */ (function (_super) {
    __extends(StoreState, _super);
    function StoreState() {
        var _this = _super.apply(this, arguments) || this;
        _this.isLoaded = void 0;
        _this.store = void 0;
        _this.MediaLoading = void 0;
        _this.BroadcastAreaLoading = void 0;
        _this.CurrencyLoading = void 0;
        _this.SupportLoading = void 0;
        _this.AdvCompany_ComLoading = void 0;
        _this.AdvCompany_FinLoading = void 0;
        _this.FormatLoading = void 0;
        _this.PlacementLoading = void 0;
        return _this;
    }
    return StoreState;
}(StoreStateHierarchy));
export { StoreState };
var initialState = { isLoaded: false, store: {}, AllBroadcastAreaOptions: undefined, AllCurrencyOptions: undefined, MediaOptions: undefined, BroadcastAreaOptions: undefined, CurrencyOptions: undefined, SupportOptions: undefined, AdvCompany_ComOptions: undefined, AdvCompany_FinOptions: undefined, FormatOptions: undefined, PlacementOptions: undefined, MediaLoading: false, BroadcastAreaLoading: false, CurrencyLoading: false, SupportLoading: false, AdvCompany_ComLoading: false, AdvCompany_FinLoading: false, FormatLoading: false, PlacementLoading: false };
export var setStore = createAsyncThunk('supportStorefilters/setStore', function (_ref, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var store, _supportStorage, _currentStorage$Media, newStorage_1, currentStorage_1, isLoaded, _a, _b, mediasChanged, supportsChanged, advCmpChanged, _currentStorage$AllBr, _currentStorage$AllCu, advCompaniesRoles, _c, _d, _e, _f, advCompanies, _store$AdvCompany_Com, _store$AdvCompany_Fin, _store$Media, advCmpRids, advCmp, hasSubAgencies, _g, _store$Media2, _store$Media3, property, operators, _h, _store$Media4, _store$Media5, property, operators, _j, _newStorage$AllCurren, _newStorage$AllBroadc, _store$BroadcastArea, _store$Currency, allCurrencyOptions, allBroadcastAreaOptions, error_1;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                store = _ref.store;
                _k.label = 1;
            case 1:
                _k.trys.push([1, 24, , 25]);
                store = clearEmptyValues(duplicate(store));
                newStorage_1 = { store: store, isLoaded: true };
                currentStorage_1 = (_supportStorage = thunkAPI.getState().supportStorage) !== null && _supportStorage !== void 0 ? _supportStorage : {};
                isLoaded = currentStorage_1.isLoaded;
                _a = newStorage_1;
                if (!((_currentStorage$Media = currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.MediaOptions) !== null && _currentStorage$Media !== void 0)) return [3 /*break*/, 2];
                _b = _currentStorage$Media;
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, Client.searchVertexTyped(ref_Media)];
            case 3:
                _b = _k.sent();
                _k.label = 4;
            case 4:
                _a.MediaOptions = _b;
                mediasChanged = function () { return !compareObjects(extractSub(currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.store, ["Media"]), extractSub(newStorage_1.store, ["Media"])); };
                supportsChanged = function () { return !compareObjects(extractSub(currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.store, ["Support"]), extractSub(newStorage_1.store, ["Support"])); };
                advCmpChanged = function () { return !compareObjects(extractSub(currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.store, ["AdvCompany_Com", "AdvCompany_Fin"]), extractSub(newStorage_1.store, ["AdvCompany_Com", "AdvCompany_Fin"])); };
                if (!(!isLoaded || mediasChanged() || advCmpChanged() || supportsChanged()) // || advertisersChanged()
                ) return [3 /*break*/, 23]; // || advertisersChanged()
                if (!(!advComRole || !advFinRole)) return [3 /*break*/, 6];
                return [4 /*yield*/, Client.searchVertexTyped(ref_AdvertisingCompanyRole)];
            case 5:
                advCompaniesRoles = _k.sent();
                advComRole = advCompaniesRoles.find(function (r) { return r.Name == "Commercial"; });
                advFinRole = advCompaniesRoles.find(function (r) { return r.Name == "Financial"; });
                _k.label = 6;
            case 6:
                _c = newStorage_1;
                if (!((_currentStorage$AllBr = currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.AllBroadcastAreaOptions) !== null && _currentStorage$AllBr !== void 0)) return [3 /*break*/, 7];
                _d = _currentStorage$AllBr;
                return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, Client.searchVertexTyped(ref_BroadcastAreas)];
            case 8:
                _d = _k.sent();
                _k.label = 9;
            case 9:
                _c.AllBroadcastAreaOptions = _d; // Load Currencies
                _e = newStorage_1;
                if (!((_currentStorage$AllCu = currentStorage_1 === null || currentStorage_1 === void 0 ? void 0 : currentStorage_1.AllCurrencyOptions) !== null && _currentStorage$AllCu !== void 0)) return [3 /*break*/, 10];
                _f = _currentStorage$AllCu;
                return [3 /*break*/, 12];
            case 10: return [4 /*yield*/, Client.searchVertexTyped(ref_Currencies)];
            case 11:
                _f = _k.sent();
                _k.label = 12;
            case 12:
                _e.AllCurrencyOptions = _f;
                if (!!isLoaded) return [3 /*break*/, 14];
                return [4 /*yield*/, Client.searchVertexTyped(ref_AdvertisingCompanies)];
            case 13:
                advCompanies = _k.sent();
                newStorage_1.AdvCompany_ComOptions = advCompanies;
                newStorage_1.AdvCompany_FinOptions = advCompanies;
                _k.label = 14;
            case 14:
                if (!(!isLoaded || mediasChanged() || advCmpChanged())) return [3 /*break*/, 16];
                advCmpRids = Array.from(new Set(__spreadArray(__spreadArray([], ((_store$AdvCompany_Com = store.AdvCompany_Com) !== null && _store$AdvCompany_Com !== void 0 ? _store$AdvCompany_Com : []), true), ((_store$AdvCompany_Fin = store.AdvCompany_Fin) !== null && _store$AdvCompany_Fin !== void 0 ? _store$AdvCompany_Fin : []), true)));
                advCmp = advCmpRids.map(function (rid) { var _newStorage$AdvCompan; return ((_newStorage$AdvCompan = newStorage_1.AdvCompany_ComOptions) !== null && _newStorage$AdvCompan !== void 0 ? _newStorage$AdvCompan : currentStorage_1.AdvCompany_ComOptions).find(function (e) { return e["@rid"] == rid; }); });
                hasSubAgencies = advCmp.some(function (e) { return e["@class"] == ref_SubAgencies.name; });
                _g = newStorage_1;
                return [4 /*yield*/, Client.searchVertexTyped(SupportExtended, __assign(__assign({ options: { lnkAdvertisingCompanies: true, lnkBroadcastAreas: false, lnkCurrencies: false, traverselnk: false, current: true }, properties: ["@rid", "Name"] }, (!hasSubAgencies && advCmp.length && { parents: advCmpRids })), (((_store$Media = store.Media) === null || _store$Media === void 0 ? void 0 : _store$Media.length) && { _operators: [{ property: "Medias", value: store.Media, compare: eCompare.ContainsAny }] })))];
            case 15:
                _g.SupportOptions = _k.sent();
                newStorage_1.SupportOptions = newStorage_1.SupportOptions.filter(function (s) { var _store$AdvCompany_Com2, _store$AdvCompany_Fin2; if ((_store$AdvCompany_Com2 = store.AdvCompany_Com) !== null && _store$AdvCompany_Com2 !== void 0 && _store$AdvCompany_Com2.length && !s.lnkAdvertisingCompanies.some(function (l) { return l.Roles.includes(advComRole["@rid"]) && store.AdvCompany_Com.includes(l.in); }))
                    return false; if ((_store$AdvCompany_Fin2 = store.AdvCompany_Fin) !== null && _store$AdvCompany_Fin2 !== void 0 && _store$AdvCompany_Fin2.length && !s.lnkAdvertisingCompanies.some(function (l) { return l.Roles.includes(advFinRole["@rid"]) && store.AdvCompany_Fin.includes(l.in); }))
                    return false; return true; });
                _k.label = 16;
            case 16:
                if (!(!isLoaded || mediasChanged())) return [3 /*break*/, 19];
                return [4 /*yield*/, Client.searchVertexTyped(ref_PropertyType, { Type: "Emplacement" })];
            case 17:
                property = (_k.sent())[0];
                operators = [{ property: "PropertyType", value: [property["@rid"]], compare: eCompare.ContainsAny }];
                if ((_store$Media2 = store.Media) !== null && _store$Media2 !== void 0 && _store$Media2.length)
                    operators.push({ property: "Medias", value: (_store$Media3 = store.Media) !== null && _store$Media3 !== void 0 ? _store$Media3 : [], compare: eCompare.ContainsAny });
                _h = newStorage_1;
                return [4 /*yield*/, Client.searchVertexTyped(ref_Property, { properties: ["@rid", "Name"], _operators: operators })];
            case 18:
                _h.PlacementOptions = _k.sent();
                _k.label = 19;
            case 19:
                if (!(!isLoaded || mediasChanged())) return [3 /*break*/, 22];
                return [4 /*yield*/, Client.searchVertexTyped(ref_PropertyType, { Type: "Format" })];
            case 20:
                property = (_k.sent())[0];
                operators = [{ property: "PropertyType", value: [property["@rid"]], compare: eCompare.ContainsAny }];
                if ((_store$Media4 = store.Media) !== null && _store$Media4 !== void 0 && _store$Media4.length)
                    operators.push({ property: "Medias", value: (_store$Media5 = store.Media) !== null && _store$Media5 !== void 0 ? _store$Media5 : [], compare: eCompare.ContainsAny });
                _j = newStorage_1;
                return [4 /*yield*/, Client.searchVertexTyped(ref_Property, { properties: ["@rid", "Name"], _operators: operators })];
            case 21:
                _j.FormatOptions = _k.sent();
                _k.label = 22;
            case 22:
                if (!isLoaded || supportsChanged() || mediasChanged() || advCmpChanged()) {
                    allCurrencyOptions = (_newStorage$AllCurren = newStorage_1.AllCurrencyOptions) !== null && _newStorage$AllCurren !== void 0 ? _newStorage$AllCurren : currentStorage_1.AllCurrencyOptions;
                    allBroadcastAreaOptions = (_newStorage$AllBroadc = newStorage_1.AllBroadcastAreaOptions) !== null && _newStorage$AllBroadc !== void 0 ? _newStorage$AllBroadc : currentStorage_1.AllBroadcastAreaOptions;
                    newStorage_1.BroadcastAreaOptions = allBroadcastAreaOptions;
                    newStorage_1.CurrencyOptions = allCurrencyOptions;
                    if (store.BroadcastArea)
                        store.BroadcastArea = (_store$BroadcastArea = store.BroadcastArea) === null || _store$BroadcastArea === void 0 ? void 0 : _store$BroadcastArea.filter(function (id) { return newStorage_1.BroadcastAreaOptions.some(function (b) { return b["@rid"] == id; }); });
                    if (store.Currency)
                        store.Currency = (_store$Currency = store.Currency) === null || _store$Currency === void 0 ? void 0 : _store$Currency.filter(function (id) { return newStorage_1.CurrencyOptions.some(function (b) { return b["@rid"] == id; }); });
                }
                _k.label = 23;
            case 23: return [2 /*return*/, newStorage_1];
            case 24:
                error_1 = _k.sent();
                console.log("SupportHierarchyComponent error", error_1);
                return [2 /*return*/, { store: store, isLoaded: false }];
            case 25: return [2 /*return*/];
        }
    });
}); });
export var StoreSlice = createSlice({ name: 'supportStorefilters', initialState: initialState, extraReducers: function (builder) {
        builder.addCase(setStore.pending, function (state, action) { var _state$store, _action$meta$arg$stor; var previousStore = duplicate((_state$store = state === null || state === void 0 ? void 0 : state.store) !== null && _state$store !== void 0 ? _state$store : {}); var newStore = duplicate((_action$meta$arg$stor = action.meta.arg.store) !== null && _action$meta$arg$stor !== void 0 ? _action$meta$arg$stor : {}); if (!compareObjects(extractSub(previousStore, ["Media"]), extractSub(newStore, ["Media"]))) {
            state.SupportLoading = true;
            state.CurrencyLoading = true;
            state.BroadcastAreaLoading = true;
            state.FormatLoading = true;
            state.PlacementLoading = true;
        }
        else if (!compareObjects(extractSub(previousStore, ["Support", "AdvCompany_Com", "AdvCompany_Fin"]), extractSub(newStore, ["Support", "AdvCompany_Com", "AdvCompany_Fin"]))) {
            state.BroadcastAreaLoading = true;
            state.CurrencyLoading = true;
        } }); // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(setStore.fulfilled, function (state, action) { if (action.payload)
            Object.entries(action.payload).forEach(function (_ref2) { var k = _ref2[0], v = _ref2[1]; state[k] = v; }); Object.entries(state).forEach(function (_ref3) { var k = _ref3[0], v = _ref3[1]; if (k.endsWith("Loading"))
            state[k] = false; }); });
    }, reducers: {} }); // Action creators are generated for each case reducer function
export var _b = _a = StoreSlice.actions;
export var SupportStoreReducer = StoreSlice.reducer;

// useCustomStyles.js
import { useState } from 'react';
import { css } from '@emotion/css'; // Helper function to infer the keys of the styles
export function useCustomStyles(styles, theme) {
    var getClasses = function () {
        var evaluatedStyles = typeof styles === 'function' ? styles(theme) : styles;
        var generated = {};
        for (var key in evaluatedStyles) { // Assuming 'css' is a function that returns a string
            generated[key] = css(evaluatedStyles[key]);
        }
        return generated;
    }; // Use the inferred type from the generated object
    var classes = useState(getClasses())[0];
    return classes;
}

export var EstimateStatus;
(function (EstimateStatus) {
    EstimateStatus["Reported"] = "Reported";
    EstimateStatus["Validated"] = "Validated";
})(EstimateStatus || (EstimateStatus = {}));
var ref_Estimates = /** @class */ (function () {
    function ref_Estimates() {
    }
    return ref_Estimates;
}());
export { ref_Estimates };
var LinkedEstimate = /** @class */ (function () {
    function LinkedEstimate() {
    }
    return LinkedEstimate;
}());
export { LinkedEstimate };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { EventEmitter } from "events";
import { clone } from "../tools.bin";
var ClientAXIOS = /** @class */ (function () {
    function ClientAXIOS(urlBase, config) {
        if (config === void 0) { config = {}; }
        this.onRequestDone = new EventEmitter();
        this.urlBase = urlBase;
        this._defaultAxiosConfig = config;
    }
    Object.defineProperty(ClientAXIOS.prototype, "defaultAxiosConfig", {
        get: function () {
            return this._defaultAxiosConfig;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClientAXIOS.prototype, "headers", {
        get: function () {
            return {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClientAXIOS.prototype, "url", {
        get: function () {
            return typeof this.urlBase === "function" ? this.urlBase() : this.urlBase;
        },
        enumerable: false,
        configurable: true
    });
    ClientAXIOS.prototype.mergeConfigs = function (config, config2) {
        var _a, _b;
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_c) {
                return [2 /*return*/, __assign(__assign(__assign({}, config), config2), { headers: __assign(__assign(__assign({}, ((_a = config === null || config === void 0 ? void 0 : config.headers) !== null && _a !== void 0 ? _a : {})), ((_b = config2 === null || config2 === void 0 ? void 0 : config2.headers) !== null && _b !== void 0 ? _b : {})), this.headers) })];
            });
        });
    };
    ;
    ClientAXIOS.prototype.onRequestError = function (err, args) {
    };
    ClientAXIOS.prototype.execPost = function (url, body, config) {
        return __awaiter(this, void 0, Promise, function () {
            var time1594, mergeConfig, args, cloneArgs, resp, error_1, _time1594;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        time1594 = new Date().getTime();
                        return [4 /*yield*/, this.mergeConfigs(this.defaultAxiosConfig, config)];
                    case 1:
                        mergeConfig = _a.sent();
                        args = clone({
                            url: url,
                            body: body,
                            mergeConfig: mergeConfig,
                            config: config,
                            defaultAxiosConfig: this.defaultAxiosConfig
                        });
                        cloneArgs = clone(args);
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, axios.post(args.url, args.body, mergeConfig)];
                    case 3:
                        resp = _a.sent();
                        try {
                            this.onRequestDone.emit("POST", url);
                        }
                        catch (error) {
                        }
                        return [2 /*return*/, resp];
                    case 4:
                        error_1 = _a.sent();
                        _time1594 = new Date().getTime();
                        this.onRequestError(error_1, __assign({ time: _time1594 - time1594 }, cloneArgs));
                        throw error_1;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ClientAXIOS.prototype.execPostSecure = function (url, body, config) {
        return __awaiter(this, void 0, Promise, function () {
            var args, _a, resp, error_2;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = clone;
                        _b = {
                            url: url,
                            body: body
                        };
                        return [4 /*yield*/, this.mergeConfigs(this.defaultAxiosConfig, config)];
                    case 1:
                        args = _a.apply(void 0, [(_b.config = _c.sent(),
                                _b)]);
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, axios.post(args.url, args.body, args.config)];
                    case 3:
                        resp = _c.sent();
                        return [2 /*return*/, resp];
                    case 4:
                        error_2 = _c.sent();
                        console.error('[execPostSecure]', error_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ClientAXIOS.prototype.execPut = function (url, body, config) {
        return __awaiter(this, void 0, Promise, function () {
            var resp, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _b = (_a = axios).put;
                        _c = [url, body];
                        return [4 /*yield*/, this.mergeConfigs(this.defaultAxiosConfig, config)];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 2:
                        resp = _d.sent();
                        this.onRequestDone.emit("PUT", url);
                        return [2 /*return*/, resp];
                }
            });
        });
    };
    ClientAXIOS.prototype.execGet = function (url, config) {
        return __awaiter(this, void 0, Promise, function () {
            var resp, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _b = (_a = axios).get;
                        _c = [url];
                        return [4 /*yield*/, this.mergeConfigs(this.defaultAxiosConfig, config)];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                    case 2:
                        resp = _d.sent();
                        this.onRequestDone.emit("GET", url);
                        return [2 /*return*/, resp];
                }
            });
        });
    };
    ClientAXIOS.prototype.execDelete = function (url, config, data) {
        if (data === void 0) { data = undefined; }
        return __awaiter(this, void 0, Promise, function () {
            var resp, _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = (_a = axios).delete;
                        _c = [url];
                        _d = [{}];
                        return [4 /*yield*/, this.mergeConfigs(this.defaultAxiosConfig, config)];
                    case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([__assign.apply(void 0, [__assign.apply(void 0, _d.concat([_e.sent()])), { data: data }])]))];
                    case 2:
                        resp = _e.sent();
                        this.onRequestDone.emit("DELETE", url);
                        return [2 /*return*/, resp];
                }
            });
        });
    };
    return ClientAXIOS;
}());
export { ClientAXIOS };
var ClientBearer = /** @class */ (function (_super) {
    __extends(ClientBearer, _super);
    function ClientBearer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ClientBearer.prototype, "defaultAxiosConfig", {
        get: function () {
            return {
                headers: {
                    Authorization: "Bearer ".concat(this.bearer)
                }
            };
        },
        enumerable: false,
        configurable: true
    });
    ClientBearer.prototype.mergeConfigs = function (config, config2) {
        return __awaiter(this, void 0, Promise, function () {
            var resp, baseConfig, finaleConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.bearer) return [3 /*break*/, 2];
                        if (!this.client_id || !this.client_secret || !this.token_url)
                            throw new Error('Configuration error: client_id, client_secret and token_url are required for ClientBearer');
                        return [4 /*yield*/, axios.post(this.token_url, "client_id=".concat(this.client_id, "&client_secret=").concat(this.client_secret, "&grant_type=client_credentials"), {
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                }
                            })];
                    case 1:
                        resp = _a.sent();
                        this.bearer = resp.data.access_token;
                        _a.label = 2;
                    case 2: return [4 /*yield*/, _super.prototype.mergeConfigs.call(this, config, config2)];
                    case 3:
                        baseConfig = _a.sent();
                        return [4 /*yield*/, _super.prototype.mergeConfigs.call(this, baseConfig, this.defaultAxiosConfig)];
                    case 4:
                        finaleConfig = _a.sent();
                        return [2 /*return*/, finaleConfig];
                }
            });
        });
    };
    return ClientBearer;
}(ClientAXIOS));
export { ClientBearer };

import { kpis } from "../../types";
// export class ref_ExternalData implements IRid, INamed, IUpsertInfo, IMediaModel {
//     "@rid"?: rid;
//     Active: boolean;
//     Key: string;
//     Support: ref_SupportsId;
//     BroadcastArea: ref_BroadcastAreasId;
//     Media: ref_MediaId;
//     Model: ref_ModelId;
//     Currency: ref_CurrenciesId;
//     Created_by?: UserId;
//     Created_at?: Date;
//     Updated_by?: UserId;
//     Updated_at?: Date;
//     Name: string;
//     Start: Date;
//     End: Date;
//     ModelProperties: { [key: string]: string | number };
//     KPIs: kpis = new kpis();
//     DataImported: { [key: string]: any }[];
//     Source?: string;
// }
var ref_ExternalData = /** @class */ (function () {
    function ref_ExternalData() {
        this.KPIs = new kpis();
    }
    return ref_ExternalData;
}());
export { ref_ExternalData };

import { GetCurrentLocale, Trad } from "trad-lib";
export var minDateMessage = new Date(2019, 0, 1);
export var maxYearsRangeMessage = 3;
export function GetFirstDayOfYear() {
    var today = new Date();
    var firstDay = new Date('2020-01-01');
    firstDay.setFullYear(today.getFullYear());
    return firstDay;
}
export function GetLastDayOfYear() {
    var today = new Date();
    var firstDay = new Date('2020-12-31');
    firstDay.setFullYear(today.getFullYear());
    return firstDay;
}
export function GetToday() {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}
export function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    // Return array of year and week number
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
}
export function getUniqueWeekNumbers(startDate, endDate) {
    var currentDate = new Date(startDate);
    var weekNumbers = new Set();
    // Assurez-vous d'inclure la semaine de la endDate
    weekNumbers.add(getWeekNumber(endDate));
    while (currentDate < endDate) {
        weekNumbers.add(getWeekNumber(currentDate));
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return Array.from(weekNumbers);
}
function getFirstDayWeek(date) {
    var jour = date.getDay(); // Récupérer le jour de la semaine (0 pour dimanche, 1 pour lundi, etc.)
    var diff = date.getDate() - jour + (jour === 0 ? -6 : 1); // Calculer la différence pour obtenir le premier jour de la semaine
    var premierJourSemaine = new Date(date); // Créer une copie de la date d'origine
    premierJourSemaine.setDate(diff); // Définir la date sur le premier jour de la semaine
    return premierJourSemaine;
}
export function getUniqueWeekYears(startDate, endDate) {
    var currentDate = new Date(startDate);
    var weekNumbers = new Set();
    // Generate Key composed of week number and the date of the first day of the week
    var generateKeyWeek = function (d) { var _a; return ((_a = getWeekNumber(d)) === null || _a === void 0 ? void 0 : _a.toString()) + "-" + FormatDate(getFirstDayWeek(d)); };
    // Assurez-vous d'inclure la semaine de la endDate
    weekNumbers.add(generateKeyWeek(endDate));
    while (currentDate < endDate) {
        weekNumbers.add(generateKeyWeek(currentDate));
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return Array.from(weekNumbers);
}
export function getSemesterNumber(d) {
    var month = d.getMonth();
    if (month < 6)
        return 0;
    return 1;
}
export function getTrimesterNumber(d) {
    var month = d.getMonth();
    if (month < 3)
        return 0;
    if (month < 6)
        return 1;
    return 2;
}
export function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(), diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}
export function DateNoZone(date) {
    if (!date || typeof date !== "string")
        return date;
    date = new Date(date);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}
export function DateZone(date) {
    if (!date || typeof date !== "string")
        return date;
    date = new Date(date);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
}
export function DateNoTime(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
var check = function (d) { return (['number', 'string'].includes(typeof d)) ? new Date(d) : d; };
export function IsIntersec(s1, e1, s2, e2, strict) {
    if (strict === void 0) { strict = false; }
    s1 = check(s1);
    e1 = check(e1);
    s2 = check(s2);
    e2 = check(e2);
    var s1Time = s1.getTime();
    var e1Time = e1.getTime();
    var s2Time = s2.getTime();
    var e2Time = e2.getTime();
    if (!strict)
        return e2Time >= s1Time && e1Time >= s2Time;
    return (e2Time > s1Time && e1Time >= s2Time) || (e2Time >= s1Time && e1Time > s2Time);
}
export function isBetween(date, start, end) {
    return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
}
export function AddDays(currentDate, days) {
    return new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + days));
}
export function GetDateRanges(start, end, split) {
    var current = new Date(start);
    end = new Date(end);
    var currentRange = { start: current, end: undefined };
    var getIndex = null;
    switch (split) {
        case "week":
            getIndex = function (d) { return getWeekNumber(d); };
            break;
        case "month":
            getIndex = function (d) { return d.getMonth(); };
            break;
        case "trimester":
            getIndex = function (d) { return getTrimesterNumber(d); };
            break;
        case "semester":
            getIndex = function (d) { return getSemesterNumber(d); };
            break;
        default:
            throw new Error("unknown split time: ".concat(split));
    }
    var currentIndex = getIndex(current);
    var ranges = [];
    while (current.getTime() < end.getTime()) {
        var previous = current;
        current = new Date(current.getTime() + 60 * 60 * 24 * 1000);
        var newIndex = getIndex(current);
        if (newIndex != currentIndex || current.getTime() >= end.getTime()) {
            currentRange.end = current.getTime() >= end.getTime() ? end : previous;
            ranges.push(currentRange);
            currentIndex = newIndex;
            currentRange = {
                start: current,
                end: undefined
            };
        }
    }
    return ranges;
}
export function FormatDate(inputFormat, includeTime) {
    if (includeTime === void 0) { includeTime = false; }
    // function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    var str = d.toLocaleDateString(GetCurrentLocale());
    if (includeTime) {
        str += ' ' + d.toLocaleTimeString(GetCurrentLocale());
    }
    return str;
}
// function format minutes:seconds
export function FormatDuration(durationInMs) {
    var duration = durationInMs / 1000;
    var minutes = Math.floor(duration / 60);
    var seconds = Math.floor(duration % 60);
    return "".concat(minutes, "min").concat(seconds);
}
export function GetNbDays(begin, end) {
    return Math.round((end.getTime() - begin.getTime()) / 1000 / 60 / 60 / 24);
}
export function getMonthName(month, short) {
    var name = Trad("month_".concat(Number(month) - 1));
    if (name.length > 5 && short) {
        if (name === "Juillet") {
            name = name.substring(0, 5);
        }
        else {
            name = name.substring(0, 3);
        }
    }
    return name;
}
export function setEndOfMonth(date) {
    date.setDate(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate());
    date.setHours(23, 59, 59, 999);
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GetFirstDayOfYear, GetLastDayOfYear } from "tools-lib";
import { extractSub } from "../../tools.bin";
import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";
var AdvertiserHierarchyFilter = /** @class */ (function () {
    function AdvertiserHierarchyFilter() {
        /* Hierarchie Annonceur */
        this.Group = undefined;
        this.AdvertiserGroup = undefined;
        this.Advertiser = undefined;
        this.Brand = undefined;
        this.Product = undefined;
    }
    AdvertiserHierarchyFilter.getFilters = function (data, defaultValue) {
        if (defaultValue === void 0) { defaultValue = undefined; }
        return extractSub(data !== null && data !== void 0 ? data : {}, Object.keys(new AdvertiserHierarchyFilter()), defaultValue);
    };
    return AdvertiserHierarchyFilter;
}());
export { AdvertiserHierarchyFilter };
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.Campaign = undefined;
        _this.Source = ["ADWONE"];
        /* Hierarchie Support */
        _this.Media = [];
        _this.Support = [];
        _this.AdvCompany_Com = [];
        _this.AdvCompany_Fin = [];
        _this.Format = [];
        _this.Placement = [];
        _this.BroadcastArea = [];
        _this.Currency = [];
        _this.Status = [];
        _this["ModelProperties.AdCreation"] = [];
        _this.Agency_Res = [];
        _this.Start = GetFirstDayOfYear();
        _this.End = GetLastDayOfYear();
        return _this;
    }
    Filter.getFilters = function (configAny) {
        var copy = new Filter();
        var obj = {};
        Object.keys(copy).forEach(function (key) {
            var value = configAny[key];
            if ((value === null || value === void 0 ? void 0 : value.length) > 0)
                obj[key] = configAny[key];
        });
        return obj;
    };
    return Filter;
}(AdvertiserHierarchyFilter));
export { Filter };
var AdvertiserHierarchy = /** @class */ (function () {
    function AdvertiserHierarchy() {
        this.AdvertiserGroup = undefined;
        this.Advertiser = undefined;
        this.Brand = undefined;
        this.Product = undefined;
        this.Campaign = undefined;
        this.Start = null;
        this.End = null;
    }
    return AdvertiserHierarchy;
}());
export { AdvertiserHierarchy };
var ref_FilterConfigurations = /** @class */ (function (_super) {
    __extends(ref_FilterConfigurations, _super);
    function ref_FilterConfigurations() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.Filters = new AdvertiserHierarchyFilter;
        return _this;
    }
    return ref_FilterConfigurations;
}(ref_ConfigurationsBase));
export { ref_FilterConfigurations };

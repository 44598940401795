var Element = /** @class */ (function () {
    function Element() {
    }
    return Element;
}());
export { Element };
var EstimatesResponse = /** @class */ (function () {
    function EstimatesResponse() {
    }
    return EstimatesResponse;
}());
export { EstimatesResponse };
export var MMStatus;
(function (MMStatus) {
    MMStatus["ClientValidated"] = "ClientValidated";
    MMStatus["AgencyValidated"] = "AgencyValidated";
    MMStatus["Canceled"] = "Canceled";
    MMStatus["Archived"] = "Archived";
    MMStatus["Valuation"] = "Valuation";
})(MMStatus || (MMStatus = {}));
export var MMContractStatus;
(function (MMContractStatus) {
    MMContractStatus["Mandataire"] = "Mandataire";
    MMContractStatus["Commissionnaire"] = "Commissionnaire";
})(MMContractStatus || (MMContractStatus = {}));
//Estimate MasterData
var mm_Estimates = /** @class */ (function () {
    function mm_Estimates() {
    }
    return mm_Estimates;
}());
export { mm_Estimates };
var MMEstimateFilter = /** @class */ (function () {
    function MMEstimateFilter() {
    }
    return MMEstimateFilter;
}());
export { MMEstimateFilter };
var PairEstimates = /** @class */ (function () {
    function PairEstimates() {
    }
    return PairEstimates;
}());
export { PairEstimates };

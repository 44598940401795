var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import qs from "qs";
import { ClientAXIOS } from "./axios.bin";
var UserKey = function () { var _a; return "User-" + ((_a = JSON.parse(localStorage.getItem('user'))) === null || _a === void 0 ? void 0 : _a["@rid"]); };
var ClientOAUT2 = /** @class */ (function (_super) {
    __extends(ClientOAUT2, _super);
    function ClientOAUT2(client_id, client_secret, urlBase, urlTokenBase, redirectBase, config) {
        var _this = this;
        var _a, _b, _c;
        _this = _super.call(this, urlBase, config === null || config === void 0 ? void 0 : config.client) || this;
        _this.client_id = client_id;
        _this.client_secret = client_secret;
        _this.urlTokenBase = urlTokenBase;
        _this.redirectBase = redirectBase;
        _this.oautConfig = __assign(__assign({ withCredentials: true }, ((_a = config === null || config === void 0 ? void 0 : config.oaut) !== null && _a !== void 0 ? _a : {})), { headers: __assign({ "Content-Type": "application/x-www-form-urlencoded" }, ((_c = (_b = config === null || config === void 0 ? void 0 : config.oaut) === null || _b === void 0 ? void 0 : _b.headers) !== null && _c !== void 0 ? _c : {})) });
        _this.initilize();
        return _this;
    }
    Object.defineProperty(ClientOAUT2.prototype, "urlToken", {
        get: function () {
            return typeof this.urlTokenBase === "function" ? this.urlTokenBase() : this.urlTokenBase;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClientOAUT2.prototype, "redirect_uri", {
        get: function () {
            return typeof this.redirectBase === "function" ? this.redirectBase() : this.redirectBase;
        },
        enumerable: false,
        configurable: true
    });
    ;
    ClientOAUT2.prototype.initilizeToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (typeof localStorage !== "undefined") {
                    this.access_token = localStorage.getItem(this.getStorageKey('access_token'));
                    this.refresh_token = localStorage.getItem(this.getStorageKey('refresh_token'));
                }
                return [2 /*return*/];
            });
        });
    };
    ClientOAUT2.prototype.updateToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (typeof localStorage !== "undefined") {
                    localStorage.setItem(this.getStorageKey("access_token"), this.access_token);
                    localStorage.setItem(this.getStorageKey("refresh_token"), this.refresh_token);
                }
                return [2 /*return*/];
            });
        });
    };
    ClientOAUT2.prototype.removeToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (typeof localStorage !== "undefined") {
                    localStorage.removeItem(this.getStorageKey('access_token'));
                    localStorage.removeItem(this.getStorageKey('refresh_token'));
                }
                return [2 /*return*/];
            });
        });
    };
    ClientOAUT2.prototype.initilize = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.initilizeToken()];
                    case 1:
                        _a.sent();
                        if (this.access_token)
                            this.setHeaderAuthorization();
                        return [2 /*return*/];
                }
            });
        });
    };
    ;
    ClientOAUT2.prototype.getStorageKey = function (key) {
        if (this.client_id === "hub-website") {
            return key;
        }
        return "".concat(UserKey(), "_").concat(this.client_id.slice(0, 5), "_").concat(key);
    };
    ClientOAUT2.prototype.logout = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.access_token = null;
                        this.refresh_token = null;
                        return [4 /*yield*/, this.removeToken()];
                    case 1:
                        _c.sent();
                        if ((_b = (_a = this.defaultAxiosConfig) === null || _a === void 0 ? void 0 : _a.headers) === null || _b === void 0 ? void 0 : _b["Authorization"]) {
                            delete this.defaultAxiosConfig.headers["Authorization"];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientOAUT2.prototype.setHeaderAuthorization = function () {
        if (!this.defaultAxiosConfig.headers) {
            this.defaultAxiosConfig.headers = {};
        }
        this.defaultAxiosConfig.headers["Authorization"] = "Bearer ".concat(this.access_token);
    };
    ClientOAUT2.prototype.getRefreshToken = function () {
        return __awaiter(this, void 0, Promise, function () {
            var body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = {
                            client_id: this.client_id,
                            client_secret: this.client_secret,
                            grant_type: "refresh_token",
                            refresh_token: this.refresh_token,
                        };
                        return [4 /*yield*/, _super.prototype.execPost.call(this, this.urlToken, qs.stringify(body), this.oautConfig)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, this.handleNewToken(resp)];
                }
            });
        });
    };
    ClientOAUT2.prototype.getToken = function (code, additionalBody) {
        return __awaiter(this, void 0, void 0, function () {
            var body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = __assign({ client_id: this.client_id, client_secret: this.client_secret, redirect_uri: this.redirect_uri, grant_type: "authorization_code", code: code }, (additionalBody !== null && additionalBody !== void 0 ? additionalBody : {}));
                        return [4 /*yield*/, _super.prototype.execPost.call(this, this.urlToken, qs.stringify(body), this.oautConfig)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, this.handleNewToken(resp)];
                }
            });
        });
    };
    ClientOAUT2.prototype.getTokenClientCredentials = function () {
        return __awaiter(this, void 0, Promise, function () {
            var body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = {
                            client_id: this.client_id,
                            client_secret: this.client_secret,
                            grant_type: "client_credentials",
                        };
                        return [4 /*yield*/, _super.prototype.execPost.call(this, this.urlToken, qs.stringify(body), this.oautConfig)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, this.handleNewToken(resp)];
                }
            });
        });
    };
    ClientOAUT2.prototype.handleNewToken = function (resp) {
        this.access_token = resp.data.access_token;
        this.refresh_token = resp.data.refresh_token;
        this.updateToken();
        this.setHeaderAuthorization();
        return resp;
    };
    ;
    ClientOAUT2.prototype.onGetRefreshTokenError = function (err, args) {
    };
    ClientOAUT2.prototype.onRequestError = function (err, args) {
    };
    ClientOAUT2.prototype.checkRefreshToken = function (req) {
        var _a, _b, _c, _d, _e, _f, _g;
        return __awaiter(this, void 0, void 0, function () {
            var resp, err_1;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        _h.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, req()];
                    case 1:
                        resp = _h.sent();
                        return [2 /*return*/, resp];
                    case 2:
                        err_1 = _h.sent();
                        console.log('err?.response?.data', (_a = err_1 === null || err_1 === void 0 ? void 0 : err_1.response) === null || _a === void 0 ? void 0 : _a.data);
                        if (((_c = (_b = err_1 === null || err_1 === void 0 ? void 0 : err_1.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) == "invalid_token" || ((_f = (_e = (_d = err_1 === null || err_1 === void 0 ? void 0 : err_1.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.error) === null || _f === void 0 ? void 0 : _f.message) == "Access token has been revoked") { //TODO
                            return [2 /*return*/, this.getRefreshToken()
                                    .then(function () { return req(); })];
                        }
                        //this.onRequestError(err);
                        console.log((_g = err_1 === null || err_1 === void 0 ? void 0 : err_1.response) === null || _g === void 0 ? void 0 : _g.data);
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ;
    ClientOAUT2.prototype.isAuth = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                if (!this.access_token) {
                    return [2 /*return*/, false];
                }
                return [2 /*return*/, true];
            });
        });
    };
    ClientOAUT2.prototype.execPost = function (url, body, config) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.checkRefreshToken(function () { return _super.prototype.execPost.call(_this, url, body, config); })];
            });
        });
    };
    ClientOAUT2.prototype.execPostBase = function (url, body, config) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, _super.prototype.execPostSecure.call(this, url, body, config)];
            });
        });
    };
    ClientOAUT2.prototype.execPut = function (url, body, config) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.checkRefreshToken(function () { return _super.prototype.execPut.call(_this, url, body, config); })];
            });
        });
    };
    ClientOAUT2.prototype.execGet = function (url, config) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.checkRefreshToken(function () { return _super.prototype.execGet.call(_this, url, config); })];
            });
        });
    };
    ClientOAUT2.prototype.execDelete = function (url, config, data) {
        if (data === void 0) { data = undefined; }
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.checkRefreshToken(function () { return _super.prototype.execDelete.call(_this, url, config, data); })];
            });
        });
    };
    return ClientOAUT2;
}(ClientAXIOS));
export { ClientOAUT2 };

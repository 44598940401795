export var db = {
    "Referentials": {
        "mandatory": [
            "Active"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ReferentialHasViews": {
        "mandatory": [],
        "optional": []
    },
    "Views": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "User": {
        "mandatory": [
            "name",
            "status",
            "password",
            "Active",
            "qualification"
        ],
        "optional": [
            "roles",
            "isDefaultPassword",
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_Advertisers": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_AdvertiserGroups": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_AdvertisingCompanies": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_AdvertisingCompanyGroups": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_AdvertisingCompanyRole": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": []
    },
    "ref_Agencies": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_AgencyGroups": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_AgencyRole": {
        "mandatory": [
            "Name"
        ],
        "optional": []
    },
    "ref_Brands": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_BroadcastAreas": {
        "mandatory": [
            "Active"
        ],
        "optional": [
            "Code",
            "Name",
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_Companies": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": []
    },
    "ref_Contacts": {
        "mandatory": [
            "Email"
        ],
        "optional": [
            "Phone",
            "Job"
        ]
    },
    "ref_Departments": {
        "mandatory": [
            "Active"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_Countries": {
        "mandatory": [
            "Name"
        ],
        "optional": []
    },
    "ref_Currencies": {
        "mandatory": [
            "Active",
            "Name",
            "Code"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_Customers": {
        "mandatory": [],
        "optional": [
            "Active",
            "Country",
            "Authentication",
            "Licences",
            "End",
            "Billing",
            "Start",
            "Type",
            "Company",
            "Authorization",
            "MasterDataKey",
            "MailsDomain",
            "Vpn",
            "Sources",
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_DiscountClasses": {
        "mandatory": [
            "Active",
            "Name",
            "Operator",
            "Rank",
            "DiscountType"
        ],
        "optional": [
            "Medias",
            "LastPosition"
        ]
    },
    "ref_DiscountTypes": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": []
    },
    "ref_KPIs": {
        "mandatory": [],
        "optional": [
            "Name"
        ]
    },
    "ref_Property": {
        "mandatory": [
            "Active"
        ],
        "optional": [
            "Medias",
            "BroadcastAreas",
            "AllBroadcastAreas",
            "Name",
            "PropertyType",
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_Media": {
        "mandatory": [
            "Active"
        ],
        "optional": [
            "Name",
            "Code",
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_Model": {
        "mandatory": [],
        "optional": [
            "MediaTypes",
            "DocumentType",
            "Description",
            "Country"
        ]
    },
    "ref_Modules": {
        "mandatory": [],
        "optional": [
            "Name"
        ]
    },
    "ref_Persons": {
        "mandatory": [
            "Active"
        ],
        "optional": [
            "FirstName",
            "LastName"
        ]
    },
    "ref_Products": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": [
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "ref_PropertyType": {
        "mandatory": [],
        "optional": [
            "Type"
        ]
    },
    "ref_Regional": {
        "mandatory": [
            "Active"
        ],
        "optional": [
            "Medias",
            "BroadcastAreas"
        ]
    },
    "ref_Sources": {
        "mandatory": [
            "URI"
        ],
        "optional": []
    },
    "ref_SourceTypes": {
        "mandatory": [],
        "optional": [
            "Name"
        ]
    },
    "ref_Supports": {
        "mandatory": [
            "Active",
            "Name"
        ],
        "optional": [
            "Covers",
            "Medias",
            "Logo",
            "Description",
            "Created_by",
            "Updated_by",
            "Created_at",
            "Updated_at"
        ]
    },
    "src_AdwOne": {
        "mandatory": [
            "URI"
        ],
        "optional": []
    },
    "src_CODIPRESSE": {
        "mandatory": [
            "URI"
        ],
        "optional": []
    },
    "src_MM": {
        "mandatory": [
            "URI"
        ],
        "optional": [
            "URI_Estimates",
            "URI_Financial",
            "MasterEndpoint",
            "URI_MAP"
        ]
    },
    "src_PIGE": {
        "mandatory": [
            "URI"
        ],
        "optional": []
    },
    "src_TSM": {
        "mandatory": [
            "URI"
        ],
        "optional": []
    },
    "codip_Couleur": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "codip_Diffusion": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "codip_Emplacement": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "codip_Encart": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "codip_Format": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "codip_Implantation": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "codip_Pagination": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "codip_Rubrique": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "mm_Advertisers": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "mm_AdvertiserGroups": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "mm_AdvertisingCompanies": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "mm_Agencies": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "mm_Brands": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "mm_Products": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "mm_Supports": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "mm_Users": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID",
            "Type"
        ]
    },
    "tsm_Couleur": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "tsm_Emplacement": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "tsm_Format": {
        "mandatory": [],
        "optional": [
            "Label",
            "ExternalID"
        ]
    },
    "lnk_AdvertisingCompanySupport": {
        "mandatory": [],
        "optional": [
            "in",
            "Roles",
            "End",
            "Start",
            "out",
            "Default"
        ]
    },
    "lnk_HasBroadcastArea": {
        "mandatory": [],
        "optional": [
            "out",
            "End",
            "Start",
            "in"
        ]
    },
    "lnk_HasCompany": {
        "mandatory": [
            "in",
            "Start",
            "out"
        ],
        "optional": [
            "Contact",
            "End",
            "Account"
        ]
    },
    "lnk_HasCurrency": {
        "mandatory": [
            "Start"
        ],
        "optional": [
            "End",
            "out",
            "in"
        ]
    },
    "lnk_HasKPI": {
        "mandatory": [
            "Name",
            "out",
            "in"
        ],
        "optional": [
            "ValueType",
            "Id",
            "ValueChoices",
            "KPI"
        ]
    },
    "lnk_HasPropertyType": {
        "mandatory": [],
        "optional": [
            "in",
            "out"
        ]
    },
    "lnk_Hierarchy": {
        "mandatory": [
            "Start"
        ],
        "optional": [
            "End",
            "out",
            "in"
        ]
    },
    "lnk_Mandate": {
        "mandatory": [
            "Start"
        ],
        "optional": [
            "End",
            "Media",
            "Roles",
            "out",
            "in"
        ]
    },
    "lnk_Prospect": {
        "mandatory": [
            "Start"
        ],
        "optional": [
            "End",
            "in",
            "out"
        ]
    },
    "vw_one_HasUser": {
        "mandatory": [],
        "optional": [
            "out",
            "in"
        ]
    },
    "vw_codip_HasCouleur": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_codip_HasDiffusion": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_codip_HasEmplacement": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_codip_HasEncart": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_codip_HasFormat": {
        "mandatory": [],
        "optional": [
            "out",
            "in",
            "Referential"
        ]
    },
    "vw_codip_HasImplantation": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_codip_HasPagination": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_codip_HasRubrique": {
        "mandatory": [],
        "optional": [
            "out",
            "in",
            "Referential"
        ]
    },
    "vw_mm_HasAdvertiser": {
        "mandatory": [],
        "optional": [
            "Referential",
            "in",
            "out"
        ]
    },
    "vw_mm_HasAdvertiserGroup": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_mm_HasAdvertisingCompanies": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_mm_HasBrand": {
        "mandatory": [],
        "optional": [
            "Referential",
            "out",
            "in"
        ]
    },
    "vw_mm_HasProduct": {
        "mandatory": [],
        "optional": [
            "in",
            "Referential",
            "out"
        ]
    },
    "vw_mm_HasSupport": {
        "mandatory": [],
        "optional": [
            "out",
            "in",
            "Referential"
        ]
    },
    "vw_mm_HasUser": {
        "mandatory": [],
        "optional": [
            "out",
            "in",
            "Referential"
        ]
    },
    "vw_tsm_HasCouleur": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_tsm_HasEmplacement": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    },
    "vw_tsm_HasFormat": {
        "mandatory": [],
        "optional": [
            "in",
            "out",
            "Referential"
        ]
    }
};

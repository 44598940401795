var _a;
import { createSlice } from '@reduxjs/toolkit';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_Property } from 'hub-lib/models/orientdb/ref_Property.bin';
import { GetToday } from 'tools-lib';
import { getLinkFromName } from '../components/layout/SuperAdmin/LinkManager/LinkManagerTools';
var initialState = { filtersReferential: { includeLinkedRef: true, includeUnlinkedRef: true, includeActiveRef: true, includeInactiveRef: false, searchRef: "", columnFilters: [] // searchRid: ""
    }, filtersView: { searchView: "",
        includeLinkedView: true, includeUnlinkedView: true, excludeInactiveView: true, filterViewUpdate: false, minDateViewUpdate: GetToday() }, selectedDimension: ref_Advertisers.name, selectedSource: undefined, selectedLink: undefined, selectedProptype: undefined, modalities: [], modalitiesViews: [], topModalitiesViews: [], filteredModalitiesViews: [], links: [], inId: "", outIds: [], outIdsAuto: [], loaders: { links: false, gridRef: false, gridView: false } };
export var linkMgrSlice = createSlice({ name: 'linkMgr', initialState: initialState, reducers: { select: function (state, action) { var _a = action.payload, selectedDimension = _a.selectedDimension, selectedSource = _a.selectedSource; /** Si la dimension ou la source ont changé */ /** Si la dimension ou la source ont changé */ if (selectedDimension && selectedDimension != state.selectedDimension || selectedSource && selectedSource != state.selectedSource) {
            Object.assign(state, action.payload);
            updateSelectedLink(state);
        } Object.assign(state, action.payload); }, setReferentialFilters: function (state, action) { Object.assign(state.filtersReferential, action.payload); }, setViewFilters: function (state, action) { Object.assign(state.filtersView, action.payload); }, setState: function (state, action) { var _a = action.payload, links = _a.links, selectedLink = _a.selectedLink; /** Si les links on été set, on tente de sélectionner un link */ /** Si les links on été set, on tente de sélectionner un link */ if (links && links != state.links && !selectedLink) {
            Object.assign(state, action.payload);
            updateSelectedLink(state);
        } Object.assign(state, action.payload); }, loadingGridRef: function (state, action) { state.loaders.gridRef = action.payload; }, loadingGridView: function (state, action) { state.loaders.gridView = action.payload; }, loadingLinks: function (state, action) { state.loaders.links = action.payload; } } }); // Action creators are generated for each case reducer function
export var select = (_a = linkMgrSlice.actions, _a.select), setReferentialFilters = _a.setReferentialFilters, setState = _a.setState, setViewFilters = _a.setViewFilters, loadingGridRef = _a.loadingGridRef, loadingGridView = _a.loadingGridView, loadingLinks = _a.loadingLinks;
export var linkMgrReducer = linkMgrSlice.reducer;
function updateSelectedLink(state) { if (state.selectedDimension && state.selectedSource) {
    if (state.selectedDimension != ref_Property.name) {
        var selectedLink = getLinkFromName(state.links, state.selectedDimension, state.selectedSource);
        state.selectedLink = selectedLink;
    }
} }

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ref_AdvertiserGroups } from "../../models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "../../models/orientdb/ref_Advertisers.bin";
import { ref_Property } from "../../models/orientdb/ref_Property.bin";
import { ref_Supports } from "../../models/orientdb/ref_Supports.bin";
import { eRights, RightManager } from "../../models/types/rights.bin";
var ref_GroupsOld = /** @class */ (function () {
    function ref_GroupsOld() {
    }
    return ref_GroupsOld;
}());
export { ref_GroupsOld };
export var eGroupCategories;
(function (eGroupCategories) {
    eGroupCategories["MediaFamily"] = "MediaFamily";
    eGroupCategories["SupportCategory"] = "SupportCategory";
    eGroupCategories["PlacementCategory"] = "PlacementCategory";
    eGroupCategories["Magasin"] = "Magasin";
})(eGroupCategories || (eGroupCategories = {}));
var ref_Groups = /** @class */ (function () {
    function ref_Groups() {
    }
    return ref_Groups;
}());
export { ref_Groups };
var MediaFamilyGroup = /** @class */ (function (_super) {
    __extends(MediaFamilyGroup, _super);
    function MediaFamilyGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.Category = eGroupCategories.MediaFamily;
        _this.Of = {
            Class: ref_Supports.name,
        };
        return _this;
    }
    return MediaFamilyGroup;
}(ref_Groups));
export { MediaFamilyGroup };
var SupportCategoryGroup = /** @class */ (function (_super) {
    __extends(SupportCategoryGroup, _super);
    function SupportCategoryGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.Category = eGroupCategories.SupportCategory;
        _this.Of = {
            Class: ref_Supports.name,
        };
        return _this;
    }
    return SupportCategoryGroup;
}(ref_Groups));
export { SupportCategoryGroup };
var PlacementCategoryGroup = /** @class */ (function (_super) {
    __extends(PlacementCategoryGroup, _super);
    function PlacementCategoryGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.Category = eGroupCategories.PlacementCategory;
        _this.Of = {
            Class: ref_Property.name,
            Type: "Emplacement",
        };
        return _this;
    }
    return PlacementCategoryGroup;
}(ref_Groups));
export { PlacementCategoryGroup };
var MagasinGroup = /** @class */ (function (_super) {
    __extends(MagasinGroup, _super);
    function MagasinGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.Category = eGroupCategories.Magasin;
        _this.Of = {
            Class: ref_Advertisers.name,
        };
        return _this;
    }
    return MagasinGroup;
}(ref_Groups));
export { MagasinGroup };
export var hierarchyAdvertiserGroups = {
    class: ref_AdvertiserGroups.name,
    params: {
        Active: true,
        deep: [ref_Advertisers.name],
        format: { children: "items" },
        properties: ["@class", "Name"]
    },
    filter: function (e) { return [ref_AdvertiserGroups.name, ref_Advertisers.name].includes(e["@class"]); },
};
export var hierarchyAdvertisers = {
    class: ref_Advertisers.name,
    params: {
        Active: true,
        properties: ["@class", "Name"],
    },
    filter: function (e) { return [ref_Advertisers.name].includes(e["@class"]); },
};
export var getHierarchy = function () {
    if (RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read)) {
        return hierarchyAdvertiserGroups;
    }
    else if (RightManager.hasRight(ref_Advertisers.name, eRights.read)) {
        return hierarchyAdvertisers;
    }
};
export var GroupClassList = [
    new MediaFamilyGroup(),
    //new SupportCategoryGroup(),
    new PlacementCategoryGroup(),
    /*{
        ...new MagasinGroup(),
        Hierarchy: hierarchyAdvertiserGroups,
    },*/
];

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { removeDiacritics } from "hub-lib/tools.bin";
var intervalTrads = undefined;
var tradNotFound = new Set();
/**
 * Configure les accès à la locale
 * @param _getter
 * @param _setter
 */
export var ConfigureInOut = function (_getter, _setter) {
    setter = _setter;
    getter = _getter;
};
var setter = undefined;
var getter = undefined;
/**
 * Accès à la locale courante
 */
export var GetCurrentLocale = function () {
    var _a;
    var locale = getter === null || getter === void 0 ? void 0 : getter();
    if (!locale && ((_a = process === null || process === void 0 ? void 0 : process.env) === null || _a === void 0 ? void 0 : _a.NODE_ENV) !== "test")
        console.log("!!! No local found !!!");
    var locales = ["fr-FR", "en-GB", "en-US", "engine"];
    if (!locales.includes(locale))
        locale = "fr-FR";
    return locale;
};
export function FormatPrice(value, currencyName, options) {
    var _a, _b, _c;
    var priceFormated = "";
    try {
        if (typeof value !== "number" && (isNaN(value) || value === null)) {
            if (typeof value === "string")
                return value;
            return (_b = (_a = value === null || value === void 0 ? void 0 : value.toString) === null || _a === void 0 ? void 0 : _a.call(value)) !== null && _b !== void 0 ? _b : "-";
        }
        var locale = GetCurrentLocale();
        priceFormated = new Intl.NumberFormat(locale, { style: 'currency', currency: currencyName }).format(value);
        if ((options === null || options === void 0 ? void 0 : options.useThousands) && value >= 1000) {
            var numberValue = value === null || value === void 0 ? void 0 : value.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            return priceFormated.replace(numberValue, (value / 1000).toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "K");
        }
    }
    catch (error) {
        console.error("Error formatting price", value, currencyName, error);
        return (_c = value === null || value === void 0 ? void 0 : value.toString()) !== null && _c !== void 0 ? _c : "-";
    }
    return priceFormated;
}
/**
 * Pour changer la locale courante
 * @param locale
 */
export var SetCurrentLocale = function (locale) {
    return setter === null || setter === void 0 ? void 0 : setter(locale);
};
/**
 * A utiliser dans adwone uniquement, fonction de transition de systeme
 * @param name
 */
export var TradClassName = function (name) {
    return Trad(name);
};
/**
 * Traduit un code statique
 * @param key
 */
export var Trad = function (key, capitalize) {
    if (capitalize === void 0) { capitalize = false; }
    if (GetCurrentLocale() == 'engine')
        return key;
    if (!key || typeof key != "string")
        return 'undefined';
    var toCapitalize = function (s) { return (s && s[0].toUpperCase() + s.slice(1)) || ""; };
    // Hack adwone, transition old key system
    if (key.startsWith("property_")) {
        var k = key.replace("property_", "");
        var trad_1 = TradProvider.GetInstance().Trad({ Type: "static", Property: k });
        if (!trad_1.startsWith("static/"))
            return capitalize ? toCapitalize(trad_1) : trad_1;
    }
    // Hack adwone, transition old key system
    if (key.startsWith("ref_") || key.startsWith("src_")) {
        var trad_2 = TradProvider.GetInstance().Trad({ Type: "static", Class: key });
        if (!trad_2.startsWith("static/"))
            return capitalize ? toCapitalize(trad_2) : trad_2;
    }
    var trad = TradProvider.GetInstance().Trad({ Type: "static", Code: key });
    return capitalize ? toCapitalize(trad) : trad;
};
export function TradComposed(key, values, capitalize) {
    if (capitalize === void 0) { capitalize = false; }
    var trad = Trad(key, capitalize);
    for (var index = 0; index < values.length; index++) {
        var element = values[index];
        trad = trad.replace("$" + index, element === null || element === void 0 ? void 0 : element.toString());
    }
    return trad;
}
export function FormatPropertyName(propertyName, shift) {
    if (shift === void 0) { shift = false; }
    if (GetCurrentLocale() != 'engine')
        return propertyName;
    var props = propertyName.split('.');
    if (shift)
        props.shift();
    return removeDiacritics(props.join("_").trim().split(" ").join("_"));
}
/**
 * Traduit le nom d'une propriété
 * @param prop
 * @param classType
 */
export function TradProp(prop, classType) {
    var key = undefined;
    if (classType)
        key = typeof classType == "string" ? classType : classType.name;
    if (GetCurrentLocale() == 'engine')
        return FormatPropertyName(prop);
    if (prop === "*")
        return "*";
    return TradProvider.GetInstance().Trad({ Type: "static", Class: key, Property: prop });
}
export function TradPropVName(prop, classType) {
    if (GetCurrentLocale() == 'engine')
        return FormatPropertyName(prop);
    if (prop === "*")
        return "*";
    return TradProvider.GetInstance().Trad({ Type: "static", Class: classType, Property: prop });
}
/**
 * Traduit une valeur, dynamic
 * @param classType
 * @param prop
 * @param value
 */
export function TradValue(classType, prop, value) {
    var _a;
    if (GetCurrentLocale() == 'engine')
        return value;
    var Class = typeof classType === "string" ? classType : classType === null || classType === void 0 ? void 0 : classType.name;
    return (_a = TradProvider.GetInstance().Trad({ Type: "dynamic", Class: Class, Property: prop, Value: value })) !== null && _a !== void 0 ? _a : value;
}
export var GetTimeFormat = function () {
    switch (GetCurrentLocale()) {
        case "fr-FR":
            return "HH:mm";
        case "en-US":
            return "hh:mm a";
        case "en-GB":
            return "HH:mm";
        default:
            return "HH:mm";
    }
};
export var GetMonthFormat = function () {
    switch (GetCurrentLocale()) {
        case "fr-FR":
            return "L";
        case "en-US":
            return "l";
        case "en-GB":
            return "L";
        default:
            return "L";
    }
};
var TradProvider = /** @class */ (function () {
    function TradProvider() {
        this.dicoTrads = {};
        this.errorInit = undefined;
        this.lastTradsUpdate = undefined;
    }
    TradProvider.prototype.GetCount = function () {
        var _a;
        return (_a = this === null || this === void 0 ? void 0 : this.allTrads) === null || _a === void 0 ? void 0 : _a.length;
    };
    TradProvider.GetInstance = function () {
        if (!TradProvider._provider)
            TradProvider._provider = new TradProvider();
        return TradProvider._provider;
    };
    TradProvider.GetCode = function (trad) {
        if (!trad)
            return null;
        if (!trad.Class && !trad.Property && !trad.Value)
            return trad.Code;
        trad.Code = "".concat(trad.Type, "/").concat(trad.Class, "/").concat(trad.Property);
        if (trad.Type === "dynamic")
            trad.Code += "/".concat(trad.Value);
        return trad.Code;
    };
    TradProvider.prototype.TradCode = function (code, nullIfNotFount) {
        var _a, _b;
        if (nullIfNotFount === void 0) { nullIfNotFount = false; }
        if (!code) {
            console.error("code undefined");
            return "undefined";
        }
        var locale = GetCurrentLocale();
        if (!locale) {
            console.error("mapping locale not found for locale: ".concat(locale));
            return code;
        }
        var trad = (_b = (_a = this.dicoTrads[code]) === null || _a === void 0 ? void 0 : _a.Translations) === null || _b === void 0 ? void 0 : _b[locale];
        if (!trad && !nullIfNotFount) {
            trad = code;
        }
        return trad;
    };
    TradProvider.prototype.Trad = function (trad) {
        var code = TradProvider.GetCode(trad);
        var value = this.TradCode(code, true);
        if (!trad) {
            console.error("trad undefined");
            return "undefined";
        }
        switch (trad.Type) {
            case "dynamic":
                if (!value)
                    value = trad.Value;
                break;
            case "static":
                if (value)
                    break;
                if (trad.Property) {
                    var codeProp = TradProvider.GetCode({ Type: "static", Property: trad.Property });
                    var tradProp = this.TradCode(codeProp, true);
                    if (tradProp) {
                        value = tradProp;
                        break;
                    }
                }
                break;
            default:
                console.error("trad with no type");
                console.error(trad);
                return "*";
        }
        if (!value && trad.Type == 'static' && !(code === null || code === void 0 ? void 0 : code.startsWith('[')))
            tradNotFound.add(code);
        if (!TradProvider.IsActivated()) {
            if (!value)
                console.log(Array.from(tradNotFound));
            return "[".concat(value == undefined ? '?' : '').concat(code, "]");
        }
        if (!value && trad.Type == 'dynamic')
            return '';
        return value !== null && value !== void 0 ? value : code;
    };
    TradProvider.prototype.Init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var getTrads, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (this.allTrads)
                            return [2 /*return*/];
                        getTrads = function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            var _this = this;
                            var _b, _c;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        _a = this;
                                        return [4 /*yield*/, TradProvider.TradGetter()];
                                    case 1:
                                        _a.allTrads = (_b = _d.sent()) !== null && _b !== void 0 ? _b : [];
                                        (_c = this.allTrads) === null || _c === void 0 ? void 0 : _c.forEach(function (t) {
                                            if (!(t === null || t === void 0 ? void 0 : t.Code))
                                                return;
                                            _this.dicoTrads[t.Code] = t;
                                        });
                                        this.lastTradsUpdate = new Date();
                                        return [2 /*return*/];
                                }
                            });
                        }); };
                        if (TradProvider.Timeout) {
                            clearInterval(intervalTrads);
                            intervalTrads = setInterval(getTrads, TradProvider.Timeout);
                        }
                        return [4 /*yield*/, getTrads()];
                    case 1:
                        _a.sent();
                        console.log("Translation init OK");
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error("Cannot Init trads", error_1);
                        this.errorInit = error_1 === null || error_1 === void 0 ? void 0 : error_1.message;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TradProvider.IsActivated = function () { return true; };
    return TradProvider;
}());
export { TradProvider };

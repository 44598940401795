var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { createSlice } from '@reduxjs/toolkit';
import { AdvertiserHierarchy } from 'hub-lib/dto/client/ref_FilterConfigurations.bin';
import { duplicate } from 'hub-lib/tools.bin';
var FilterAdvertiserReferentialTree = /** @class */ (function (_super) {
    __extends(FilterAdvertiserReferentialTree, _super);
    function FilterAdvertiserReferentialTree() {
        var _this = _super.apply(this, arguments) || this;
        _this.AdvertiserGroup = [];
        _this.Advertiser = [];
        _this.Brand = [];
        _this.Product = [];
        _this.Start = null;
        _this.End = null;
        _this.children = void 0;
        _this.Active = true;
        return _this;
    }
    return FilterAdvertiserReferentialTree;
}(AdvertiserHierarchy));
export { FilterAdvertiserReferentialTree };
var TreeProps = /** @class */ (function () {
    function TreeProps() {
        this.hierarchyClassName = void 0;
        this.filterName = void 0;
        this.children = void 0;
        this.extendedProperties = void 0;
    }
    return TreeProps;
}());
export { TreeProps };
var FilterReferentialTree = /** @class */ (function () {
    function FilterReferentialTree() {
        this["@rid"] = [];
        this.children = [];
        this.Start = null;
        this.End = null;
        this.Active = true;
    }
    return FilterReferentialTree;
}());
export { FilterReferentialTree };
var initialState = { advertiserFilter: duplicate(new FilterAdvertiserReferentialTree()), agencyFilter: duplicate(new FilterReferentialTree()), advertisingCompanyFilter: duplicate(new FilterReferentialTree()) };
export var referentialTreeSlice = createSlice({ name: 'referentialTree', initialState: initialState, reducers: { onChange: function (state, action) { var _a = action.payload, advertiserFilter = _a.advertiserFilter, agencyFilter = _a.agencyFilter, advertisingCompanyFilter = _a.advertisingCompanyFilter; if (advertiserFilter) {
            state.advertiserFilter = duplicate(advertiserFilter);
        } if (agencyFilter) {
            state.agencyFilter = duplicate(agencyFilter);
        } if (advertisingCompanyFilter) {
            state.advertisingCompanyFilter = duplicate(advertisingCompanyFilter);
        } } } }); // Action creators are generated for each case reducer function
export var onChange = referentialTreeSlice.actions.onChange;
export var referentialTreeReducer = referentialTreeSlice.reducer;

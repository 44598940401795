/**
 * Socket protocol
 */
export var eSocketMsg;
(function (eSocketMsg) {
    eSocketMsg["notification"] = "notification";
    eSocketMsg["command"] = "command";
    eSocketMsg["action"] = "action";
})(eSocketMsg || (eSocketMsg = {}));
export var eSocketCommand;
(function (eSocketCommand) {
    eSocketCommand["clearCacheSession"] = "clearCacheSession";
    eSocketCommand["progress"] = "progress";
    eSocketCommand["sendLocalstorage"] = "sendLocalstorage";
    eSocketCommand["logVersionClient"] = "logVersionClient";
})(eSocketCommand || (eSocketCommand = {}));

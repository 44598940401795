/**
 * Class to define the custom width of a column
 * Stored in the localStorage when user change the width of a column
 */ var CustomWidth = /** @class */ (function () {
    function CustomWidth() {
    }
    CustomWidth.getCustomWidths = function () { var customWidths = localStorage.getItem(CustomWidth.key); return customWidths ? JSON.parse(customWidths) : {}; };
    CustomWidth.setCustomWidths = function (customWidths) { localStorage.setItem(CustomWidth.key, JSON.stringify(customWidths !== null && customWidths !== void 0 ? customWidths : {})); };
    return CustomWidth;
}());
export { CustomWidth };
CustomWidth.key = "customWidths";
export function ConvertWidthToNumber(width) { if (typeof width == 'number')
    return width;
else if (typeof width == 'string' && width.endsWith('px'))
    return Number(width.replace('px', '')); return 0; }
export function GetColumnWidthNumber(col) { var res = 0; if (typeof col.width == 'number')
    res = col.width;
else if (typeof col.width == 'string' && col.width.endsWith('px'))
    res = Number(col.width.replace('px', '')); return res; }

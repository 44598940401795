import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
export function GetDiscountValue(discount, cofo) {
    var _baseValue$Value;
    if (!discount)
        return null; // CO, FO ou FOS
    var baseValue = DiscountManager.getModulation(discount, cofo);
    var value = ((_baseValue$Value = baseValue === null || baseValue === void 0 ? void 0 : baseValue.Value) !== null && _baseValue$Value !== void 0 ? _baseValue$Value : 0) * discount.Operator;
    if (value == -0)
        value = 0;
    return value;
}

export var eFileType;
(function (eFileType) {
    eFileType["ExcelTemplate"] = "ExcelTemplate";
})(eFileType || (eFileType = {}));
var FileDescriptor = /** @class */ (function () {
    function FileDescriptor() {
    }
    return FileDescriptor;
}());
export { FileDescriptor };

export function UrlParser(_paramName, defaultValue, char) {
    if (char === void 0) { char = "?"; }
    var location = window.location.href;
    var array = location.split(char);
    if (array.length > 1) {
        for (var idx = 1; idx < array.length; idx++) {
            var arrayAnd = array[idx].split("&");
            for (var _i = 0, arrayAnd_1 = arrayAnd; _i < arrayAnd_1.length; _i++) {
                var chunk = arrayAnd_1[_i];
                var res = chunk.split("=");
                if (res.length == 2) {
                    if (res[0] === _paramName)
                        return res[1];
                }
            }
        }
    }
    return defaultValue;
}

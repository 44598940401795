import { ref_Supports } from "../models/orientdb/ref_Supports.bin";
import { ref_Brands } from "../models/orientdb/ref_Brands.bin";
import { ref_Products } from "../models/orientdb/ref_Products.bin";
import { ref_AdvertiserGroups } from "../models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "../models/orientdb/ref_Advertisers.bin";
import { ref_Property } from "../models/orientdb/ref_Property.bin";
import { ref_KPIs } from "../models/orientdb/ref_KPIs.bin";
import { lnk_HasKPI } from "../models/orientdb/lnk_HasKPI.bin";
import { ref_Model } from "../models/orientdb/ref_Model.bin";
import { ref_Sources } from "../models/orientdb/ref_Sources.bin";
import { ref_SourceTypes } from "../models/orientdb/ref_SourceTypes.bin";
import { V } from "../models/orientdb/V.bin";
import { ref_DiscountClasses } from "../models/orientdb/ref_DiscountClasses.bin";
import { ref_Media } from "../models/orientdb/ref_Media.bin";
import { ref_DiscountTypes } from "../models/orientdb/ref_DiscountTypes.bin";
import { SupportExtended } from "../dto/referential/SupportExtended.bin";
import { ref_Currencies } from "../models/orientdb/ref_Currencies.bin";
import { ref_PropertyType } from "../models/orientdb/ref_PropertyType.bin";
import { vw_mm_HasDiscountClass } from "../models/orientdb/vw_mm_HasDiscountClass.bin";
import { lnk_HasPropertyType } from "../models/orientdb/lnk_HasPropertyType.bin";
import { ref_AdvertisingCompanies } from "../models/orientdb/ref_AdvertisingCompanies.bin";
var clientCacheClass = /** @class */ (function () {
    function clientCacheClass() {
        this.dicoCache = {};
        this.dicoTimeouts = {};
    }
    clientCacheClass.prototype.setTimeOut = function (vertexType, timeout) {
        this.dicoTimeouts[vertexType] = timeout;
    };
    clientCacheClass.prototype.getCache = function (vertexType, params) {
        if (!this.dicoTimeouts.hasOwnProperty(vertexType))
            return undefined;
        var key = vertexType + ":" + JSON.stringify(params);
        return this.dicoCache[key];
    };
    clientCacheClass.prototype.setCache = function (vertexType, params, value) {
        var _this = this;
        if (!this.dicoTimeouts.hasOwnProperty(vertexType))
            return undefined;
        var key = vertexType + ":" + JSON.stringify(params);
        this.dicoCache[key] = value;
        setTimeout(function () {
            delete _this.dicoCache[key];
        }, this.dicoTimeouts[vertexType]);
    };
    clientCacheClass.prototype.clearCache = function (vertexType) {
        this.dicoCache = {};
        //Object.keys(this.dicoCache)
        //.filter(k => k.startsWith(`${vertexType}:`))
        //.forEach(k => delete this.dicoCache[k])
    };
    return clientCacheClass;
}());
var h3 = 1000 * 60 * 60 * 3; // 3h
export var clientCache = new clientCacheClass;
clientCache.setTimeOut(SupportExtended.name, 600000); // 10min
clientCache.setTimeOut(ref_Supports.name, 600000); // 10min
clientCache.setTimeOut(ref_Currencies.name, 600000); // 10min
clientCache.setTimeOut(ref_Brands.name, h3);
clientCache.setTimeOut(ref_Products.name, h3);
clientCache.setTimeOut(ref_AdvertiserGroups.name, h3);
clientCache.setTimeOut(ref_Advertisers.name, h3);
clientCache.setTimeOut(ref_AdvertisingCompanies.name, h3);
clientCache.setTimeOut(ref_Property.name, 600000); // 10min
clientCache.setTimeOut(ref_KPIs.name, 600000); // 10min
clientCache.setTimeOut(lnk_HasKPI.name, 600000); // 10min
clientCache.setTimeOut(ref_Model.name, 600000); // 10min
clientCache.setTimeOut(ref_Sources.name, 600000); // 10min
clientCache.setTimeOut(ref_SourceTypes.name, 600000); // 10min
clientCache.setTimeOut(V.name, 600000); // 10min
clientCache.setTimeOut(ref_DiscountClasses.name, 3600000); // 1h
clientCache.setTimeOut(ref_DiscountTypes.name, 3600000); // 1h
clientCache.setTimeOut(ref_Media.name, 3600000); // 1h
clientCache.setTimeOut(ref_PropertyType.name, 3600000); // 1h
clientCache.setTimeOut(vw_mm_HasDiscountClass.name, 3600000); // 1h
clientCache.setTimeOut(lnk_HasPropertyType.name, 3600000); // 1h

export var DefaultDevURLServer = 'http://localhost:5000';
export var DefaultDevURLFront = 'http://localhost:3000';
export var DefaultProdURLServer = 'https://server.adwone.com';
export var AdwoneEnvironments = [
    {
        NODE_ENV: 'development',
        ADWONE_ENV: 'local',
        front: DefaultDevURLFront,
        back: DefaultDevURLServer
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'production-us',
        front: ['https://adwone.us', 'https://www.adwone.us'],
        back: 'https://server.adwone.us'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'uat.production-us',
        front: ['https://uat.adwone.us', 'https://www.uat.adwone.us'],
        back: 'https://server-uat.adwone.us'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'development',
        front: 'https://dev.adwone.com',
        back: 'https://server.dev.adwone.com'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'demo',
        front: 'https://demo.adwone.com',
        back: 'https://server.demo.adwone.com'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'uat',
        front: 'https://uat.adwone.com',
        back: 'https://server.uat.adwone.com'
    },
    {
        NODE_ENV: 'production',
        ADWONE_ENV: 'production',
        front: ['https://adwone.com', 'https://adwone.fr', 'https://www.adwone.com', 'https://www.adwone.fr'],
        back: DefaultProdURLServer
    }
];
export function GetServerUrlFromFrontUrl(frontUrl) {
    var _a, _b;
    return (_b = (_a = AdwoneEnvironments.find(function (item) {
        if (Array.isArray(item.front))
            return item.front.includes(frontUrl);
        return item.front === frontUrl;
    })) === null || _a === void 0 ? void 0 : _a.back) !== null && _b !== void 0 ? _b : DefaultDevURLServer;
}
export function GetServerUrlFromEnv(nodeEnv, adwoneEnv) {
    var _a, _b;
    var backUrl = (_a = AdwoneEnvironments.find(function (item) { return item.NODE_ENV === nodeEnv && item.ADWONE_ENV === adwoneEnv; })) === null || _a === void 0 ? void 0 : _a.back;
    return (_b = backUrl) !== null && _b !== void 0 ? _b : DefaultDevURLServer;
}
export function GetFrontUrlFromEnv(nodeEnv, adwoneEnv) {
    var _a, _b;
    var frontUrl = (_a = AdwoneEnvironments.find(function (item) { return item.NODE_ENV === nodeEnv && item.ADWONE_ENV === adwoneEnv; })) === null || _a === void 0 ? void 0 : _a.front;
    if (Array.isArray(frontUrl) && frontUrl.length > 0)
        return frontUrl[0];
    return (_b = frontUrl) !== null && _b !== void 0 ? _b : DefaultDevURLFront;
}

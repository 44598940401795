var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { extendDataItem, mapTree, removeItems } from "@progress/kendo-react-treelist";
import { createSlice } from "@reduxjs/toolkit";
import { Client } from "hub-lib/client/client.bin";
import { ref_Groups } from "hub-lib/dto/client/ref_Groups.bin";
import { eDialogMode } from "../components/ConfigurableComponents/GenericDialog.bin";
export var subItemsField = "Children";
export var editField = "inEdit";
export var expendedField = "Expanded";
var initialState = { fetchedDataType: undefined, fetchedData: [], fetchedDataAdvertiserGroups: [], fetchedDataAdvertisers: [], data: [], loaded: false, EditProps: null };
export var createNode = function (_ref) {
    var el = _ref.el, parent = _ref.parent, data = _ref.data, childrenKey = _ref.childrenKey;
    var id = new Date().getTime().toString();
    if (el !== null && el !== void 0 && el["@rid"]) {
        id = "".concat(el === null || el === void 0 ? void 0 : el["@rid"]).concat(parent ? "-" + parent : "");
    }
    return __assign(__assign({}, el), { Children: /*el?.[childrenKey]?.length
                    ? el[childrenKey].map((e: IRid) =>
                        createNode({
                            el: data.find((f) => e === f["@rid"]),
                            parent: id,
                            data,
                            childrenKey: childrenKey
                        })
                    )
                    :*/ [], Selected: false, Expanded: false, id: id, parent: parent });
};
function removeItem(state, el, idsField) { state.data = removeItems(idsField ? mapTree(state.data, subItemsField, function (item) {
    var _a;
    return item.id === el.parent ? __assign(__assign({}, item), (_a = {}, _a[idsField] = item[idsField].filter(function (e) { return e !== el["@rid"]; }), _a)) : item;
}) : state.data, subItemsField, function (i) { return i.id === el.id; }); }
export var treeSlice = createSlice({ name: "tree", initialState: initialState, /*extraReducers: (builder) => {
// Add reducers for additional action types here, and handle loading state as needed
builder.addCase(save.fulfilled, (state, action) => {
    state.data = action.payload.data;
    state.inEdit = action.payload.inEdit;
}),
    builder.addCase(save.rejected, (state, err) => {
        console.error(err);
    })
},*/ reducers: { setState: function (state, action) {
            Object.assign(state, action.payload);
        }, newItem: function (state, action) { state.EditProps = { Mode: eDialogMode.create, Item: action.payload }; }, editItem: function (state, action) { state.EditProps = { Mode: eDialogMode.modify, Item: action.payload }; },
        remove: function (state, action) { var toDelete = new Set([]); var toUpdate = new Set([]); var _a = action.payload, selected = _a.selected, idsField = _a.idsField; for (var _i = 0, selected_1 = selected; _i < selected_1.length; _i++) {
            var el = selected_1[_i];
            if (el.parent && !toUpdate.has(el["@rid"])) {
                toUpdate.add(el.parent);
            }
            else {
                toDelete.add(el["@rid"]);
            }
            removeItem(state, el, idsField);
        } if (toDelete.size) {
            toDelete.forEach(function (e) { toUpdate.delete(e); });
            Client.deleteVertex(ref_Groups.name, Array.from(toDelete));
        } if (toUpdate.size) {
            Client.updateVertex(ref_Groups.name, state.data.filter(function (e) { return toUpdate.has(e.id); }));
        } }, onItemChange: function (state, action) { var field = action.payload.field; state.data = mapTree(state.data, subItemsField, function (item) {
            var _a;
            return item.id === action.payload.id ? extendDataItem(item, subItemsField, (_a = {}, _a[field] = action.payload.value, _a)) : item;
        }); }, onExpandedChange: function (state, action) { state.data = mapTree(state.data, subItemsField, function (item) {
            var _a, _b;
            return action.payload.includes(item.id) ? __assign(__assign({}, item), (_a = {}, _a[expendedField] = true, _a)) : __assign(__assign({}, item), (_b = {}, _b[expendedField] = false, _b));
        }); }, clear: function (state, action) { Object.assign(state, __assign(__assign({}, initialState), { loaded: false, fetchedDataCategory: action.payload })); } /*updateIDs: (state, action: PayloadAction<{ id: string, Items?: DataTree[], Area?: DataTree[] }>) => {
            const newItem = {};
            if (action.payload.Area) {
                const advertiserGroupIds = action.payload.Area.filter((v) => v["@class"] == ref_AdvertiserGroups.name).map(g => g["@rid"]);
                const advertiserIds = action.payload.Area.filter((v) => v["@class"] == ref_Advertisers.name &&
                    (!v["parent"] || !advertiserGroupIds.some(g => g == v["parent"][0]))).map(a => a["@rid"]);

                console.log("Filtered", advertiserGroupIds, advertiserIds);

                newItem[propertyOf<ref_Groups>("Area")] = [{
                    Class: ref_AdvertiserGroups.name,
                    IDs: advertiserGroupIds
                }, {
                    Class: ref_Advertisers.name,
                    IDs: advertiserIds
                }]

            } else if (action.payload.Items) {
                newItem[propertyOf<ref_Groups>("IDs")] = action.payload.Items.map(e => e["@rid"]);
                newItem[subItemsField] = action.payload.Items.map(el => createNode({ el, data: state.fetchedData, parent: action.payload.id }));
            }
            console.log("newItem", newItem);
            state.data = mapTree(state.data, subItemsField, (item) =>
                item.id === action.payload.id
                    ? extendDataItem(item, subItemsField, newItem)
                    : item
            );
        }
    },*/
    } }); // Action creators are generated for each case reducer function
export var setState = (_a = treeSlice.actions, _a.setState), /* enterEdit, cancel, addChild,*/ remove = _a.remove, onItemChange = _a.onItemChange, onExpandedChange = _a.onExpandedChange, newItem = _a.newItem, editItem = _a.editItem, clear = _a.clear /*addDuplicate, clear, updateIDs*/;
export var treeReducer = treeSlice.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { Client } from "hub-lib/client/client.bin";
import { ref_Model } from "hub-lib/models/orientdb/ref_Model.bin";
import { ref_Offers } from "hub-lib/dto/client/ref_Offers.bin";
import { hasOwnProperty, propertyOf } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { Notify } from "./Notify.bin";
export function GetMessageOffers(message, additionalParams) {
    return __awaiter(this, void 0, void 0, function () {
        var offers, _await$Client$searchV, _await$Client$searchV2, _await$Client$searchV3, _await$Client$searchV4, _await$Client$searchV5, model;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    offers = [];
                    if (!(message.BroadcastArea && message.Media && message.Start && message.Support)) return [3 /*break*/, 3];
                    return [4 /*yield*/, Client.searchVertex(ref_Model.name, (_a = {}, _a[propertyOf("DocumentType")] = "Offer", _a[propertyOf("Country")] = message.BroadcastArea, _a[propertyOf("MediaTypes")] = [message.Media], _a))];
                case 1:
                    model = (_await$Client$searchV = _b.sent()) === null || _await$Client$searchV === void 0 ? void 0 : (_await$Client$searchV2 = _await$Client$searchV.data) === null || _await$Client$searchV2 === void 0 ? void 0 : (_await$Client$searchV3 = _await$Client$searchV2.results) === null || _await$Client$searchV3 === void 0 ? void 0 : _await$Client$searchV3[0];
                    if (!model) return [3 /*break*/, 3];
                    return [4 /*yield*/, Client.searchVertex(ref_Offers.name, __assign({ Support: message.Support, BroadcastArea: message.BroadcastArea, Media: message.Media, Model: model["@rid"], Start: message.Start, End: message.End }, additionalParams))];
                case 2:
                    offers = (_await$Client$searchV4 = _b.sent()) === null || _await$Client$searchV4 === void 0 ? void 0 : (_await$Client$searchV5 = _await$Client$searchV4.data) === null || _await$Client$searchV5 === void 0 ? void 0 : _await$Client$searchV5.results;
                    _b.label = 3;
                case 3: return [2 /*return*/, offers];
            }
        });
    });
}
export function SetPriceOffer(message, campaign, offer, propertyName) {
    return __awaiter(this, arguments, void 0, function () {
        var notify, computatedCascade, changed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    notify = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
                    computatedCascade = null;
                    if (message.PricingLocked)
                        return [2 /*return*/];
                    if (!offer) return [3 /*break*/, 2];
                    changed = message.KPIs.GrossVal != offer.KPIs.Gross;
                    message.KPIs.GrossVal = offer.KPIs.Gross;
                    if (propertyName == "Format" || message.PaidPlacement) {
                        changed = changed || message.KPIs.Gross != offer.KPIs.Gross;
                        changed = changed || message.KPIs.GrossBa != offer.KPIs.Gross;
                        message.KPIs.Gross = offer.KPIs.Gross;
                        message.KPIs.GrossBa = offer.KPIs.Gross;
                    }
                    message.KPIs.NetCO = offer.KPIs.Gross;
                    message.KPIs.NetFO = offer.KPIs.Gross;
                    message.KPIs.NetFOS = offer.KPIs.Gross;
                    message.KPIs.TotalCO = offer.KPIs.Gross;
                    message.KPIs.TotalFO = offer.KPIs.Gross;
                    message.KPIs.TotalFOS = offer.KPIs.Gross; // if (hasOwnProperty(message.ModelProperties, "BudgetCommitment"))
                    //     delete message.ModelProperties.BudgetCommitment;
                    if (hasOwnProperty(offer.ModelProperties, "BudgetCommitment") && typeof offer.ModelProperties.BudgetCommitment == "number")
                        message.ModelProperties.BudgetCommitment = offer.ModelProperties.BudgetCommitment; // if (!message.ModelProperties.OjdYear && hasOwnProperty(offer.ModelProperties, "Diffusion")) {
                    return [4 /*yield*/, DiscountManager.UpdateCascade(message, campaign)];
                case 1:
                    //     message.KPIs['TotalCirculation'] = offer.ModelProperties.Diffusion;
                    //     message.KPIs["Release"] = 0;
                    //     message.KPIs["PaidCirculation"] = 0;
                    //     message.KPIs["Subscriptions"] = 0;
                    // }
                    computatedCascade = _a.sent();
                    if (notify && changed)
                        Notify(Trad("price_has_been_updated"), "info");
                    _a.label = 2;
                case 2: return [2 /*return*/, computatedCascade];
            }
        });
    });
}
export function SetBroadcastPriceOffer(message, offer) {
    return __awaiter(this, arguments, void 0, function () { var notify, changed; return __generator(this, function (_a) {
        notify = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
        if (offer) {
            changed = message.KPIs.BroadcastGross != offer.KPIs.Gross;
            message.KPIs.BroadcastGross = offer.KPIs.Gross;
            if (notify && changed)
                Notify(Trad("price_has_been_updated"), "info");
        }
        return [2 /*return*/];
    }); });
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * Mongo
 */
var ref_DiscountBase = /** @class */ (function () {
    function ref_DiscountBase() {
    }
    return ref_DiscountBase;
}());
export { ref_DiscountBase };
/**
 * Mongo
 */
var ref_Discount = /** @class */ (function (_super) {
    __extends(ref_Discount, _super);
    function ref_Discount() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.LastPosition = false;
        return _this;
    }
    return ref_Discount;
}(ref_DiscountBase));
export { ref_Discount };
var ref_Intervals = /** @class */ (function (_super) {
    __extends(ref_Intervals, _super);
    function ref_Intervals() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ref_Intervals;
}(ref_DiscountBase));
export { ref_Intervals };
var Base = /** @class */ (function () {
    function Base() {
        this.Operator = 1;
    }
    return Base;
}());
export { Base };

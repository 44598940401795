var _a;
export var eRoles;
(function (eRoles) {
    eRoles["superAdministrateur"] = "super-administrateur";
    eRoles["administrateur"] = "administrateur";
    eRoles["adwoneAuth"] = "adwoneAuth";
    eRoles["manager"] = "manager";
    eRoles["superviseur"] = "superviseur";
    eRoles["dataManager"] = "dataManager";
})(eRoles || (eRoles = {}));
export var maskRole = (_a = {},
    _a[eRoles.superAdministrateur] = 1 << 0,
    _a[eRoles.administrateur] = 1 << 1,
    _a[eRoles.adwoneAuth] = 1 << 2,
    _a[eRoles.manager] = 1 << 3,
    _a[eRoles.superviseur] = 1 << 4,
    _a[eRoles.dataManager] = 1 << 5,
    _a);
export function HasAccess(mask, operators) {
    var ops = Array.isArray(operators) ? operators : [operators];
    for (var _i = 0, ops_1 = ops; _i < ops_1.length; _i++) {
        var op = ops_1[_i];
        if (typeof op !== 'string') {
            // if one and has no access, then return false
            if (op.and) {
                if (op.and.some(function (a) { return !HasAccess(mask, a); }))
                    return false;
            }
            // if one Or has access, then return true
            if (op.or) {
                if (!op.or.some(function (a) { return HasAccess(mask, a); }))
                    return false;
            }
        }
        else {
            // Then consider others as And operators
            if (((maskRole[op] || 0) & mask) == 0)
                return false;
        }
    }
    return true;
}

import { Client } from "hub-lib/client/client.bin";
import { ConfigureInOut, TradProvider } from "trad-lib";
var getterLocale = function () {
    var _navigator;
    var storedLocale = localStorage.getItem('currentLocal'); //console.log("[storedLocale]", storedLocale);
    if (storedLocale)
        return storedLocale;
    var browserLocale = (_navigator = navigator) === null || _navigator === void 0 ? void 0 : _navigator.language; //console.log("[browserLocale]", browserLocale);
    if (browserLocale)
        return browserLocale;
    return "fr-FR";
};
var setterLocale = function (locale) { localStorage.setItem('currentLocal', locale); document.getElementsByTagName("html")[0].setAttribute("lang", locale); return Client.SetLocale(locale).catch(function (err) { return console.error(err); }); };
export function RunInitLocale() { TradProvider.IsActivated = function () { return localStorage.getItem("devTrad") != "1"; }; return ConfigureInOut(getterLocale, setterLocale); }
RunInitLocale();

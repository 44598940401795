export var eCompare;
(function (eCompare) {
    eCompare["LowerEqual"] = "<=";
    eCompare["UpperEqual"] = ">=";
    eCompare["Lower"] = "<";
    eCompare["Upper"] = ">";
    eCompare["Contains"] = "contains";
    eCompare["ContainsAll"] = "containsall";
    eCompare["ContainsAny"] = "containsAny";
    eCompare["ContainsValue"] = "containsValue";
    eCompare["ContainsKey"] = "containsKey";
    eCompare["ContainsText"] = "containsText";
    eCompare["notContainsIn"] = "not in";
    eCompare["ContainsIn"] = "in";
    eCompare["Equals"] = "=";
    eCompare["NotEquals"] = "!=";
    eCompare["Is"] = "is";
    eCompare["IsNot"] = "is not";
})(eCompare || (eCompare = {}));
var Operator = /** @class */ (function () {
    function Operator() {
    }
    return Operator;
}());
export { Operator };

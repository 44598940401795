var _a;
import { ePropType } from "hub-lib/models/VertexProperty.bin"; // NOT SET
/*
    String,
    Binary,
    Embedded,
    EmbeddedList,
    EmbeddedSet,
    EmbeddedMap,
    Link,
    LinkList,
    LinkSet,
    LinkMap,
    Transient,
    Custom,
    LinkBag,
    Any
*/ var dicoFilter = (_a = {}, _a[ePropType.Boolean] = 'boolean', _a[ePropType.Integer] = 'numeric', _a[ePropType.Short] = 'numeric', _a[ePropType.Long] = 'numeric', _a[ePropType.Float] = 'numeric', _a[ePropType.Double] = 'numeric', _a[ePropType.Byte] = 'numeric', _a[ePropType.Decimal] = 'numeric', _a[ePropType.Datetime] = 'date', _a[ePropType.Date] = 'date', _a);
export function GetColumnType(type) { var value = dicoFilter[type]; if (!value)
    value = 'text'; return value; }

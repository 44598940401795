/**
 * For log management request
 */
var logArg = /** @class */ (function () {
    function logArg() {
    }
    return logArg;
}());
export { logArg };
export var eAction;
(function (eAction) {
    eAction["Create"] = "create";
    eAction["Read"] = "read";
    eAction["Update"] = "update";
    eAction["Delete"] = "delete";
})(eAction || (eAction = {}));
/**
 * Log user actions
 */
var logUserArg = /** @class */ (function () {
    function logUserArg() {
        this.Status = "success";
        this.details = 0;
        this.formatVersion = 1;
    }
    return logUserArg;
}());
export { logUserArg };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { eIndicateurType } from "adwone-engine/index.bin";
import { eKPIType } from "../models/KPIsManager.bin";
import { Trad, TradClassName, TradProp } from "trad-lib";
import { Typed, propertiesOf } from "../tools.bin";
import { eColumnType } from "../models/types.bin";
import { ref_AdvertisingCompanyGroups } from "../models/orientdb/ref_AdvertisingCompanyGroups.bin";
import { ePropType } from "../models/VertexProperty.bin";
import { ref_Messages } from "../dto/client/ref_Messages.bin";
import { clnt_Referentials } from "../dto/client/clnt_Referentials";
export function GetAdCreationII() {
    return Typed({
        type: eIndicateurType.info,
        name: TradProp('ModelProperties.AdCreation', ref_Messages),
        field: 'ModelProperties.AdCreation',
        valueType: eKPIType.Rid,
        options: {
            priorityToField: 'ModelProperties.AdCreationOther'
        }
    });
}
export function GetPlacementCategoryII() {
    return Typed({
        type: eIndicateurType.info,
        name: TradProp('ModelProperties.PlacementCategory', ref_Messages),
        field: 'ModelProperties.PlacementCategory',
        valueType: eKPIType.Rid,
        options: {
            priorityToField: 'ModelProperties.PlacementCategoryOther'
        }
    });
}
export function GetEstimateIIs() {
    return [
        Typed({
            type: eIndicateurType.info,
            name: TradProp('Deversement.Estimate', ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Code"
            }
        }),
        Typed({
            type: eIndicateurType.info,
            name: TradProp('Deversement.EstimateStatus', ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Status",
                formater: {
                    type: "trad"
                }
            }
        }),
        Typed({
            type: eIndicateurType.info,
            name: TradProp('Deversement.EstimateId', ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: 'Import.ExternalID',
                subPropertyFallback: "@rid",
            }
        }),
        Typed({
            type: eIndicateurType.info,
            name: TradProp('Deversement.EstimateLib', ref_Messages),
            field: 'Deversement.Estimate',
            valueType: eKPIType.Rid,
            options: {
                subProperty: 'Lib'
            }
        })
    ];
}
export function GetDuplicatesIO() {
    return Typed({
        indicateur: Typed({
            field: "Duplicates",
            name: "".concat(Trad("duplicates")),
            valueType: eKPIType.String,
            type: eIndicateurType.join,
            indicateurs: [
                Typed({
                    name: Trad("start"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Date,
                    field: "Start",
                    options: { value: { type: "moment" } }
                }),
                Typed({
                    name: TradProp("Support"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Rid,
                    field: "Support",
                }),
                Typed({
                    name: TradProp("BroadcastArea"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Rid,
                    field: "BroadcastArea",
                }),
                Typed({
                    name: TradProp("Currency"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Rid,
                    field: "Currency",
                }),
                Typed({
                    name: TradProp("AdvertiserGroup"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Rid,
                    field: "AdvertiserGroup",
                }),
            ],
            options: { separator: " - " }
        }),
        columnType: eColumnType.Property
    });
}
export function GetFiscalMonthIO() {
    var getFiscalMonthField_OI = function (field) {
        return Typed({
            type: eIndicateurType.info,
            name: Trad('fiscal_month'),
            field: field,
            valueType: eKPIType.Rid,
            options: {
                subProperty: 'ClntData.ModelProperties.fiscal_month',
                MetaData: {
                    linkedClass: clnt_Referentials.name,
                    name: field + "_fiscal_month",
                    type: ePropType.Link
                }
            }
        });
    };
    return Typed({
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad('fiscal_month'),
            valueType: eKPIType.String,
            operator: "||",
            type: eIndicateurType.computed,
            options: {
                formater: {
                    type: "trad",
                    prefixe: "month_",
                    fallback: "0"
                }
            },
            indicateurs: [getFiscalMonthField_OI('Advertiser'), getFiscalMonthField_OI('AdvertiserGroup')]
        })
    });
}
export function GetAdvCompanyGroupIOs() {
    var AdvCompanyGroupComIO = Typed({
        columnType: eColumnType.Property,
        indicateur: Typed({
            type: eIndicateurType.info,
            name: "".concat(TradClassName(ref_AdvertisingCompanyGroups.name), " Commercial"),
            field: "AdvCompany_Com.first(out('lnk_Hierarchy'))",
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Name",
                MetaData: {
                    linkedClass: ref_AdvertisingCompanyGroups.name,
                    name: "AdvCompanyGroup_Com",
                    type: ePropType.Link
                }
            }
        })
    });
    var AdvCompanyGroupFinIO = Typed({
        columnType: eColumnType.Property,
        indicateur: Typed({
            type: eIndicateurType.info,
            name: "".concat(TradClassName(ref_AdvertisingCompanyGroups.name), " Financier"),
            field: "AdvCompany_Fin.first(out('lnk_Hierarchy'))",
            valueType: eKPIType.Rid,
            options: {
                subProperty: "Name",
                MetaData: {
                    linkedClass: ref_AdvertisingCompanyGroups.name,
                    name: "AdvCompanyGroup_Fin",
                    type: ePropType.Link
                }
            }
        })
    });
    return [AdvCompanyGroupComIO, AdvCompanyGroupFinIO];
}
export function GetCampaignKPI_IOs(kpi, options) {
    var _a, _b;
    var valueType = (_a = options === null || options === void 0 ? void 0 : options.valueType) !== null && _a !== void 0 ? _a : eKPIType.Decimal;
    return Typed({
        columnType: eColumnType.KPI,
        indicateur: Typed({
            type: eIndicateurType.kpi,
            name: Trad(kpi),
            field: "Campaign",
            valueType: valueType,
            options: __assign(__assign({ subProperty: "KPIs.".concat(kpi) }, ((_b = options === null || options === void 0 ? void 0 : options.options) !== null && _b !== void 0 ? _b : {})), (valueType == eKPIType.Price ? ({ subPropertyDependencies: propertiesOf('Currency') }) : {}))
        })
    });
}
export function GetNumberElementsIO() {
    return {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("number_elements"),
            valueType: eKPIType.Number,
            type: eIndicateurType.kpi,
            options: { rid: undefined, forceValue: 1 }
        })
    };
}
export function GetCOFOIndicateurDiscounts(discountClasses, discountIOs, className) {
    var classRid = discountClasses.find(function (t) { return t.Name === className; })["@rid"];
    var ioCO = discountIOs.filter(function (p) { return p.columnType == eColumnType.DiscountValue; })
        .map(function (p) { return p.indicateur; })
        .find(function (p) { var _a; return ((_a = p.options) === null || _a === void 0 ? void 0 : _a.rid) == classRid; });
    var ioFO = discountIOs.filter(function (p) { return p.columnType == eColumnType.DiscountFOValue; })
        .map(function (p) { return p.indicateur; })
        .find(function (p) { var _a; return ((_a = p.options) === null || _a === void 0 ? void 0 : _a.rid) == classRid; });
    return { CO: ioCO, FO: ioFO };
}
export function GetComputedIO(type, name, operator, ios) {
    return Typed({
        indicateur: Typed({
            name: Trad(name),
            valueType: type,
            operator: operator,
            type: eIndicateurType.computed,
            indicateurs: ios
        }),
        columnType: eColumnType.KPI,
        tradKey: name
    });
}
export function GetComputedKpiPriceIO(kpiName, lnkKpi, name, operator, ios) {
    return GetComputedIO(eKPIType.Price, name, operator, __spreadArray([
        Typed({
            name: kpiName,
            valueType: eKPIType.Price,
            type: eIndicateurType.kpi,
            field: lnkKpi.Id,
            options: { rid: lnkKpi.KPI }
        })
    ], ios, true));
}
export function GetIndicateurKPIName(name, kpiType) {
    switch (kpiType) {
        case eKPIType.PriceBound:
            return Trad(name) + " ".concat(Trad("bound"));
        case eKPIType.PriceReturned:
            return Trad(name) + " ".concat(Trad("returned"));
        default:
            return Trad(name);
    }
}
export function GetTimeIOs() {
    /** Colonne Start sous format JJ */
    var DayIO = {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("day_dd"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "DD" } }
        })
    };
    /** Colonne End sous format JJ */
    var DayEndIO = {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("day_dd_end"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "End",
            options: { formater: { type: "moment", format: "DD" } }
        })
    };
    /** Colonne Mois sous format MM */
    var MonthIO = {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("day_m"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "MM" } }
        })
    };
    /** Colonne Mois sous format MMMM */
    var NamedMonthIO = {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("named_month"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "MMMM" } }
        })
    };
    /** Colonne Année sous format YY */
    var YearIO = {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("year"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "YYYY" } }
        })
    };
    /** Colonne Semaine sous format ww */
    var NamedWeekIO = {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("named_week"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "WW" } }
        })
    };
    /** Colonne Semaine sous format ww */
    var DatedWeekIO = {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("dated_week"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", periodicity: "datedWeek" } }
        })
    };
    /** Colonne sous format trimestre */
    var TrimesterIO = {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("trimester"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", format: "[T]Q" } }
        })
    };
    /** Colonne sous format trimestre */
    var SemesterIO = {
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("semester"),
            type: eIndicateurType.info,
            valueType: eKPIType.String,
            field: "Start",
            options: { formater: { type: "moment", periodicity: "semester" } }
        })
    };
    /** Colonne Nombre de jours */
    var NbDaysIO = Typed({
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("number_of_days"),
            valueType: eKPIType.Number,
            operator: "-",
            type: eIndicateurType.computed,
            options: { rate: 1 / (1000 * 60 * 60 * 24), round: 'ceil', baseValue: 1000 * 60 * 60 * 24 },
            indicateurs: [
                Typed({
                    name: Trad("end"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Number,
                    field: "End",
                    options: { value: { type: "moment" } }
                }),
                Typed({
                    name: Trad("start"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Number,
                    field: "Start",
                    options: { value: { type: "moment" } }
                })
            ]
        }),
    });
    /** Colonne Nombre de semaines */
    var NbWeeksIO = Typed({
        columnType: eColumnType.Property,
        indicateur: Typed({
            name: Trad("number_of_weeks"),
            valueType: eKPIType.Number,
            operator: "-",
            type: eIndicateurType.computed,
            options: { rate: 1 / (1000 * 60 * 60 * 24 * 7), round: 'ceil', baseValue: 1000 * 60 * 60 * 24 },
            indicateurs: [
                Typed({
                    name: Trad("end"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Number,
                    field: "End",
                    options: { value: { type: "moment" } }
                }),
                Typed({
                    name: Trad("start"),
                    type: eIndicateurType.info,
                    valueType: eKPIType.Number,
                    field: "Start",
                    options: { value: { type: "moment" } }
                })
            ]
        })
    });
    return [DayIO, DayEndIO, YearIO, MonthIO, NamedMonthIO, NamedWeekIO, DatedWeekIO, TrimesterIO, SemesterIO, NbDaysIO, NbWeeksIO];
}

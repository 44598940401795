var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Client } from "hub-lib/client/client.bin";
var PropertiesProvider = /** @class */ (function () {
    function PropertiesProvider(configurationProvider) {
        this.configurationProvider = void 0;
        this.configurationProvider = configurationProvider;
    }
    PropertiesProvider.prototype.Provide = function (type) {
        return __awaiter(this, void 0, void 0, function () { var _properties, properties, configuration, _configuration$Column, _configuration$Column2, _configuration$Column3; return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Client.getMetadata(type, false)];
                case 1:
                    properties = (_a.sent()).data.results;
                    configuration = this.configurationProvider();
                    if (configuration) {
                        properties = configuration === null || configuration === void 0 ? void 0 : (_configuration$Column = configuration.Columns) === null || _configuration$Column === void 0 ? void 0 : (_configuration$Column2 = _configuration$Column.map(function (c) { var _c$options, _c$options2; return { c: c, p: c !== null && c !== void 0 && (_c$options = c.options) !== null && _c$options !== void 0 && _c$options["MetaData"] ? __assign(__assign({}, (c === null || c === void 0 ? void 0 : (_c$options2 = c.options) === null || _c$options2 === void 0 ? void 0 : _c$options2["MetaData"])), { name: c === null || c === void 0 ? void 0 : c.field }) : properties.find(function (p) { return (p === null || p === void 0 ? void 0 : p.name) == (c === null || c === void 0 ? void 0 : c.field); }) }; })) === null || _configuration$Column2 === void 0 ? void 0 : (_configuration$Column3 = _configuration$Column2.filter(function (c) { return c.p; })) === null || _configuration$Column3 === void 0 ? void 0 : _configuration$Column3.map(function (c) { return (__assign(__assign({}, c.p), { options: c.c.options })); });
                    }
                    return [2 /*return*/, (_properties = properties) !== null && _properties !== void 0 ? _properties : []];
            }
        }); });
    };
    return PropertiesProvider;
}());
export { PropertiesProvider };

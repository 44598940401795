var _a;
import { createSlice } from '@reduxjs/toolkit';
import { DiscountManager, netTypes } from 'hub-lib/business/DiscountManager.bin';
import { clone, compareObjects } from 'hub-lib/tools.bin';
var initialState = { agreementBeforeUpsert: null, agreement: null, applicationConfig: netTypes, hasChanged: { CO: false, FO: false, FOS: false } };
export var agreementEditorSlice = createSlice({ name: 'agreementEditor', initialState: initialState, reducers: { setAgreement: function (state, action) { var _state$agreementBefor; var newAgreement = clone(action.payload); var discountsBeforeUpdate = (_state$agreementBefor = state.agreementBeforeUpsert) === null || _state$agreementBefor === void 0 ? void 0 : _state$agreementBefor.Discounts; state.hasChanged = {}; var _loop_1 = function (netType) {
            var discountSelector = function (discounts) { return discounts === null || discounts === void 0 ? void 0 : discounts.map(function (d) { return [d.DiscountClass, DiscountManager.getModulation(d, netType), d.Rank, d.IsRate]; }).sort(function (a, b) { return a[0].localeCompare(b[0]); }); };
            var before = discountSelector(discountsBeforeUpdate);
            var after = discountSelector(newAgreement === null || newAgreement === void 0 ? void 0 : newAgreement.Discounts);
            var equals = compareObjects(before, after);
            state.hasChanged[netType] = !equals;
        }; for (var _i = 0, netTypes_1 = netTypes; _i < netTypes_1.length; _i++) {
            var netType = netTypes_1[_i];
            _loop_1(netType);
        } if (state.hasChanged['CO'])
            state.applicationConfig = netTypes;
        else if (state.hasChanged['FO'])
            state.applicationConfig = netTypes.filter(function (n) { return n != 'CO'; });
        else if (state.hasChanged['FOS'])
            state.applicationConfig = netTypes.filter(function (n) { return n != 'CO' && n != 'FO'; }); state.agreement = newAgreement; }, setAgreementBeforeUpsert: function (state, action) { state.applicationConfig = netTypes; state.agreementBeforeUpsert = clone(action.payload); }, setConfig: function (state, action) { state.applicationConfig = clone(action.payload); } } }); // Action creators are generated for each case reducer function
export var setAgreement = (_a = agreementEditorSlice.actions, _a.setAgreement), setConfig = _a.setConfig, setAgreementBeforeUpsert = _a.setAgreementBeforeUpsert;
export var agreementEditorReducer = agreementEditorSlice.reducer;
